import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {
    accountSlice,
    authSlice,
    // alertSlice,
    changePasswordSlice,
    citySlice,
    companyTypeSlice,
    contractTypeSlice,
    countrySlice,
    employmentTypeSlice,
    industrySlice,
    initLogout,
    languageLevelSlice,
    languageSlice,
    loginSlice,
    organizationSizeSlice,
    organizationVerificationFileTypeSlice,
    onlineConsultationSlice,
    // sagaSlice,
    senioritySlice,
    serviceTypeSlice,
    technologySlice,
    technologyToolSlice,
    videoCallDetailsSlice,
    chatContactsSlice,
    workTypeSlice,
    preferenceTagsSlice,
    relocationsPageSlice,
} from 'jobhunter-common-web';
import alertSlice from './alertSlice';
import sagaSlice from './sagaSlice';
import {createReduxHistoryContext} from 'redux-first-history';
import profilePageSlice from './profilePageSlice';
import offersPageSlice from './offersPageSlice';
import marketplacePageSlice from './marketplacePageSlice';
import dashboardPageSlice from './dashboardPageSlice';
import offerDetailsPageSlice from './offerDetailsPageSlice';
import purchasedServicesPageSlice from './purchasedServicesPageSlice';
import relocationDetailsPageSlice from './relocationDetailsPageSlice';

const {routerReducer} = createReduxHistoryContext({history: createBrowserHistory()});

const appReducer = combineReducers({
    auth: authSlice,
    login: loginSlice,
    account: accountSlice,
    changePassword: changePasswordSlice,
    chatContacts: chatContactsSlice,
    alert: alertSlice,
    saga: sagaSlice,
    videoCallDetails: videoCallDetailsSlice,
    onlineConsultation: onlineConsultationSlice,

    city: citySlice,
    companyType: companyTypeSlice,
    contractType: contractTypeSlice,
    country: countrySlice,
    employmentType: employmentTypeSlice,
    industry: industrySlice,
    languageLevel: languageLevelSlice,
    language: languageSlice,
    organizationSize: organizationSizeSlice,
    organizationVerificationFileType: organizationVerificationFileTypeSlice,
    seniority: senioritySlice,
    serviceType: serviceTypeSlice,
    technology: technologySlice,
    technologyTool: technologyToolSlice,
    workType: workTypeSlice,
    preferenceTags: preferenceTagsSlice,

    profilePage: profilePageSlice,
    offersPage: offersPageSlice,
    offerDetailsPage: offerDetailsPageSlice,
    marketplacePage: marketplacePageSlice,
    purchasedServices: purchasedServicesPageSlice,
    dashboardPage: dashboardPageSlice,
    relocationsPage: relocationsPageSlice,
    relocationDetailsPage: relocationDetailsPageSlice,

    router: routerReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: any) => {
    if (action.type === initLogout.type) {
        return appReducer(undefined, {type: undefined});
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
