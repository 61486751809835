import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IDashboardState} from '../reducers/dashboardPageSlice';

export const selectDashboardPage = (state: RootState): IDashboardState => {
    return state.dashboardPage;
};

export const bestMatchOffersSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.bestMatchOffers);

export const dashboardApplicationsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.applications);

export const profileDetailsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.profileDetails);

export const calendarDetailsSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.calendarDetails);

export const isDashboardLoadingSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.isDashboardLoading);

export const isDashboardInitializedSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.isDashboardInitialized
);

export const isBestMatchListLoadingSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.isBestMatchListLoading
);

export const isApplicationsListLoadingSelector = createSelector(
    [selectDashboardPage],
    (state: IDashboardState) => state.isApplicationsListLoading
);

export const isCalendarLoadingSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.isCalendarLoading);

export const dashboardErrorSelector = createSelector([selectDashboardPage], (state: IDashboardState) => state.dashboardError);
