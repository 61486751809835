import {Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Edit3, Trash2} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Badge, Button} from 'reactstrap';
import AddTechnology from './AddTechnologySkills';
import {ITechnologyEntry, removeTechnologySkillData} from '../../../../store/reducers/profilePageSlice';
import DeleteSkillsModal from '../DeleteSkillsModal';

interface IConnectedTechnologySkillsProps {}

interface IExternalTechnologySkillsProps {
    readonly technologySkills: ITechnologyEntry[] | undefined;
    readonly removeTechnologySkillData: typeof removeTechnologySkillData;
}

interface ITechnologySkillsProps extends IConnectedTechnologySkillsProps, IExternalTechnologySkillsProps, WithTranslation {}

interface ITechnologySkillsState {
    isTechnologiesModalVisible: boolean;
    isDeleteModalVisible: boolean;
    selectedTechnologyEntry: ITechnologyEntry | null;
    isLoading: boolean;
}

class TechnologySkills extends Component<ITechnologySkillsProps, ITechnologySkillsState> {
    constructor(props: ITechnologySkillsProps) {
        super(props);

        this.state = {
            isTechnologiesModalVisible: false,
            isDeleteModalVisible: false,
            selectedTechnologyEntry: null,
            isLoading: false,
        };
    }

    render() {
        return (
            <>
                <div className="career-section">
                    <p className="sub-title">
                        <Translation text="profile.skillTest.technologySkills" />
                    </p>
                    {!this.props.technologySkills || !this.props.technologySkills.length ? (
                        <p className="no-options">
                            <Translation text="profile.career.noTechnologySkills" />
                        </p>
                    ) : (
                        this.props.technologySkills.map((item: any, index: number) => this.renderTechnologyItem(item, index))
                    )}

                    <div className="add-btn-container">
                        <Button color="primary" className="add-new-technology-button" onClick={() => this.toggleAddTechnology()}>
                            <Translation text="buttons.addNew" />
                        </Button>
                    </div>
                </div>

                {this.state.isTechnologiesModalVisible ? (
                    <AddTechnology
                        isModalOpen={this.state.isTechnologiesModalVisible}
                        toggleModal={this.toggleAddTechnology}
                        technologySkill={this.state.selectedTechnologyEntry}
                        technologySkills={this.props.technologySkills}
                    />
                ) : null}
                {this.state.isDeleteModalVisible ? (
                    <DeleteSkillsModal
                        isModalOpen={this.state.isDeleteModalVisible}
                        toggleModal={this.toggleDeleteModal}
                        removeAction={this.removeTechnologyItem}
                    />
                ) : null}
            </>
        );
    }

    private renderTechnologyItem = (item: ITechnologyEntry, index: number) => {
        const {t} = this.props,
            tools =
                item.technologyTool && item.technologyTool.length
                    ? item.technologyTool
                          .map((tool: {[key: string]: any}) => {
                              return t(tool.name);
                          })
                          .join(',\n')
                    : null;

        return (
            <div className="career-item" key={item.id ? item.id : `technology-${index}`}>
                <div className="career-details">
                    <div className="technology">
                        {/*{item.logo ? <img className="rounded me-1" src={item.logo} height="30" alt={item.position} /> : null}*/}
                        <div className="technology-details">
                            <p>
                                <Translation text={item.technology.name} />
                            </p>
                            <div className="tools">{tools ? <p>{tools}</p> : null}</div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={item.seniority.name} />
                            </Badge>
                        </div>
                        <div className="actions">
                            <button className="remove-technology-button" onClick={() => this.toggleDeleteModal(item)}>
                                <Trash2 />
                            </button>
                            <button className="edit-technology-button" onClick={() => this.toggleAddTechnology(item)}>
                                <Edit3 />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private toggleAddTechnology = (technologyEntry?: ITechnologyEntry | null) => {
        this.setState({
            isTechnologiesModalVisible: !this.state.isTechnologiesModalVisible,
            selectedTechnologyEntry: technologyEntry ? technologyEntry : null,
        });
    };

    private toggleDeleteModal = (technologyEntry?: ITechnologyEntry | null) => {
        this.setState({
            isDeleteModalVisible: !this.state.isDeleteModalVisible,
            selectedTechnologyEntry: technologyEntry ? technologyEntry : null,
        });
    };

    private removeTechnologyItem = () => {
        if (this.state.selectedTechnologyEntry && this.state.selectedTechnologyEntry.id) {
            this.props.removeTechnologySkillData(this.state.selectedTechnologyEntry.id);
        }
    };
}

export default withTranslation()(TechnologySkills);
