import React, {Component} from 'react';
import {Button} from 'reactstrap';
import {
    ApexRadialbar,
    ILanguageTestResult,
    ISkillItem,
    isNotNullOrUndefined,
    ITechnologyTestResult,
    Translation
} from 'jobhunter-common-web';
import {ArrowLeft, ArrowRight} from 'react-feather';
import {addLanguageTest, addTechnologyTest, ICandidateSkills} from '../../../store/reducers/profilePageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    profileLanguageTestResultsSelector,
    profileSkillsSelector,
    profileTechnologyTestResultsSelector,
} from '../../../store/selectors/profilePageSelectors';

interface IConnectedSkillTestsProps {
    readonly skills: ICandidateSkills | null;
    readonly technologyTestResults: typeof ITechnologyTestResult[] | null;
    readonly languageTestResults: typeof ILanguageTestResult[] | null;
    readonly addTechnologyTest: typeof addTechnologyTest;
    readonly addLanguageTest: typeof addLanguageTest;
}

interface IExternalSkillTestsProps {
    stepper: any;
}

interface ISkillTestsProps extends IConnectedSkillTestsProps, IExternalSkillTestsProps {}

interface ISkillTestsState {}

class SkillTests extends Component<ISkillTestsProps, ISkillTestsState> {
    render() {
        const languages = this.props.skills?.languageSkills,
            technologies = this.props.skills?.technologySkills;

        return (
            <div className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <p className="title">
                                <Translation text="profile.skillTest.title" />
                            </p>
                            <p className="sub-title">
                                <Translation text="profile.skillTest.testSkills" />
                            </p>
                        </div>
                    </div>

                    <div className="skills-content">
                        <div className="skills">
                            <p>
                                <Translation text="profile.skillTest.technologySkills" />
                            </p>
                            {!technologies || !technologies.length ? (
                                <p className="no-results">
                                    <Translation text="profile.skillTest.noTestsTaken" />
                                </p>
                            ) : (
                                technologies.map((item: typeof ISkillItem) => {
                                    return this.renderTestEntries(item.technology, true);
                                })
                            )}
                        </div>

                        <div className="skills">
                            <p>
                                <Translation text="profile.skillTest.languageSkills" />
                            </p>
                            {!languages || !languages.length ? (
                                <p className="no-results">
                                    <Translation text="profile.skillTest.noTestsTaken" />
                                </p>
                            ) : (
                                languages.map((item: typeof ISkillItem) => {
                                    return this.renderTestEntries(item.language);
                                })
                            )}
                        </div>
                    </div>
                </div>
                <div className="wizard-actions">
                    <Button color="primary" className="btn-prev skills-prev-button" onClick={() => this.props.stepper.previous()}>
                        <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.previous" />
                        </span>
                    </Button>
                    <Button color="primary" className="btn-next skills-next-button" onClick={() => this.props.stepper.next()}>
                        {/*<Save size={14} className="align-middle ms-sm-25 me-25" />*/}
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.next" />
                        </span>
                        <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
                    </Button>
                </div>
            </div>
        );
    }

    private renderTestEntries = (skillItem: typeof ISkillItem, isTechnology?: boolean) => {
        const result: typeof ILanguageTestResult | typeof ITechnologyTestResult = isTechnology
            ? this.renderTechnologyResult(skillItem.id)
            : this.renderLanguageResult(skillItem.id);

        return (
            <div className="skill-item" key={skillItem.id}>
                <div className="d-flex align-items-center justify-content-center">
                    <p>
                        <Translation text={skillItem.name} />
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    {isNotNullOrUndefined(result?.result) ? <ApexRadialbar item={{value: result?.result, series: [result?.result]}} /> : null}
                    <Button
                        color="primary"
                        className={isTechnology ? 'test-technology-button' : 'test-language-button'}
                        disabled={!skillItem.isTestable}
                        id={skillItem.id}
                        onClick={() => {
                            this.addTest(skillItem.id, isTechnology);
                        }}>
                        <Translation text="profile.skillTest.takeSkillTest" />
                    </Button>
                </div>
            </div>
        );
    };

    private renderLanguageResult = (languageId: string) => {
        return this.props.languageTestResults?.filter((result: typeof ILanguageTestResult) => {
            return result?.language?.id === languageId;
        })[0];
    };

    private renderTechnologyResult = (technologyId: string) => {
        return this.props.technologyTestResults?.filter((result: typeof ITechnologyTestResult) => {
            return result?.technology?.id === technologyId;
        })[0];
    };

    private addTest = (skillEntryId: string, isTechnology?: boolean) => {
        const btn = document.getElementById(skillEntryId) as HTMLButtonElement;
        if (btn !== null) {
            btn.disabled = true;
            setTimeout(function () {
                btn.disabled = false;
            }, 10000);
        }

        return isTechnology ? this.props.addTechnologyTest(skillEntryId) : this.props.addLanguageTest(skillEntryId);
    };
}

export default connect(
    (state: RootState) => ({
        skills: profileSkillsSelector(state),
        technologyTestResults: profileTechnologyTestResultsSelector(state),
        languageTestResults: profileLanguageTestResultsSelector(state),
    }),
    {
        addTechnologyTest,
        addLanguageTest,
    }
)(SkillTests);
