import React, {Component} from 'react';
import {Price, Translation} from 'jobhunter-common-web';
import styles from './styles.module.scss';
import {CheckCircle} from 'react-feather';
import {IModelService} from '../../../../model/service';

interface IPaymentCompleteModalProps {
    service: IModelService | null;
    invoiceNumber: string | null;
}

class PaymentCompleteModal extends Component<IPaymentCompleteModalProps> {
    render() {
        const service = this.props.service,
            taxPrice = service && service.grossPrice && service.netPrice ? Number(service.grossPrice) - Number(service.netPrice) : '';

        return (
            <div>
                <div className={styles.modalHeader}>
                    <div className={styles.header}>
                        <p className={styles.title}>
                            <Translation text="marketplace.purchaseComplete.title" />
                        </p>

                        <p className={styles.subtitle}>
                            <Translation text="marketplace.purchaseComplete.invoice" />
                            {this.props.invoiceNumber}
                        </p>
                    </div>
                    <div>
                        <CheckCircle size={80} />
                    </div>
                </div>

                <hr />

                <div className={styles.serviceDetails}>
                    <div className={styles.serviceDetailsLabels}>
                        <p>
                            <Translation text="marketplace.purchaseComplete.serviceTitle" />
                        </p>
                        <p>
                            <Translation text="marketplace.purchaseComplete.subTotal" />
                        </p>
                        <p>
                            <Translation text="marketplace.purchaseComplete.tax" />
                        </p>
                    </div>

                    <div className={`${styles.serviceDetailsData} ${styles.taxRate}`}>
                        <p />
                        <p />
                        <p>{service?.taxRate}%</p>
                    </div>

                    <div className={styles.serviceDetailsData}>
                        <p>{service?.title}</p>
                        <p>
                            <Price
                                price={{
                                    amount: service?.grossPrice,
                                }}
                            />
                        </p>
                        <p>
                            <Price
                                price={{
                                    amount: taxPrice,
                                }}
                            />
                        </p>
                    </div>
                </div>

                <hr />

                <div className={styles.paymentSummary}>
                    <p className={styles.summaryLabel}>
                        <Translation text="marketplace.purchaseComplete.total" />
                    </p>
                    <p>
                        <Price
                            price={{
                                amount: service?.netPrice,
                            }}
                        />
                    </p>
                </div>

                <p className={styles.sectionTitle}>
                    <Translation text="marketplace.purchaseComplete.details" />
                </p>
                <hr className="divider divider-dashed" />

                <div className={styles.purchaserDetails}>
                    <div className={styles.purchaseDetailsLabels}>
                        <p>
                            <Translation text="marketplace.purchaseComplete.serviceTitle" />
                        </p>
                        <p>
                            <Translation text="marketplace.purchaseComplete.companyName" />
                        </p>
                    </div>

                    <div className={styles.purchaseDetailsData}>
                        <p>{service?.title}</p>
                        <p>{service?.author?.organization?.organizationCompany?.name}</p>
                    </div>
                </div>

                {/*The cde below commented out in the current version of application*/}
                {/*<hr />*/}

                {/*<p className={styles.report}>*/}
                {/*    <Translation text="marketplace.purchaseComplete.noticeError" />*/}
                {/*    <a href="/" target="_blank">*/}
                {/*        <Translation text="marketplace.purchaseComplete.report" />{' '}*/}
                {/*    </a>*/}
                {/*</p>*/}
            </div>
        );
    }
}

export default PaymentCompleteModal;
