import React, {Component} from 'react';
import {
    ITimelineItem,
    Loader,
    LoaderType,
    Timeline,
    Translation,
    usernameSelector,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {Calendar, User} from 'react-feather';
import EventCalendar from './EventCalendar';
import LayoutWrapper from '../LayoutWrapper';
import {
    fetchApplications,
    fetchBestMatchOffers,
    fetchCalendarDetails,
    fetchProfileDetails,
    IBestMatchOffer,
    IOfferApplication,
    IProfileCompletion,
    resetToInitialDashboardPageState,
} from '../../store/reducers/dashboardPageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    bestMatchOffersSelector,
    calendarDetailsSelector,
    dashboardApplicationsSelector,
    isApplicationsListLoadingSelector,
    isBestMatchListLoadingSelector,
    isCalendarLoadingSelector,
    isDashboardLoadingSelector,
    profileDetailsSelector,
} from '../../store/selectors/dashboardSelectors';
import BestMatchOffers from './BestMatchOffers';
import DashboardApplications from './DashboardApplications';
import {ICalendarEvent} from '../../model/calendarEventModel';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedDashboardProps {
    readonly applications: IOfferApplication[] | null;
    readonly bestMatchOffers: IBestMatchOffer[] | null;
    readonly profileCompletion: IProfileCompletion | null;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly isDashboardLoading: boolean;
    readonly isBestMatchListLoading: boolean;
    readonly isApplicationsListLoading: boolean;
    readonly username: string;
    readonly isCalendarLoading: boolean;
    readonly fetchBestMatchOffers: typeof fetchBestMatchOffers;
    readonly fetchApplications: typeof fetchApplications;
    readonly fetchProfileDetails: typeof fetchProfileDetails;
    readonly resetToInitialDashboardPageState: typeof resetToInitialDashboardPageState;
    readonly fetchCalendarDetails: typeof fetchCalendarDetails;
}

interface IDashboardProps extends IConnectedDashboardProps, WithNavigationProps {}

class Dashboard extends Component<IDashboardProps, any> {
    componentDidMount() {
        const isProfileComplete =
            this.props.profileCompletion?.isPreferencesDataComplete && this.props.profileCompletion?.isPersonalDataComplete;

        if (isProfileComplete) {
            this.props.fetchBestMatchOffers();
        }

        this.props.fetchApplications();
        this.props.fetchProfileDetails();
        this.props.fetchCalendarDetails();
    }

    componentDidUpdate(prevProps: Readonly<IDashboardProps>) {
        if (
            this.props.profileCompletion?.isPreferencesDataComplete !== prevProps.profileCompletion?.isPreferencesDataComplete ||
            this.props.profileCompletion?.isPersonalDataComplete !== prevProps.profileCompletion?.isPersonalDataComplete
        ) {
            const isProfileComplete =
                this.props.profileCompletion?.isPreferencesDataComplete && this.props.profileCompletion?.isPersonalDataComplete;

            if (isProfileComplete) {
                this.props.fetchBestMatchOffers();
            }
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialDashboardPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <BestMatchOffers
                            bestMatchOffers={this.props.bestMatchOffers}
                            profileCompletion={this.props.profileCompletion}
                            isBestMatchListLoading={this.props.isBestMatchListLoading}
                        />

                        <DashboardApplications
                            applications={this.props.applications}
                            isApplicationsListLoading={this.props.isApplicationsListLoading}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        {this.renderProfile()}
                        {this.renderCalendar()}
                    </div>
                </div>
                <Loader type={LoaderType.Local} showLoader={this.props.isDashboardLoading} />
            </LayoutWrapper>
        );
    }

    private renderProfile = () => {
        return (
            <Card className="profile-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <User size={20} />
                                <Translation text="dashboard.profile.title" />
                            </div>
                        </div>
                    </CardTitle>

                    <Timeline data={this.renderTimelineData()} />
                </CardBody>
            </Card>
        );
    };

    private renderCalendar = () => {
        return (
            <Card className="events-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Calendar size={20} />
                                <Translation text="dashboard.calendar.title" />
                            </div>
                        </div>
                    </CardTitle>
                    <EventCalendar
                        username={this.props.username}
                        calendarEvents={this.props.calendarEvents}
                        isCalendarLoading={this.props.isCalendarLoading}
                    />
                </CardBody>
            </Card>
        );
    };

    private renderTimelineData = (): typeof ITimelineItem[] => {
        const profileCompletion = this.props.profileCompletion;
        return [
            {
                title: 'dashboard.profile.personalInformation.title',
                content: profileCompletion?.isPersonalDataComplete ? '' : 'dashboard.profile.personalInformation.description',
                metaIcon: true,
                isTimelineComplete: profileCompletion?.isPersonalDataComplete,
                timelineLink: '/panel/profile',
                activeTab: 0,
                color: profileCompletion?.isPersonalDataComplete ? 'primary' : 'secondary',
                linkClassName: 'personal-information-link',
            },
            {
                title: 'dashboard.profile.career.title',
                content: profileCompletion?.isCareerComplete ? '' : 'dashboard.profile.career.description',
                metaIcon: true,
                isTimelineComplete: profileCompletion?.isCareerComplete,
                timelineLink: '/panel/profile',
                activeTab: 1,
                color: profileCompletion?.isCareerComplete ? 'primary' : 'secondary',
                linkClassName: 'career-link',
            },
            {
                title: 'dashboard.profile.skills.title',
                content: profileCompletion?.isSkillDataComplete ? '' : 'dashboard.profile.skills.description',
                metaIcon: true,
                isTimelineComplete: profileCompletion?.isSkillDataComplete,
                timelineLink: '/panel/profile',
                activeTab: 2,
                color: profileCompletion?.isSkillDataComplete ? 'primary' : 'secondary',
                linkClassName: 'skill-test-link',
            },
            {
                title: 'dashboard.profile.preferences.title',
                content: profileCompletion?.isPreferencesDataComplete ? '' : 'dashboard.profile.preferences.description',
                metaIcon: true,
                isTimelineComplete: profileCompletion?.isPreferencesDataComplete,
                timelineLink: '/panel/profile',
                activeTab: 3,
                color: profileCompletion?.isPreferencesDataComplete ? 'primary' : 'secondary',
                linkClassName: 'preferences-link',
            },
        ];
    };
}

export default connect(
    (state: RootState) => ({
        applications: dashboardApplicationsSelector(state),
        bestMatchOffers: bestMatchOffersSelector(state),
        profileCompletion: profileDetailsSelector(state),
        isDashboardLoading: isDashboardLoadingSelector(state),
        isBestMatchListLoading: isBestMatchListLoadingSelector(state),
        isApplicationsListLoading: isApplicationsListLoadingSelector(state),
        calendarEvents: calendarDetailsSelector(state),
        isCalendarLoading: isCalendarLoadingSelector(state),
        username: usernameSelector(state),
    }),
    {
        fetchBestMatchOffers,
        fetchApplications,
        fetchProfileDetails,
        fetchCalendarDetails,
        resetToInitialDashboardPageState,
    }
)(withNavigation(Dashboard));
