import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    formatRangePickerLabel,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'jobhunter-common-web';

export const addTechnologyFormConfig: typeof IFormConfig = (
    technologyList: typeof IMultiselectOption[] | [],
    technologyToolList: typeof IMultiselectOption[] | [],
    seniorityLevels: any[],
    isFormEdit?: boolean
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'technology',
            controlType: 'group',
            class: 'row',
            controls: {
                technology: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: technologyList ? technologyList : [],
                    validationRules: [],
                    placeholder: 'profile.career.addTechnology.form.placeholders.select',
                    label: 'profile.career.addTechnology.form.labels.technology',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                    disabled: isFormEdit,
                },
                tools: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: technologyToolList ? technologyToolList : [],
                    validationRules: [],
                    placeholder: 'profile.career.addTechnology.form.placeholders.select',
                    label: 'profile.career.addTechnology.form.labels.tools',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'level',
            controlType: 'group',
            class: 'row',
            controls: {
                level: {
                    controlType: 'control',
                    defaultValue: '', // '20',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels, // labels,
                    label: 'Seniority Level',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    isRangeLabelTranslated: true,
                },
            },
        },
    ],
});
