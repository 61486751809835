import React, {Component} from 'react';
import {
    convertToPriceString,
    IModelDictionaryDatum,
    isNotNullOrUndefined,
    ListItemDetails,
    Tooltip,
    Translation,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import {Badge, Button, Table} from 'reactstrap';
import {Eye, EyeOff, Search, Star, Video} from 'react-feather';
import styles from './styles.module.scss';
import {WithTranslation, withTranslation} from 'react-i18next';
import OfferApplicationModal from '../OfferView/OfferApplicationModal';
import {connect} from 'react-redux';
import {
    addOfferToObserved,
    applyOffersFilters,
    changeOffersFilters,
    changeOffersListType,
    fetchListData,
    IOffer,
    IOfferFilters,
    IOfferListItem,
    OfferStatus,
    removeOfferFromObserved,
    resetOffersFiltersToDefaultAccountFilters,
} from '../../../store/reducers/offersPageSlice';
import {offersListSelector} from '../../../store/selectors/offersPageSelectors';
import {RootState} from '../../../store/reducers';
import {IOfferConsultation} from '../../../model/offer';

export enum OffersListType {
    OFFERS = 'offers',
    APPLICATIONS = 'applications',
    RECRUITMENT = 'recruitment',
    OBSERVED = 'observed',
}

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedOffersListProps {
    readonly offers: IOfferListItem[] | null;
    readonly applyOffersFilters: typeof applyOffersFilters;
    readonly fetchListData: typeof fetchListData;
    readonly changeOffersFilters: typeof changeOffersFilters;
    readonly changeOffersListType: typeof changeOffersListType;
    readonly addOfferToObserved: typeof addOfferToObserved;
    readonly removeOfferFromObserved: typeof removeOfferFromObserved;
    readonly resetOffersFiltersToDefaultAccountFilters: typeof resetOffersFiltersToDefaultAccountFilters;
}

interface IExternalOffersListProps {
    type: OffersListType;
    isBestMatchSet?: boolean;
}

interface IOffersListProps extends IConnectedOffersListProps, IExternalOffersListProps, WithTranslation, WithNavigationProps {}

interface IOffersListState {
    isApplicationModalOpen: boolean;
    selectedOffer: IOfferListItem | null;
}

class OffersList extends Component<IOffersListProps, IOffersListState> {
    constructor(props: IOffersListProps) {
        super(props);

        this.state = {
            isApplicationModalOpen: false,
            selectedOffer: null,
        };
    }

    componentDidMount() {
        this.props.changeOffersListType(this.props.type);

        if (this.props.type === OffersListType.OFFERS) {
            this.changeFilters();
        } else {
            this.props.fetchListData(this.props.type);
        }
    }

    componentDidUpdate(prevProps: Readonly<IOffersListProps>) {
        if (this.props.isBestMatchSet !== prevProps.isBestMatchSet) {
            this.changeFilters();
        }
    }

    render() {
        return <div>{this.renderMatches()}</div>;
    }

    private renderMatches = () => {
        return (
            <div>
                <Table striped responsive className="offers-table">
                    <thead>
                        <tr>
                            <th>
                                <Translation text="offers.table.project" />
                            </th>
                            <th>
                                <Translation text="offers.table.seniority" />
                            </th>
                            <th>
                                <Translation text="offers.table.salary" />
                            </th>
                            <th>
                                <Translation text="offers.table.remote" />
                            </th>
                            <th>
                                <Translation text="offers.table.status" />
                            </th>
                            <th>
                                <Translation text="offers.table.actions" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.renderOffersTableRows()}</tbody>
                </Table>

                {this.state.isApplicationModalOpen ? (
                    <OfferApplicationModal
                        isModalOpen={this.state.isApplicationModalOpen}
                        toggleModal={this.toggleApplicationModal}
                        offer={this.state.selectedOffer}
                        listType={this.props.type}
                    />
                ) : null}
            </div>
        );
    };

    private toggleApplicationModal = (item?: IOfferListItem | null) => {
        this.setState({
            isApplicationModalOpen: !this.state.isApplicationModalOpen,
            selectedOffer: item ? item : null,
        });
    };

    private renderOffersTableRows() {
        const {t} = this.props;

        if (this.props.offers === undefined || this.props.offers === null || !this.props.offers.length) {
            return (
                <tr key="no-data">
                    <td colSpan={6}>
                        <Translation text="offers.table.noData" />
                    </td>
                </tr>
            );
        } else {
            return this.props.offers.map((item: IOfferListItem) => {
                const offerStatus =
                        item.status === OfferStatus.ACTIVE || item.status === OfferStatus.HIRED
                            ? 'light-success'
                            : item.status === OfferStatus.APPLIED || item.status === OfferStatus.SCHEDULED
                            ? 'light-info'
                            : 'light-danger',
                    isApplied =
                        this.props.type === OffersListType.APPLICATIONS ||
                        item.status === OfferStatus.APPLIED ||
                        item.status === OfferStatus.SCHEDULED;

                return (
                    <tr key={item.offer.id}>
                        <td>{this.renderDescription(item.offer)}</td>
                        <td>
                            <Translation text={item.offer.seniority.name} />
                        </td>
                        <td>
                            {convertToPriceString(item.offer.minimumSalary)} - {convertToPriceString(item.offer.maximumSalary)}
                        </td>
                        <td>
                            {item.offer && item.offer.workTypes.length
                                ? item.offer.workTypes.map((type: typeof IModelDictionaryDatum, index: number) => {
                                      return (
                                          <span key={type.id}>
                                              {index ? ', ' : ''}
                                              <Translation text={type.name} />
                                          </span>
                                      );
                                  })
                                : null}
                        </td>
                        <td>
                            <Badge pill color={offerStatus}>
                                {item.status}
                            </Badge>
                        </td>
                        <td>
                            <div className={styles.actions}>
                                {this.props.type === OffersListType.OFFERS || this.props.type === OffersListType.OBSERVED ? (
                                    <Button
                                        color="flat-primary"
                                        className={item.observed ? 'add-observed-button' : 'remove-observed-button'}
                                        onClick={() =>
                                            this.addToObserve(item.offer.id, isNotNullOrUndefined(item.observed) && item.observed)
                                        }>
                                        {item.observed ? (
                                            <EyeOff id={`observe_${item.offer.id}`} />
                                        ) : (
                                            <Eye id={`observe_${item.offer.id}`} />
                                        )}
                                        <Tooltip
                                            target={`observe_${item.offer.id}`}
                                            tooltipText={t(
                                                `${item.observed ? 'offers.table.tooltips.unobserve' : 'offers.table.tooltips.observe'}`
                                            )}
                                        />
                                    </Button>
                                ) : null}

                                {this.props.type === OffersListType.RECRUITMENT ? (
                                    <Button
                                        color="flat-primary"
                                        disabled={!item?.consultation}
                                        className="consultation-button"
                                        onClick={() => this.redirectToConsultation(item?.consultation)}>
                                        <Video id={`consultation_${item.offer.id}`} />
                                        <Tooltip
                                            target={`consultation_${item.offer.id}`}
                                            tooltipText={t('offers.table.tooltips.consultation')}
                                        />
                                    </Button>
                                ) : null}

                                {this.props.type === OffersListType.OFFERS || this.props.type === OffersListType.RECRUITMENT ? (
                                    <Button
                                        className={isApplied ? `${styles.applied} unapply-button` : 'apply-button'}
                                        disabled={
                                            item.status === OfferStatus.CANCELLED ||
                                            item.status === OfferStatus.REJECTED ||
                                            (this.props.type === OffersListType.RECRUITMENT && item.status === OfferStatus.ACTIVE)
                                        }
                                        color="flat-primary"
                                        onClick={() => {
                                            this.toggleApplicationModal(item);
                                        }}>
                                        <Star id={`apply_${item.offer.id}`} />
                                        <Tooltip
                                            target={`apply_${item.offer.id}`}
                                            tooltipText={isApplied ? t('offers.table.tooltips.unapply') : t('offers.table.tooltips.apply')}
                                        />
                                    </Button>
                                ) : null}

                                <Button
                                    color="flat-primary"
                                    className="offer-details-button"
                                    onClick={() => this.showDetails(item.offer.id)}>
                                    <Search id={`details_${item.offer.id}`} />
                                    <Tooltip target={`details_${item.offer.id}`} tooltipText={t('offers.table.tooltips.details')} />
                                </Button>
                            </div>
                        </td>
                    </tr>
                );
            });
        }
    }

    private renderDescription = (item: IOffer) => {
        const {t} = this.props,
            description = `${t(item.city.country.name)}, ${t(item.city.name)}`;
        return <ListItemDetails avatar={item.avatar} name={item.name} description={description} />;
    };

    private addToObserve = (offerId: string, isObserved: boolean) => {
        return isObserved ? this.props.removeOfferFromObserved(offerId) : this.props.addOfferToObserved(offerId);
    };

    private showDetails = (offerId: string) => {
        this.props.navigate(`/panel/offer-view/${offerId}`);
    };

    private changeFilters = () => {
        if (!this.props.isBestMatchSet) {
            this.props.resetOffersFiltersToDefaultAccountFilters();
        } else {
            const filters: IOfferFilters = {
                offerTechnologies: null,
                seniority: null,
                minimumSalary: null,
                best_match: true,
                contractTypes: null,
                industries: null,
                companyTypes: null,
                employmentTypes: null,
                workTypes: null,
                relocation: false,
            };

            this.props.changeOffersFilters(filters);
        }

        this.props.applyOffersFilters();
    };

    private redirectToConsultation = (consultation: IOfferConsultation | null | undefined) => {
        if (!consultation) {
            return;
        }
        this.props.navigate(`/panel/consultations/${consultation.id}`);
    };
}

export default connect(
    (state: RootState) => ({
        offers: offersListSelector(state),
    }),
    {
        applyOffersFilters,
        changeOffersFilters,
        resetOffersFiltersToDefaultAccountFilters,
        addOfferToObserved,
        removeOfferFromObserved,
        fetchListData,
        changeOffersListType,
    }
)(withTranslation()(withNavigation(OffersList)));
