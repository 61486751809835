import React, {Component} from 'react';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Download, FileText, Trash2} from 'react-feather';
import {Loader, LoaderType, Tooltip, Translation} from 'jobhunter-common-web';
import {WithTranslation, withTranslation} from 'react-i18next';
import AddRelocationFile from './AddRelocationFile';
import RemoveRelocationFileModal from './RemoveRelocationFile';
import {
    changeIsRelocationFileRemoved,
    downloadRelocationFile,
    IRelocationFile,
} from '../../../../store/reducers/relocationDetailsPageSlice';

interface IRelocationFilesProps extends WithTranslation {
    readonly files: IRelocationFile[] | null;
    readonly applicationId: string | null;
    readonly error: string | null;
    readonly isRelocationFileRemoved: boolean;
    readonly isLoading: boolean;
    readonly removeRelocationFile: (fileId: string) => void;
    readonly changeIsRelocationFileRemoved: typeof changeIsRelocationFileRemoved;
    readonly downloadRelocationFile: typeof downloadRelocationFile;
}

interface IRelocationFilesState {
    isAddFileModalShown: boolean;
    isRemoveFileModalShown: boolean;
    selectedFile: IRelocationFile | null;
}

class RelocationFiles extends Component<IRelocationFilesProps, IRelocationFilesState> {
    constructor(props: IRelocationFilesProps) {
        super(props);

        this.state = {
            isAddFileModalShown: false,
            isRemoveFileModalShown: false,
            selectedFile: null,
        };
    }

    render() {
        return (
            <>
                <Card className="relocation-files-card">
                    <CardBody>
                        <CardTitle>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="card-header justify-content-start">
                                    <FileText size={20} />
                                    <Translation text="relocationView.files.title" />
                                </div>
                                <button className="btn btn-primary offers-button" onClick={() => this.toggleAddFileModal()}>
                                    <Translation text="buttons.addNew" />
                                </button>
                            </div>
                        </CardTitle>
                        <Table responsive className="relocation-files-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="relocationView.files.table.fileName" />
                                    </th>
                                    <th className="text-center">
                                        <Translation text="relocationView.files.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderApplicationsTableRows()}</tbody>
                        </Table>
                    </CardBody>
                    <Loader type={LoaderType.Local} showLoader={this.props.isLoading} />
                </Card>

                {this.state.isAddFileModalShown ? (
                    <AddRelocationFile
                        isModalOpen={this.state.isAddFileModalShown}
                        toggleModal={this.toggleAddFileModal}
                        applicationId={this.props.applicationId}
                    />
                ) : null}

                {this.state.isRemoveFileModalShown ? (
                    <RemoveRelocationFileModal
                        isModalOpen={this.state.isRemoveFileModalShown}
                        selectedItem={this.state.selectedFile}
                        removeRelocationFile={this.props.removeRelocationFile}
                        error={this.props.error}
                        isRelocationFileRemoved={this.props.isRelocationFileRemoved}
                        changeIsRelocationFileRemoved={this.props.changeIsRelocationFileRemoved}
                        toggleModal={this.toggleRemoveFileModal}
                    />
                ) : null}
            </>
        );
    }

    private renderApplicationsTableRows() {
        const {t} = this.props;

        if (this.props.files === null || !this.props.files.length) {
            return (
                <tr key={'no-applications-data'}>
                    <td colSpan={2}>
                        <p>
                            <Translation text="relocationView.files.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.files.map((item: IRelocationFile) => {
            const fileName = item?.file?.originalFileName ? item.file.originalFileName : item.fileName;

            return (
                <tr key={`file_${item.file.id}`}>
                    <td>{fileName}</td>
                    <td className="text-center">
                        <div>
                            <Button color="flat-primary" className="download-file-button" onClick={() => this.downloadFile(item.file.id)}>
                                <Download id={`download_${item.file.id}`} size={20} />
                                <Tooltip target={`download_${item.file.id}`} tooltipText={t('relocationView.files.tooltips.download')} />
                            </Button>
                            <Button color="flat-primary" onClick={() => this.toggleRemoveFileModal(item)} className="delete-file-button">
                                <Trash2 id={`delete_${item.file.id}`} size={20} />
                                <Tooltip target={`delete_${item.file.id}`} tooltipText={t('relocationView.files.tooltips.download')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private downloadFile = (fileId: string) => {
        this.props.downloadRelocationFile(fileId);
    };

    private toggleRemoveFileModal = (item: IRelocationFile) => {
        this.setState({isRemoveFileModalShown: !this.state.isRemoveFileModalShown, selectedFile: item ? item : null});
    };

    private toggleAddFileModal = () => {
        this.setState({isAddFileModalShown: !this.state.isAddFileModalShown});
    };
}

export default withTranslation()(RelocationFiles);
