import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {
    convertToMultiselectLabels,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelDictionaryDatum,
    languageLevelsSelector,
    languagesSelector,
    sortMultiselectLabels,
    Translation,
} from 'jobhunter-common-web';
import {filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {addLanguageFormConfig} from './addLanguageFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {changeLanguageSkillsData, ILanguageEntry} from '../../../../../store/reducers/profilePageSlice';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedAddLanguageProps {
    readonly languages: typeof IModelDictionaryDatum[] | null;
    readonly languageLevels: typeof IModelDictionaryDatum[] | null;
    readonly languageSkills: ILanguageEntry[] | undefined;
    readonly changeLanguageSkillsData: typeof changeLanguageSkillsData;
}

interface IExternalAddLanguageProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    languageSkill: ILanguageEntry | null;
}

interface IAddLanguageProps extends IConnectedAddLanguageProps, IExternalAddLanguageProps, WithTranslation {}

interface IAddLanguageState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddLanguage extends Component<IAddLanguageProps, IAddLanguageState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddLanguageProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        if (this.props.languageSkill) {
            this.setDefaultFormValues(this.props.languageSkill);
        }

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation
                        text={this.props.languageSkill ? 'profile.career.editLanguage.title' : 'profile.career.addLanguage.title'}
                    />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="profile.career.addLanguage.description" />
                    </p>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'addLanguageForm'}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className={this.props.languageSkill ? 'update-language-button' : 'add-language-button'}
                        onClick={() => this.addLanguage()}
                        disabled={!this.isFormComplete()}>
                        <Translation text={this.props.languageSkill ? 'buttons.save' : 'buttons.add'} />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private addLanguage = () => {
        this.setState({isLoading: true});

        const formData = this.state.value,
            languageId = Array.isArray(formData.language) ? formData.language[0] : formData.language,
            language: typeof IModelDictionaryDatum | undefined = this.props.languages?.find((language) => language.id === languageId),
            languageLevel: typeof IModelDictionaryDatum | undefined = this.props.languageLevels?.find(
                (level) => level.id === formData.languageLevel
            );

        if (language === undefined || languageLevel === undefined) {
            return;
        }

        const languageEntry: ILanguageEntry = {
            language: language,
            languageLevel: languageLevel,
        };

        this.props.changeLanguageSkillsData(languageEntry);
        this.props.toggleModal();
    };

    private setFormConfig = () => {
        const {t} = this.props,
            languages = sortMultiselectLabels(convertToMultiselectLabels(this.props.languages, t)),
            selectedLanguage = this.props.languageSkill,
            languageLevels = sortMultiselectLabels(convertToMultiselectLabels(this.props.languageLevels, t)),
            filteredOptions =
                null === selectedLanguage
                    ? languages?.filter((language: any) => {
                          return !this.props.languageSkills?.some((item: any) => language.value === item.language.id);
                      })
                    : languages;

        const formConfig = addLanguageFormConfig(filteredOptions, languageLevels, selectedLanguage !== null);
        this.setState({formConfig});
    };

    private setDefaultFormValues = (language: ILanguageEntry) => {
        const value = {
            language: language.language.id,
            languageLevel: language.languageLevel.id,
        };

        this.setState({value});
    };

    private isFormComplete = () => {
        return this.state.value && this.state.value.language && this.state.value.languageLevel;
    };
}

export default connect(
    (state: RootState) => ({
        languages: languagesSelector(state),
        languageLevels: languageLevelsSelector(state),
    }),
    {
        changeLanguageSkillsData,
    }
)(withTranslation()(AddLanguage));
