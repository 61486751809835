import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, IModelSeniority, isSameValue, Translation, senioritySelector} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {addCareerFormConfig} from './addCareerFormConfig';
import {connect} from 'react-redux';
import {changeCareerData, ICareerEntry} from '../../../../../store/reducers/profilePageSlice';
import {convertSeniorityToRangeValues} from '../../TechnologySkills/AddTechnologySkills';
import {RootState} from '../../../../../store/reducers';

interface IConnectedAddCareerProps {
    readonly seniorityLevels: typeof IModelSeniority[] | null;
    readonly changeCareerData: typeof changeCareerData;
}

interface IExternalAddCareerProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    career: ICareerEntry | null;
}

interface IAddCareerProps extends IConnectedAddCareerProps, IExternalAddCareerProps {}

interface IAddCareerState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddCareer extends Component<IAddCareerProps, IAddCareerState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddCareerProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        if (this.props.career) {
            this.setDefaultFormValues(this.props.career);
        }

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddCareerProps>) {
        if (!isSameValue(this.props.seniorityLevels, prevProps.seniorityLevels)) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text={this.props.career ? 'profile.career.editCareer.title' : 'profile.career.addCareer.title'} />{' '}
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="profile.career.addCareer.description" />
                    </p>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'addCareerForm'}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className={this.props.career ? 'update-career-button' : 'add-career-button'}
                        color="primary"
                        onClick={() => this.addCareer()}
                        disabled={!this.isFormComplete()}>
                        <Translation text={this.props.career ? 'buttons.save' : 'buttons.add'} />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private addCareer = () => {
        this.setState({isLoading: true});
        const formData = this.state.value,
            isDefaultSeniority = formData.seniorityLevel === '',
            seniorityLevel: typeof IModelSeniority | undefined = this.props.seniorityLevels?.find((level) =>
                isDefaultSeniority ? level.level === 0 : level.id === formData.seniorityLevel
            );

        if (seniorityLevel === undefined) {
            return;
        }

        const careerEntry: ICareerEntry = {
            from: new Date(formData.startDate).toISOString(),
            to: formData.isWorking ? null : new Date(formData.endDate).toISOString(),
            company: formData.companyName,
            position: formData.position,
            seniority: seniorityLevel,
        };

        if (this.props.career) {
            careerEntry.id = this.props.career.id;
        }

        this.props.changeCareerData(careerEntry);
        this.props.toggleModal();
    };

    private setFormConfig = () => {
        const seniorityLevels = convertSeniorityToRangeValues(this.props.seniorityLevels);

        const formConfig = addCareerFormConfig(seniorityLevels);
        this.setState({formConfig});
    };

    private setDefaultFormValues = (career: ICareerEntry) => {
        const value = {
            companyName: career.company,
            endDate: career.to,
            isWorking: career.to === null,
            position: career.position,
            seniorityLevel: career.seniority.id,
            startDate: career.from,
        };
        this.setState({value});
    };

    private isFormComplete = () => {
        return this.state.value && this.state.value.companyName && this.state.value.position && this.state.value.startDate;
    };
}

export default connect(
    (state: RootState) => ({
        seniorityLevels: senioritySelector(state),
    }),
    {
        changeCareerData,
    }
)(AddCareer);
