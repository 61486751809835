import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IOffer, OfferStatus} from './offersPageSlice';
import {ICandidateFullInfo, IOrganizationFullInfo} from 'jobhunter-common-web';

export interface IBestMatchOffer extends IModelApiObject {
    observed: boolean;
    offer: IOffer;
    status: OfferStatus;
}

export interface IChatContact extends IModelApiObject {
    readonly candidateFullInfo: typeof ICandidateFullInfo;
    readonly organizationFullInfo: typeof IOrganizationFullInfo;
}

export interface IOfferApplication extends IModelApiObject {
    candidateMatched: boolean;
    headhunterMatched: boolean;
    id: string;
    offer: IOffer;
    status: OfferStatus;
    relocationStatus: string | null;
    createdAt: string;
    chatContact: IChatContact;
}

export interface IProfileCompletion {
    isPersonalDataComplete: boolean;
    isCareerComplete: boolean;
    isSkillDataComplete: boolean;
    isPreferencesDataComplete: boolean;
}

export interface IDashboardState {
    bestMatchOffers: IBestMatchOffer[] | null;
    applications: IOfferApplication[] | null;
    profileDetails: IProfileCompletion | null;
    calendarDetails: any | null;
    isDashboardLoading: boolean;
    isBestMatchListLoading: boolean;
    isApplicationsListLoading: boolean;
    isCalendarLoading: boolean;
    isDashboardInitialized: boolean;
    dashboardError: string | null;
}

export interface ISetBestMatchOffers {
    bestMatchOffers: IBestMatchOffer[] | null;
}

export interface ISetApplications {
    applications: IOfferApplication[] | null;
}

export interface ISetProfileDetails {
    profileDetails: IProfileCompletion | null;
}

export interface ISetCalendarDetails {
    calendarDetails: any | null;
}

export interface IChangeIsDashboardLoading {
    isDashboardLoading: boolean;
}

export interface IChangeIsDashboardInitialized {
    isDashboardInitialized: boolean;
}

export interface IChangeDashboardError {
    error: string | null;
}

const initialState: IDashboardState = {
    bestMatchOffers: null,
    applications: null,
    profileDetails: null,
    calendarDetails: null,
    isDashboardLoading: false,
    isDashboardInitialized: false,
    isBestMatchListLoading: false,
    isApplicationsListLoading: false,
    isCalendarLoading: false,
    dashboardError: null,
};

const dashboardPageSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setBestMatchOffers: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetBestMatchOffers>) => {
                return {
                    ...state,
                    bestMatchOffers: action.payload.bestMatchOffers,
                };
            },
            prepare(bestMatchOffers: IBestMatchOffer[] | null) {
                return {
                    payload: {
                        bestMatchOffers: bestMatchOffers,
                    },
                };
            },
        },
        setApplications: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetApplications>) => {
                return {
                    ...state,
                    applications: action.payload.applications,
                };
            },
            prepare(applications: IOfferApplication[] | null) {
                return {
                    payload: {
                        applications: applications,
                    },
                };
            },
        },
        setProfileDetails: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetProfileDetails>) => {
                return {
                    ...state,
                    profileDetails: action.payload.profileDetails,
                };
            },
            prepare(profileDetails: IProfileCompletion | null) {
                return {
                    payload: {
                        profileDetails: profileDetails,
                    },
                };
            },
        },
        setCalendarDetails: {
            reducer: (state: IDashboardState, action: PayloadAction<ISetCalendarDetails>) => {
                return {
                    ...state,
                    calendarDetails: action.payload.calendarDetails,
                };
            },
            prepare(calendarDetails: any) {
                return {
                    payload: {
                        calendarDetails: calendarDetails,
                    },
                };
            },
        },
        changeIsDashboardLoading: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardLoading>) => {
                return {
                    ...state,
                    isDashboardLoading: action.payload.isDashboardLoading,
                };
            },
            prepare(isDashboardLoading: boolean) {
                return {
                    payload: {isDashboardLoading: isDashboardLoading},
                };
            },
        },
        changeIsDashboardInitialized: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardInitialized>) => {
                return {
                    ...state,
                    isDashboardInitialized: action.payload.isDashboardInitialized,
                };
            },
            prepare(isDashboardInitialized: boolean) {
                return {
                    payload: {isDashboardInitialized: isDashboardInitialized},
                };
            },
        },
        changeDashboardError: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeDashboardError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchBestMatchOffers: (state: IDashboardState) => {
            return {
                ...state,
                isBestMatchListLoading: true,
            };
        },
        changeIsBestMatchListLoading: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardLoading>) => {
                return {
                    ...state,
                    isBestMatchListLoading: action.payload.isDashboardLoading,
                };
            },
            prepare(isDashboardLoading: boolean) {
                return {
                    payload: {isDashboardLoading: isDashboardLoading},
                };
            },
        },
        fetchApplications: (state: IDashboardState) => {
            return {
                ...state,
                isApplicationsListLoading: true,
            };
        },
        changeIsApplicationsListLoading: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardLoading>) => {
                return {
                    ...state,
                    isApplicationsListLoading: action.payload.isDashboardLoading,
                };
            },
            prepare(isDashboardLoading: boolean) {
                return {
                    payload: {isDashboardLoading: isDashboardLoading},
                };
            },
        },
        fetchProfileDetails: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        fetchCalendarDetails: (state: IDashboardState) => {
            return {
                ...state,
                isDashboardLoading: true,
            };
        },
        changeIsCalendarLoading: {
            reducer: (state: IDashboardState, action: PayloadAction<IChangeIsDashboardLoading>) => {
                return {
                    ...state,
                    isCalendarLoading: action.payload.isDashboardLoading,
                };
            },
            prepare(isDashboardLoading: boolean) {
                return {
                    payload: {isDashboardLoading: isDashboardLoading},
                };
            },
        },
        resetToInitialDashboardPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setBestMatchOffers,
    setApplications,
    setProfileDetails,
    setCalendarDetails,
    changeIsDashboardLoading,
    changeIsDashboardInitialized,
    changeDashboardError,
    fetchBestMatchOffers,
    changeIsBestMatchListLoading,
    fetchApplications,
    changeIsApplicationsListLoading,
    fetchProfileDetails,
    fetchCalendarDetails,
    changeIsCalendarLoading,
    resetToInitialDashboardPageState,
} = dashboardPageSlice.actions;

export default dashboardPageSlice.reducer;
