import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOffersState} from '../reducers/offersPageSlice';

export const selectOffersPage = (state: RootState): IOffersState => {
    return state.offersPage;
};

export const offerFiltersSelector = createSelector([selectOffersPage], (state: IOffersState) => state.filters);

export const offersListSelector = createSelector([selectOffersPage], (state: IOffersState) => state.offersList);

export const offersListTypeSelector = createSelector([selectOffersPage], (state: IOffersState) => state.offersListType);

export const isOffersLoadingSelector = createSelector([selectOffersPage], (state: IOffersState) => state.isOfferListLoading);

export const isOffersInitializedSelector = createSelector([selectOffersPage], (state: IOffersState) => state.isOffersInitialized);

export const offersErrorSelector = createSelector([selectOffersPage], (state: IOffersState) => state.offersError);

export const isOfferActionCompleteSelector = createSelector([selectOffersPage], (state: IOffersState) => state.isOfferActionComplete);

export const offersMetadataSelector = createSelector([selectOffersPage], (state: IOffersState) => state.offersMetadata);

export const offersPaginationSelector = createSelector([selectOffersPage], (state: IOffersState) => state.pagination);

export const offersSearchIdSelector = createSelector([selectOffersPage], (state: IOffersState) => state.searchId);
