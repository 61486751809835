import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AlertType} from 'jobhunter-common-web';

const uuid = require('uuid');

export interface AlertObject {
    message: string;
    displayFor?: number;
    id?: number;
    displayDate?: number;
    priority?: number;
    type?: typeof AlertType;
    isTranslated?: boolean;
}

export interface IAlertState {
    alerts: AlertObject[] | [];
    alertsDisabled: boolean;
}

export interface IAddAlert {
    alert: AlertObject;
}

export interface IRemoveAlert {
    id: number;
}

export interface IChangeAlertsDisabled {
    alertsDisabled: boolean;
}

const initialState: IAlertState = {
    alerts: [],
    alertsDisabled: false,
};

const alertSlice = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        addAlert: {
            reducer: (state: IAlertState, action: PayloadAction<IAddAlert>) => {
                const alerts = [...state.alerts];
                if (!action.payload.alert.id) {
                    action.payload.alert.id = uuid.v4();
                }
                if (!action.payload.alert.displayDate) {
                    action.payload.alert.displayDate = Date.now();
                }
                if (!action.payload.alert.displayFor) {
                    action.payload.alert.displayFor = 5000;
                }
                if (!action.payload.alert.hasOwnProperty('isTranslated')) {
                    action.payload.alert.isTranslated = true;
                }

                return {
                    alerts: [...alerts, action.payload.alert],
                    alertsDisabled: state.alertsDisabled,
                };
            },
            prepare(alert: AlertObject) {
                return {
                    payload: {alert: alert},
                };
            },
        },
        removeAlert: {
            reducer: (state: IAlertState, action: PayloadAction<IRemoveAlert>) => {
                const alerts = [...state.alerts];

                return {
                    alerts: alerts.filter((alert) => {
                        return alert.id !== action.payload.id;
                    }),
                    alertsDisabled: state.alertsDisabled,
                };
            },
            prepare(id: number) {
                return {
                    payload: {id: id},
                };
            },
        },
        changeAlertsDisabled: {
            reducer: (state: IAlertState, action: PayloadAction<IChangeAlertsDisabled>) => {
                return {
                    alerts: state.alerts,
                    alertsDisabled: action.payload.alertsDisabled,
                };
            },
            prepare(disableErrors: boolean) {
                return {
                    payload: {alertsDisabled: disableErrors},
                };
            },
        },
    },
});

export const {addAlert, removeAlert, changeAlertsDisabled} = alertSlice.actions;
export default alertSlice.reducer;
