import {combineEpics} from 'redux-observable';
import {
    accountEpic,
    changePasswordEpic,
    dictionaryDataEpic,
    loginEpic,
    reauthenticateEpic,
    renewAuthTokenEpic,
    chatContactsEpic,
    authEpic,
} from 'jobhunter-common-web';
import profilePageEpic from './profilePageEpic';
import offersEpic from './offersEpic';
import dashboardEpic from './dashboardPageEpic';
import offerDetailsEpic from './offerDetailsPageEpic';
import marketplacePageEpic from './marketplacePageEpic';
import purchasedServicesPageEpic from './purchasedServicesPageEpic';
import relocationsPageEpic from './relocationsPageEpic';
import relocationDetailsPageEpic from './relocationDetailsPageEpic';

export const rootEpic = combineEpics(
    loginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic,
    authEpic,
    changePasswordEpic,
    accountEpic,
    dictionaryDataEpic,
    profilePageEpic,
    chatContactsEpic,
    offersEpic,
    offerDetailsEpic,
    dashboardEpic,
    marketplacePageEpic,
    purchasedServicesPageEpic,
    relocationsPageEpic,
    relocationDetailsPageEpic
);
