import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LanguageLocale} from 'jobhunter-common-web';

export enum ContentWidth {
    BOXED = 'boxed',
    FULL = 'full',
}

export enum RouterTransition {
    FADE_IN = 'fadeIn',
    FADE_IN_LEFT = 'fadeInLeft',
    ZOOM_IN = 'zoomIn',
    NONE = 'none',
}

export interface ISagaState {
    activeLanguage: typeof LanguageLocale;
    contentWidth: ContentWidth;
    routerTransition: RouterTransition;
    menuCollapsed: boolean;
    menuHidden: boolean;
    showLoader: boolean;
}

export interface IChangeLanguage {
    activeLanguage: typeof LanguageLocale;
}

export interface IChangeShowLoader {
    readonly payload: {
        showLoader: boolean;
    };
}

const initialState: ISagaState = {
    activeLanguage: LanguageLocale?.EN,
    contentWidth: ContentWidth.BOXED,
    routerTransition: RouterTransition.FADE_IN,
    menuCollapsed: false,
    menuHidden: false,
    showLoader: false,
};

const sagaSlice = createSlice({
    name: 'saga',
    initialState: initialState,
    reducers: {
        changeActiveLanguage: {
            reducer: (state: ISagaState, action: PayloadAction<IChangeLanguage>) => {
                return {
                    activeLanguage: action.payload.activeLanguage,
                    contentWidth: state.contentWidth,
                    routerTransition: state.routerTransition,
                    menuCollapsed: state.menuCollapsed,
                    menuHidden: state.menuHidden,
                    showLoader: state.showLoader,
                };
            },
            prepare(activeLanguage: typeof LanguageLocale) {
                return {
                    payload: {activeLanguage: activeLanguage},
                };
            },
        },
        changeContentWidth: {
            reducer: (state: ISagaState, action: PayloadAction<{contentWidth: ContentWidth}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    contentWidth: action.payload.contentWidth,
                    routerTransition: state.routerTransition,
                    menuCollapsed: state.menuCollapsed,
                    menuHidden: state.menuHidden,
                    showLoader: state.showLoader,
                };
            },
            prepare(contentWidth: ContentWidth) {
                return {
                    payload: {contentWidth: contentWidth},
                };
            },
        },
        changeRouterTransition: {
            reducer: (state: ISagaState, action: PayloadAction<{routerTransition: RouterTransition}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    contentWidth: state.contentWidth,
                    routerTransition: action.payload.routerTransition,
                    menuCollapsed: state.menuCollapsed,
                    menuHidden: state.menuHidden,
                    showLoader: state.showLoader,
                };
            },
            prepare(routerTransition: RouterTransition) {
                return {
                    payload: {routerTransition: routerTransition},
                };
            },
        },
        changeMenuCollapsed: {
            reducer: (state: ISagaState, action: PayloadAction<{isMenuCollapsed: boolean}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    contentWidth: state.contentWidth,
                    routerTransition: state.routerTransition,
                    menuCollapsed: action.payload.isMenuCollapsed,
                    menuHidden: state.menuHidden,
                    showLoader: state.showLoader,
                };
            },
            prepare(isMenuCollapsed: boolean) {
                return {
                    payload: {isMenuCollapsed: isMenuCollapsed},
                };
            },
        },
        changeMenuHidden: {
            reducer: (state: ISagaState, action: PayloadAction<{isMenuHidden: boolean}>) => {
                return {
                    activeLanguage: state.activeLanguage,
                    contentWidth: state.contentWidth,
                    routerTransition: state.routerTransition,
                    menuCollapsed: state.menuCollapsed,
                    menuHidden: action.payload.isMenuHidden,
                    showLoader: state.showLoader,
                };
            },
            prepare(isMenuHidden: boolean) {
                return {
                    payload: {isMenuHidden: isMenuHidden},
                };
            },
        },
        changeShowLoader: {
            reducer: (state: ISagaState, action: IChangeShowLoader) => {
                return {
                    activeLanguage: state.activeLanguage,
                    contentWidth: state.contentWidth,
                    routerTransition: state.routerTransition,
                    menuCollapsed: state.menuCollapsed,
                    menuHidden: state.menuHidden,
                    showLoader: action.payload.showLoader,
                };
            },
            prepare(showLoader: boolean) {
                return {
                    payload: {showLoader: showLoader},
                };
            },
        },
    },
});

export const {changeActiveLanguage, changeContentWidth, changeRouterTransition, changeMenuCollapsed, changeMenuHidden, changeShowLoader} =
    sagaSlice.actions;

export default sagaSlice.reducer;
