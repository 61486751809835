import {DateComponent, Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Edit3, Trash2} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Badge, Button} from 'reactstrap';
import AddCareer from './AddCareer';
import {ICareerEntry, removeCareerEntryData} from '../../../../store/reducers/profilePageSlice';
import DeleteSkillsModal from '../DeleteSkillsModal';

interface IConnectedCareerEntriesProps {}

interface IExternalCareerEntriesProps {
    readonly career: ICareerEntry[] | null;
    readonly removeCareerEntryData: typeof removeCareerEntryData;
}

interface ICareerEntriesProps extends IConnectedCareerEntriesProps, IExternalCareerEntriesProps, WithTranslation {}

interface ICareerEntriesState {
    isCareerModalVisible: boolean;
    isDeleteModalVisible: boolean;
    selectedCareerEntry: ICareerEntry | null;
    isLoading: boolean;
}

class CareerEntries extends Component<ICareerEntriesProps, ICareerEntriesState> {
    constructor(props: ICareerEntriesProps) {
        super(props);

        this.state = {
            isCareerModalVisible: false,
            isDeleteModalVisible: false,
            selectedCareerEntry: null,
            isLoading: false,
        };
    }

    render() {
        return (
            <>
                <div className="career-section">
                    <p className="title">
                        <Translation text="profile.career.title" />
                    </p>
                    <p className="sub-title">
                        <Translation text="profile.career.careerTimeline" />
                    </p>
                    {!this.props.career || !this.props.career.length ? (
                        <p className="no-options">
                            <Translation text="profile.career.noCareer" />
                        </p>
                    ) : (
                        this.props.career.map((item: any, index: number) => this.renderCareerItem(item, index))
                    )}

                    <div className="add-btn-container">
                        <Button color="primary" onClick={() => this.toggleAddCareer()} className="add-new-career-button">
                            <Translation text="buttons.addNew" />
                        </Button>
                    </div>
                </div>

                {this.state.isCareerModalVisible ? (
                    <AddCareer
                        isModalOpen={this.state.isCareerModalVisible}
                        toggleModal={this.toggleAddCareer}
                        career={this.state.selectedCareerEntry}
                    />
                ) : null}

                {this.state.isDeleteModalVisible ? (
                    <DeleteSkillsModal
                        isModalOpen={this.state.isDeleteModalVisible}
                        toggleModal={this.toggleDeleteModal}
                        removeAction={this.removeCareerEntry}
                    />
                ) : null}
            </>
        );
    }

    private renderCareerItem = (item: ICareerEntry, index: number) => {
        return (
            <div className="career-item" key={item.id ? item.id : `career-${index}`}>
                <div className="duration">
                    <p>
                        <DateComponent date={item.from} format="DD.MM.YYYY" />
                    </p>
                    <p>{item.to ? <DateComponent date={item.to} format="DD.MM.YYYY" /> : 'now'}</p>
                </div>
                <div className="career-details">
                    <div className="company-details">
                        <p className="company">{item.company}</p>
                        <p>{item.position}</p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={item.seniority.name} />
                            </Badge>
                        </div>
                        <div className="actions">
                            <button className="remove-career-button" onClick={() => this.toggleDeleteModal(item)}>
                                <Trash2 />
                            </button>
                            <button className="edit-career-button" onClick={() => this.toggleAddCareer(item)}>
                                <Edit3 />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private toggleAddCareer = (careerEntry?: ICareerEntry | null) => {
        this.setState({
            isCareerModalVisible: !this.state.isCareerModalVisible,
            selectedCareerEntry: careerEntry ? careerEntry : null,
        });
    };

    private toggleDeleteModal = (careerEntry?: ICareerEntry | null) => {
        this.setState({
            isDeleteModalVisible: !this.state.isDeleteModalVisible,
            selectedCareerEntry: careerEntry ? careerEntry : null,
        });
    };

    private removeCareerEntry = () => {
        if (this.state.selectedCareerEntry && this.state.selectedCareerEntry.id) {
            this.props.removeCareerEntryData(this.state.selectedCareerEntry.id);
        }
    };
}

export default withTranslation()(CareerEntries);
