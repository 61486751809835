import {FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const socialLinksFormConfig: typeof IFormConfig = (value?: any, githubIcon?: any, linkedinIcon?: any, portfolioIcon?: any) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'social_links',
            controlType: 'group',
            class: 'row',
            controls: {
                github: {
                    controlType: 'control',
                    defaultValue: value ? value.github : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_VALID_URL}],
                    placeholder: 'profile.career.socialLinks.placeholders.github',
                    isLabelHidden: true,
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                    renderPreIcon: githubIcon,
                },
                linkedin: {
                    controlType: 'control',
                    defaultValue: value ? value.linkedin : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_VALID_URL}],
                    placeholder: 'profile.career.socialLinks.placeholders.linkedin',
                    isLabelHidden: true,
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                    renderPreIcon: linkedinIcon,
                },
                portfolio: {
                    controlType: 'control',
                    defaultValue: value ? value.portfolio : '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_VALID_URL}],
                    placeholder: 'profile.career.socialLinks.placeholders.portfolio',
                    isLabelHidden: true,
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                    renderPreIcon: portfolioIcon,
                },
            },
        },
    ],
});
