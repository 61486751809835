import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {
    convertToMultiselectLabels,
    Form,
    FormControlChangeType,
    IFormConfig,
    IModelDictionaryDatum,
    IModelSeniority,
    isSameValue,
    Translation,
    senioritySelector,
    technologiesSelector,
    technologyToolsSelector,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {filter, tap} from 'rxjs/operators';
import {addTechnologyFormConfig} from './addTechnologyFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {changeTechnologySkillsData, ITechnologyEntry} from '../../../../../store/reducers/profilePageSlice';
import {WithTranslation, withTranslation} from 'react-i18next';

export const convertArrayToObject = (array: any, key: any) => {
    const initialValue = {};
    return array.reduce((obj: any, item: any) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

export const convertSeniorityToRangeValues = (seniorityLevels: typeof IModelSeniority[] | null) => {
    if (null === seniorityLevels) {
        return {};
    }

    const rangeLabels: any = [];
    seniorityLevels.map((level: typeof IModelSeniority) => {
        const obj: any = {
            label: level['name'],
            value: level['id'],
            level: level['level'],
        };

        return rangeLabels.push(obj);
    });

    return convertArrayToObject(rangeLabels, 'level');
};

interface IConnectedAddTechnologyProps {
    readonly technologies: typeof IModelDictionaryDatum[] | null;
    readonly technologyTools: typeof IModelDictionaryDatum[] | null;
    readonly seniorityLevels: typeof IModelSeniority[] | null;
    readonly technologySkills: ITechnologyEntry[] | undefined;
    readonly changeTechnologySkillsData: typeof changeTechnologySkillsData;
}

interface IExternalAddTechnologyProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    technologySkill: ITechnologyEntry | null;
}

interface IAddTechnologyProps extends IConnectedAddTechnologyProps, IExternalAddTechnologyProps, WithTranslation {}

interface IAddTechnologyState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class AddTechnology extends Component<IAddTechnologyProps, IAddTechnologyState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddTechnologyProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        if (this.props.technologySkill) {
            this.setDefaultFormValues(this.props.technologySkill);
        }

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IAddTechnologyProps>) {
        if (
            !isSameValue(this.props.technologies, prevProps.technologies) ||
            !isSameValue(this.props.technologyTools, prevProps.technologyTools) ||
            !isSameValue(this.props.seniorityLevels, prevProps.seniorityLevels)
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation
                        text={this.props.technologySkill ? 'profile.career.editTechnology.title' : 'profile.career.addTechnology.title'}
                    />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="profile.career.addTechnology.description" />
                    </p>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'addTechnologyForm'}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className={this.props.technologySkill ? 'update-technology-button' : 'add-technology-button'}
                        onClick={() => this.addTechnology()}
                        disabled={!this.isFormComplete()}>
                        <Translation text={this.props.technologySkill ? 'buttons.save' : 'buttons.add'} />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private addTechnology = () => {
        this.setState({isLoading: true});
        const formData = this.state.value,
            isDefaultSeniority = formData.level === '',
            technology: typeof IModelDictionaryDatum | undefined = this.props.technologies?.find(
                (technology) => technology.id === formData.technology
            ),
            technologyTools: typeof IModelDictionaryDatum[] | undefined = formData.tools?.map((toolId: string) => {
                return this.props.technologyTools?.filter((tool) => (tool.id === toolId ? tool : null))[0];
            }),
            seniorityLevel: typeof IModelSeniority | undefined = this.props.seniorityLevels?.find((level) =>
                isDefaultSeniority ? level.level === 0 : level.id === formData.level
            );

        if (undefined === technology || technologyTools === undefined || seniorityLevel === undefined) {
            return;
        }

        const technologyEntry: ITechnologyEntry = {
            technology: technology,
            technologyTool: technologyTools,
            seniority: seniorityLevel,
        };

        if (this.props.technologySkill) {
            technologyEntry.id = this.props.technologySkill.id;
        }

        this.props.changeTechnologySkillsData(technologyEntry);
        this.props.toggleModal();
    };

    private setFormConfig = () => {
        const {t} = this.props,
            technologies = sortMultiselectLabels(convertToMultiselectLabels(this.props.technologies, t)),
            selectedTechnology = this.props.technologySkill,
            technologyTools = sortMultiselectLabels(convertToMultiselectLabels(this.props.technologyTools, t)),
            seniorityLevels = convertSeniorityToRangeValues(this.props.seniorityLevels),
            filteredTechnologyOptions =
                null === selectedTechnology
                    ? technologies?.filter((technology: any) => {
                          return !this.props.technologySkills?.some((item: any) => technology.value === item.technology.id);
                      })
                    : technologies,
            filteredToolOptions =
                null === selectedTechnology
                    ? technologyTools?.filter((technology: any) => {
                          return !this.props.technologySkills?.some((item: any) => {
                              return item.technologyTool.some((tool: any) => technology.value === tool.id);
                          });
                      })
                    : technologyTools;

        const formConfig = addTechnologyFormConfig(
            filteredTechnologyOptions,
            filteredToolOptions,
            seniorityLevels,
            selectedTechnology !== null
        );
        this.setState({formConfig});
    };

    private setDefaultFormValues = (technology: ITechnologyEntry) => {
        const technologyTools = technology.technologyTool.map((tool) => tool.id),
            value = {
                level: technology.seniority.id,
                technology: technology.technology.id,
                tools: technologyTools,
            };

        this.setState({value});
    };

    private isFormComplete = () => {
        return this.state.value && this.state.value.technology && this.state.value.tools;
    };
}

export default connect(
    (state: RootState) => ({
        technologies: technologiesSelector(state),
        technologyTools: technologyToolsSelector(state),
        seniorityLevels: senioritySelector(state),
    }),
    {
        changeTechnologySkillsData,
    }
)(withTranslation()(AddTechnology));
