import React, {Component} from 'react';
import {Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Target} from 'react-feather';
import {
    convertToPriceString,
    DateComponent,
    ListItemDetails,
    Loader,
    LoaderType,
    Translation,
    WithNavigate,
    withNavigation,
} from 'jobhunter-common-web';
import {IBestMatchOffer, IProfileCompletion} from '../../../store/reducers/dashboardPageSlice';
import {IOffer} from '../../../store/reducers/offersPageSlice';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedBestMatchOffersProps {}

interface IExternalBestMatchOffersProps {
    readonly bestMatchOffers: IBestMatchOffer[] | null;
    readonly profileCompletion: IProfileCompletion | null;
    readonly isBestMatchListLoading: boolean;
}

interface IBestMatchOffersProps extends IConnectedBestMatchOffersProps, IExternalBestMatchOffersProps, WithNavigationProps {}

class BestMatchOffers extends Component<IBestMatchOffersProps> {
    render() {
        return (
            <Card className="best-match-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <Target size={20} />
                                <Translation text="dashboard.bestMatch.title" />
                            </div>
                            <button
                                disabled={!this.props.bestMatchOffers || !this.props.bestMatchOffers.length}
                                className="btn btn-primary best-match-button"
                                onClick={() => this.redirectToOffers()}>
                                <Translation text="buttons.showAll" />
                            </button>
                        </div>
                    </CardTitle>
                    <Table responsive className="best-match-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="dashboard.bestMatch.table.headers.project" />
                                </th>
                                <th>
                                    <Translation text="dashboard.bestMatch.table.headers.city" />
                                </th>
                                <th>
                                    <Translation text="dashboard.bestMatch.table.headers.salary" />
                                </th>
                                <th>
                                    <Translation text="dashboard.bestMatch.table.headers.publication" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableRows()}</tbody>
                    </Table>
                </CardBody>

                <Loader type={LoaderType.Local} showLoader={this.props.isBestMatchListLoading} />
            </Card>
        );
    }

    private renderTableRows() {
        const offers = this.props.bestMatchOffers,
            isProfileComplete =
                this.props.profileCompletion?.isPreferencesDataComplete && this.props.profileCompletion?.isPersonalDataComplete,
            bestMatchListResults = !isProfileComplete ? 'dashboard.bestMatch.completeProfile' : 'dashboard.bestMatch.noData';

        if (null === offers || offers.length <= 0) {
            return (
                <tr key={'best_match_no_data'}>
                    <td colSpan={4}>
                        <Translation text={bestMatchListResults} />
                    </td>
                </tr>
            );
        } else {
            return offers.map((item: IBestMatchOffer) => {
                const offer: IOffer = item.offer;

                return (
                    <tr key={`best_match_${offer.id}`}>
                        <td>
                            <ListItemDetails avatar={offer.avatar} name={offer.name} />
                        </td>
                        <td>
                            <Translation text={offer.city.name} />
                        </td>
                        <td>
                            {convertToPriceString(offer.minimumSalary)} - {convertToPriceString(offer.maximumSalary)}
                        </td>
                        <td>{offer.publishAt ? <DateComponent date={offer.publishAt} /> : <p>---</p>}</td>
                    </tr>
                );
            });
        }
    }

    private redirectToOffers = () => {
        this.props.navigate('/panel/offers', {state: {isBestMatchSelected: true}});
    };
}

export default withNavigation(BestMatchOffers);
