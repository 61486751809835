import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {DateComponent, HtmlParserComponent, Translation} from 'jobhunter-common-web';
import {CalendarIconType, ICalendarEvent} from '../../../../model/calendarEventModel';
import classnames from 'classnames';

interface IEventCalendarDetailsModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    event: ICalendarEvent | null;
    getTitle: (item: ICalendarEvent) => string;
    getIcon: (item: ICalendarEvent | null) => {[key: string]: any};
    getDescription: (item: ICalendarEvent) => string;
}

class EventCalendarDetailsModal extends Component<IEventCalendarDetailsModalProps> {
    render() {
        const eventIcon = this.props.getIcon(this.props.event);
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()} />
                <ModalBody>
                    <p className="modal-title">
                        {this.props.event ? this.props.getTitle(this.props.event) : <Translation text="relocationView.calendar.noTitle" />}
                    </p>
                    <p className="event-date">
                        <div
                            className={classnames(
                                'event-icon',
                                {warning: eventIcon.style === CalendarIconType.WARNING},
                                {alert: eventIcon.style === CalendarIconType.ALERT}
                            )}>
                            {eventIcon.element}
                        </div>

                        <DateComponent date={this.props.event?.startsAt} format="HH:mm, DD.MM.YYYY" />
                    </p>
                    <p className="modal-description text-start">
                        {this.props.event ? (
                            <HtmlParserComponent htmlContent={this.props.getDescription(this.props.event)} />
                        ) : (
                            <Translation text="relocationView.calendar.noDescription" />
                        )}
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="close-modal-button" outline onClick={() => this.props.toggleModal()}>
                        <Translation text="buttons.cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default EventCalendarDetailsModal;
