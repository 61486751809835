import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IOfferRecommendationPayload {
    email: string;
}

export function sendOfferRecommendationAPI(
    authToken: string | null,
    offerId: string,
    payload: IOfferRecommendationPayload
): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }
    return jobHunterAPI.put(`api/offers/${offerId}/recommend`, payload, new RestQueryParams(), headers);
}
