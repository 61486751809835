import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IRelocationDetailsState} from '../reducers/relocationDetailsPageSlice';

export const selectRelocationDetailsPage = (state: RootState): IRelocationDetailsState => {
    return state.relocationDetailsPage;
};

export const relocationDetailsSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.relocationDetails
);

export const calendarDetailsSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.calendarDetails
);

export const relocationFilesSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.relocationFiles
);

export const isRelocationDetailsLoadingSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.isRelocationDetailsLoading
);

export const isRelocationDetailsInitializedSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.isRelocationDetailsInitialized
);

export const relocationDetailsErrorSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.relocationDetailsError
);

export const isRelocationFileRemovedSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.isRelocationFileRemoved
);

export const isRelocationFilesListLoadingSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.isRelocationFilesListLoading
);

export const isRelocationEventAddedSelector = createSelector(
    [selectRelocationDetailsPage],
    (state: IRelocationDetailsState) => state.isRelocationEventAdded
);
