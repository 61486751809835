import {
    companyTypesSelector,
    contractTypesSelector,
    convertToMultiselectLabels,
    Form,
    FormControlChangeType,
    industriesSelector,
    IFormConfig,
    IModelDictionaryDatum,
    IModelSeniority,
    isSameValue,
    senioritySelector,
    technologiesSelector,
    Translation,
    workTypesSelector,
    employmentTypesSelector,
    candidateFullInfoSelector,
    ICandidateFullInfo,
    convertPriceToInputValue,
    convertPriceToPayloadValue,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {filtersFormConfig} from './filtersFormConfig';
import styles from './styles.module.scss';
import {Button} from 'reactstrap';
import {ChevronDown, ChevronUp} from 'react-feather';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {applyOffersFilters, changeOffersFilters, IOfferFilters} from '../../../store/reducers/offersPageSlice';
import {convertSeniorityToRangeValues} from '../../Profile/Career/TechnologySkills/AddTechnologySkills';

interface IConnectedOfferFiltersProps {
    readonly technologies: typeof IModelDictionaryDatum[] | null;
    readonly seniorityLevels: typeof IModelSeniority[] | null;
    readonly contractTypes: typeof IModelDictionaryDatum[] | null;
    readonly industries: typeof IModelDictionaryDatum[] | null;
    readonly companyTypes: typeof IModelDictionaryDatum[] | null;
    readonly workTypes: typeof IModelDictionaryDatum[] | null;
    readonly employmentTypes: typeof IModelDictionaryDatum[] | null;
    readonly candidateAccount: typeof ICandidateFullInfo | null;
    readonly changeOffersFilters: typeof changeOffersFilters;
    readonly applyOffersFilters: typeof applyOffersFilters;
}

interface IExternalOfferFiltersProps {
    readonly isBestMatchSet: boolean;
}

interface IOfferFiltersProps extends IConnectedOfferFiltersProps, IExternalOfferFiltersProps, WithTranslation {}

interface IOfferFiltersState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
    isFormExpanded: boolean;
}

class OfferFilters extends Component<IOfferFiltersProps, IOfferFiltersState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOfferFiltersProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
            isFormExpanded: false,
        };
    }

    componentDidMount(): void {
        this.updateFormStateValue();
        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.changeFilters(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IOfferFiltersProps>, prevState: Readonly<IOfferFiltersState>): void {
        if (this.props.isBestMatchSet !== prevProps.isBestMatchSet) {
            this.updateFormStateValue();
        }

        if (
            !isSameValue(this.state.isFormExpanded, prevState.isFormExpanded) ||
            !isSameValue(this.state.value?.bestMatch, prevState.value?.bestMatch) ||
            !isSameValue(this.props.technologies, prevProps.technologies) ||
            !isSameValue(this.props.seniorityLevels, prevProps.seniorityLevels) ||
            !isSameValue(this.props.contractTypes, prevProps.contractTypes) ||
            !isSameValue(this.props.industries, prevProps.industries) ||
            !isSameValue(this.props.companyTypes, prevProps.companyTypes)
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className={styles.formContainer}>
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        controlName={'offerFiltersForm'}
                    />
                )}
                <div className={styles.btnContainer}>
                    <Button color="flat-primary" onClick={() => this.setState({isFormExpanded: !this.state.isFormExpanded})}>
                        <span className="align-middle me-1">
                            {this.state.isFormExpanded ? (
                                <Translation text="offers.filters.lessFilters" />
                            ) : (
                                <Translation text="offers.filters.moreFilters" />
                            )}
                        </span>
                        {this.state.isFormExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                    </Button>
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        const {t} = this.props;
        const technologies = this.props.technologies ? sortMultiselectLabels(convertToMultiselectLabels(this.props.technologies, t)) : [],
            seniorityLevels = this.props.seniorityLevels ? convertSeniorityToRangeValues(this.props.seniorityLevels) : [],
            contractTypes = this.props.contractTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.contractTypes, t)) : [],
            industries = this.props.industries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.industries, t)) : [],
            companyTypes = this.props.companyTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.companyTypes, t)) : [],
            workTypes = this.props.workTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.workTypes, t)) : [],
            employmentTypes = this.props.employmentTypes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.employmentTypes, t, true))
                : [],
            isPreferencesDataSet = this.props.candidateAccount.account.filled.preferences,
            isProfileDataSet = this.props.candidateAccount.account.filled.personal,
            isBestMatchDisabled = !isPreferencesDataSet || !isProfileDataSet;

        this.setState({
            formConfig: filtersFormConfig(
                this.state.isFormExpanded,
                technologies,
                seniorityLevels,
                contractTypes,
                industries,
                companyTypes,
                workTypes,
                employmentTypes,
                isBestMatchDisabled,
                this.state.value
            ),
        });
    };

    private updateFormStateValue = () => {
        const isPreferencesDataSet = this.props.candidateAccount.account.filled.preferences,
            isProfileDataSet = this.props.candidateAccount.account.filled.personal,
            preferences = this.props.candidateAccount?.preferences,
            technologies = this.props.candidateAccount?.technologies;

        let value = this.state.value ? this.state.value : {};

        value = {
            companyType: preferences?.companyTypes?.map((type: {[key: string]: any}) => type.id),
            contractType: preferences?.contractTypes?.map((type: {[key: string]: any}) => type.id),
            employmentType: preferences?.employmentTypes?.map((type: {[key: string]: any}) => type.id),
            industry: preferences?.industries?.map((industry: {[key: string]: any}) => industry.id),
            workType: preferences?.workTypes?.map((type: {[key: string]: any}) => type.id),
            relocation: {
                withRelocation: preferences?.relocationOnly,
            },
            salary: convertPriceToInputValue(preferences?.minimumSalary),
            seniority: technologies[0]?.seniority?.id,
            technology: preferences?.technologies?.map((technology: {[key: string]: any}) => technology.id),
        };

        if (isPreferencesDataSet && isProfileDataSet) {
            value.bestMatch = this.props.isBestMatchSet;
        }

        this.setState({value});
    };

    private changeFilters = (value: {[key: string]: any}) => {
        // toDo replace with filter model once bestMatch filter is implemented on backend
        // if (this.filter && isNotNullOrUndefined(value)) {
        // const obj = this.filter.withOfferFiltersFromForm(value);
        // }
        this.setState({value: value});

        const filters: IOfferFilters = {
            offerTechnologies: {
                technology: {
                    id: value.technology,
                },
            },
            seniority: {
                id: value.seniority,
            },
            minimumSalary: convertPriceToPayloadValue(value.salary),
            best_match: value.bestMatch,
            contractTypes: {
                id: value.contractType,
            },
            industries: {
                id: value.industry,
            },
            companyTypes: {
                id: value.companyType,
            },
            employmentTypes: {
                id: value.employmentType,
            },
            workTypes: {
                id: value.workType,
            },
            relocation: value?.relocation?.withRelocation,
        };

        this.props.changeOffersFilters(filters);
        this.props.applyOffersFilters();
    };
}

export default connect(
    (state: RootState) => ({
        technologies: technologiesSelector(state),
        seniorityLevels: senioritySelector(state),
        contractTypes: contractTypesSelector(state),
        industries: industriesSelector(state),
        companyTypes: companyTypesSelector(state),
        workTypes: workTypesSelector(state),
        employmentTypes: employmentTypesSelector(state),
        candidateAccount: candidateFullInfoSelector(state),
    }),
    {
        changeOffersFilters,
        applyOffersFilters,
    }
)(withTranslation()(OfferFilters));
