import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    RestQueryParams,
} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {
    changeIsPurchasedServicesPageLoading,
    fetchPurchasedServices,
    setPurchasedServicesData,
    changePurchasedServicesPagination,
    changePurchasedServicesPageError,
    setPurchasedServicesMetadata,
} from '../reducers/purchasedServicesPageSlice';
import {getPurchasedServicesAPI} from '../../api/getPurchasedServices';
import {purchasedServicesPaginationSelector} from '../selectors/purchasedServicesPageSelectors';

const fetchPurchasedServicesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPurchasedServices(action$, state$, fetchPurchasedServices);
};

const changePurchasedServicesPaginationEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return getPurchasedServices(action$, state$, changePurchasedServicesPagination);
};

const getPurchasedServices = (action$: Observable<any>, state$: StateObservable<any>, actionType: any) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap(() => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = purchasedServicesPaginationSelector(state$.value),
                flattened = flattenObj(paginationParams),
                params = new RestQueryParams(flattened);

            return getPurchasedServicesAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const metadata = getMetadataDetails(resp['hydra:view']),
                        actions = [
                            changeIsPurchasedServicesPageLoading(false),
                            setPurchasedServicesData(resp['hydra:member']),
                            setPurchasedServicesMetadata(metadata),
                        ];

                    return of(...actions);
                }),
                catchError((error) => of(...purchasedServicesErrorActions(error)))
            );
        }),
        catchError((error) => of(...purchasedServicesErrorActions(error)))
    );
};

const purchasedServicesErrorActions = (error: any): any[] => {
    return [
        changeIsPurchasedServicesPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changePurchasedServicesPageError(getErrorMessage(error)),
    ];
};

const purchasedServicesPageEpic = combineEpics(fetchPurchasedServicesEpic, changePurchasedServicesPaginationEpic);

export default purchasedServicesPageEpic;
