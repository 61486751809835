import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    addAlert,
    addRelocationEventAPI,
    AlertType,
    authTokenSelector,
    deleteRelocationFileAPI,
    downloadRelocationFileAPI,
    getErrorMessage,
    getRelocationFilesAPI,
} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    changeIsRelocationDetailsLoading,
    changeIsRelocationFileRemoved,
    changeIsRelocationFilesListLoading,
    changeRelocationDetailsError,
    downloadRelocationFile,
    fetchCalendarDetails,
    fetchRelocationDetails,
    fetchRelocationFiles,
    IFetchRelocationDetails,
    IRelocationFileAction,
    removeRelocationFile,
    setCalendarDetails,
    setRelocationDetails,
    setRelocationFiles,
    addRelocationEvent,
    IAddRelocationEvent,
    changeIsRelocationEventAdded,
} from '../reducers/relocationDetailsPageSlice';
import {getRelocationsAPI} from '../../api/getRelocationsAPI';
import {IOfferApplication} from '../reducers/dashboardPageSlice';
import {getRelocationEventsAPI} from 'jobhunter-common-web';

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCalendarDetails.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getRelocationEventsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCalendarDetails(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchRelocationDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchRelocationDetails.type),
        switchMap((action: PayloadAction<IFetchRelocationDetails>): any => {
            const authToken = authTokenSelector(state$.value);
            return getRelocationsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const details = resp['hydra:member'].filter(
                            (relocation: IOfferApplication) => relocation.id === action.payload.relocationId
                        ),
                        actions = successActions([setRelocationDetails(details[0])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchRelocationFilesEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchRelocationFiles.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getRelocationFilesAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setRelocationFiles(resp['hydra:member']), changeIsRelocationFilesListLoading(false)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const removeRelocationFileEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(removeRelocationFile.type),
        switchMap((action: PayloadAction<IRelocationFileAction>): any => {
            const authToken = authTokenSelector(state$.value);
            return deleteRelocationFileAPI(authToken, action.payload.fileId).pipe(
                switchMap(() => {
                    const message = 'relocationView.files.removeFile.fileRemoved',
                        actions = successActions([
                            changeIsRelocationFileRemoved(true),
                            fetchRelocationFiles(),
                            addAlert({message: message}),
                        ]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const downloadRelocationFileEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(downloadRelocationFile.type),
        switchMap((action: PayloadAction<IRelocationFileAction>): any => {
            const authToken = authTokenSelector(state$.value);
            return downloadRelocationFileAPI(authToken, action.payload.fileId).pipe(
                switchMap(() => {
                    const message = 'relocationView.files.downloadFile.fileDownloaded',
                        actions = successActions([addAlert({message: message})]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const addRelocationEventEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(addRelocationEvent.type),
        switchMap((action: PayloadAction<IAddRelocationEvent>): any => {
            const authToken = authTokenSelector(state$.value);
            return addRelocationEventAPI(authToken, action.payload.offerApplicationId, action.payload.payload).pipe(
                switchMap(() => {
                    const message = 'relocationView.calendar.addEvent.eventAdded',
                        actions = successActions([
                            changeIsRelocationEventAdded(true),
                            fetchCalendarDetails(),
                            addAlert({message: message}),
                        ]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsRelocationDetailsLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsRelocationDetailsLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeRelocationDetailsError(getErrorMessage(error)),
    ];
};

const relocationDetailsPageEpic = combineEpics(
    removeRelocationFileEpic,
    downloadRelocationFileEpic,
    addRelocationEventEpic,
    fetchCalendarDetailsEpic,
    fetchRelocationDetailsEpic,
    fetchRelocationFilesEpic
);

export default relocationDetailsPageEpic;
