import {isNotNullOrUndefined, Loader, LoaderType, WithLocation, withLocation, WizardComponent} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {ChevronRight, CreditCard, Home, Terminal, User} from 'react-feather';
import PersonalInformation from './PersonalInformation';
import LayoutWrapper from '../LayoutWrapper';
import Career from './Career';
import SkillTests from './SkillTests';
import Preferences from './Preferences';
import {connect} from 'react-redux';
import {fetchProfileAccountData, resetToInitialProfilePageState} from '../../store/reducers/profilePageSlice';
import {RootState} from '../../store/reducers';
import {isProfileLoadingSelector} from '../../store/selectors/profilePageSelectors';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedProfileProps {
    readonly isLoading: boolean;
    readonly fetchProfileAccountData: typeof fetchProfileAccountData;
    readonly resetToInitialProfilePageState: typeof resetToInitialProfilePageState;
}

interface IExternalProfileProps {}

interface IProfileProps extends IConnectedProfileProps, IExternalProfileProps, WithLocationProps {}

interface IProfileState {
    activeTab: number;
    stepper: any;
}

class Profile extends Component<IProfileProps, IProfileState> {
    private readonly elementRef: any;

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            activeTab: 0,
            stepper: null,
        };
        this.elementRef = React.createRef();
    }

    componentDidMount(): void {
        if (isNotNullOrUndefined(this.props.location) && isNotNullOrUndefined(this.props.location.state)) {
            this.setState({activeTab: this.props.location.state?.activeTab});
        }

        this.props.fetchProfileAccountData();
    }

    componentWillUnmount() {
        this.props.resetToInitialProfilePageState();
    }

    render() {
        const steps = [
            {
                id: 'personal',
                title: 'dashboard.profile.personalInformation.title',
                className: 'personal-step-button',
                // subtitle: 'Lorem ipsum sit dolor',
                icon: <User size={18} />,
                content: <PersonalInformation stepper={this.state.stepper} />,
            },
            {
                id: 'career',
                title: 'dashboard.profile.career.title',
                className: 'career-step-button',
                // subtitle: 'Lorem ipsum sit dolor',
                icon: <Home size={18} />,
                content: <Career stepper={this.state.stepper} />,
            },
            {
                id: 'skill-tests',
                title: 'dashboard.profile.skills.title',
                className: 'skills-step-button',
                // subtitle: 'Lorem ipsum sit dolor',
                icon: <Terminal size={18} />,
                content: <SkillTests stepper={this.state.stepper} />,
            },
            {
                id: 'preferences',
                title: 'dashboard.profile.preferences.title',
                className: 'preferences-step-button',
                // subtitle: 'Lorem ipsum sit dolor',
                icon: <CreditCard size={18} />,
                content: <Preferences stepper={this.state.stepper} />,
            },
        ];

        return (
            <LayoutWrapper>
                <div className="modern-vertical-wizard">
                    <WizardComponent
                        type="modern-vertical"
                        ref={this.elementRef}
                        steps={steps}
                        options={{linear: false}}
                        separator={<ChevronRight size={17} />}
                        instance={(el: any) => this.setState({stepper: el})}
                        activeTab={this.state.activeTab}
                    />
                </div>
                <Loader showLoader={this.props.isLoading} type={LoaderType.LOCAL} />
            </LayoutWrapper>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: isProfileLoadingSelector(state),
    }),
    {
        fetchProfileAccountData,
        resetToInitialProfilePageState,
    }
)(withLocation(Profile));
