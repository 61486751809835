import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IProfileState} from '../reducers/profilePageSlice';

export const selectProfilePage = (state: RootState): IProfileState => {
    return state.profilePage;
};

export const profileCandidateAccountSelector = createSelector([selectProfilePage], (state: IProfileState) => state.candidateAccount);

export const profileCareerSelector = createSelector([selectProfilePage], (state: IProfileState) => state.career);

export const profileCvSelector = createSelector([selectProfilePage], (state: IProfileState) => state.cv);

export const profileSkillsSelector = createSelector([selectProfilePage], (state: IProfileState) => state.skills);

export const profileExternalLinksSelector = createSelector([selectProfilePage], (state: IProfileState) => state.externalLinks);

export const profilePreferencesSelector = createSelector([selectProfilePage], (state: IProfileState) => state.preferences);

export const profileTechnologyTestResultsSelector = createSelector(
    [selectProfilePage],
    (state: IProfileState) => state.technologyTestResults
);

export const profileLanguageTestResultsSelector = createSelector([selectProfilePage], (state: IProfileState) => state.languageTestResults);

export const isProfileLoadingSelector = createSelector([selectProfilePage], (state: IProfileState) => state.isProfileLoading);

export const isProfileInitializedSelector = createSelector([selectProfilePage], (state: IProfileState) => state.isProfileInitialized);

export const profileErrorSelector = createSelector([selectProfilePage], (state: IProfileState) => state.profileError);
