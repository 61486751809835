import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getCalendarEventsAPI(authToken: string | null, params?: typeof RestQueryParams): Observable<any> {
    let headers = undefined,
        queryParams = params ? params : new RestQueryParams();

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.get(`api/calendar_events/fetch_own`, queryParams, headers);
}
