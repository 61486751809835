import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
} from 'jobhunter-common-web';

export const addLanguageFormConfig: typeof IFormConfig = (
    languageList: typeof IMultiselectOption[] | [],
    languageLevels: typeof IMultiselectOption[] | [],
    isEditModal?: boolean
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'language',
            controlType: 'group',
            class: 'row',
            controls: {
                language: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: languageList ? languageList : [],
                    validationRules: [],
                    placeholder: 'profile.career.addLanguage.form.labels.language',
                    label: 'profile.career.addLanguage.form.labels.language',
                    hostClass: 'col-xl-6 form-control',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                    disabled: isEditModal,
                },
                languageLevel: {
                    hostClass: 'col-xl-6 form-control',
                    controlType: 'control',
                    defaultValue: null,
                    formControlType: FormControlType.RADIO,
                    options: mapToRadioOptions(languageLevels),
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.career.addLanguage.form.labels.level',
                    type: InputType.RADIO,
                    wrapperStyles: 'inline-radio',
                    isDisplayValueTranslated: true,
                },
            },
        },
    ],
});

export const mapToRadioOptions = (languageLevels: typeof IMultiselectOption[] | []): {value: string; displayValue: string}[] => {
    const options: {value: string; displayValue: string}[] = [];
    if (languageLevels === null) {
        return options;
    }

    languageLevels.map((item: typeof IMultiselectOption) => {
        return options.push({value: item.value, displayValue: item.label});
    });

    return options;
};
