import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import OffersList, {OffersListType} from '../OffersList';

interface IApplicationsProps {}

class Applications extends Component<IApplicationsProps, {}> {
    render() {
        return (
            <LayoutWrapper wrapperClass={'app-local-content'}>
                <OffersList type={OffersListType.APPLICATIONS} />
            </LayoutWrapper>
        );
    }
}

export default Applications;
