import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {addAlert, AlertType, authTokenSelector, getErrorMessage} from 'jobhunter-common-web';
import {
    fetchOfferDetails,
    IFetchOffer,
    setOfferDetails,
    changeIsOfferDetailsLoading,
    IOfferAction,
    recommendOffer,
    changeIsOfferActionProcessing,
    changeIsOfferActionComplete,
    checkOfferRecommendationAsSeen,
} from '../reducers/offerDetailsPageSlice';
import {RootState} from '../reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import {getOfferDetailsAPI} from '../../api/getOfferDetails';
import {sendOfferRecommendationAPI} from '../../api/sendOfferRecommendationAPI';
import {checkOfferRecommendationAsSeenAPI} from '../../api/checkOfferRecommendationAsSeenAPI';

const fetchOfferDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchOfferDetails.type),
        switchMap((action: PayloadAction<IFetchOffer>): any => {
            const authToken = authTokenSelector(state$.value);
            return getOfferDetailsAPI(authToken, action.payload.offerId).pipe(
                switchMap((resp: any) => {
                    const actions = [setOfferDetails(resp), changeIsOfferDetailsLoading(false)];
                    return of(...actions);
                }),
                catchError((error) => of(...offerDetailsErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerDetailsErrorActions(error)))
    );
};

const recommendOfferEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(recommendOffer.type),
        switchMap((action: PayloadAction<IOfferAction>) => {
            const authToken = authTokenSelector(state$.value),
                offerId = action.payload.offerId,
                payload = action.payload.actionPayload;

            return sendOfferRecommendationAPI(authToken, offerId, payload).pipe(
                switchMap(() => {
                    const actions = [
                        changeIsOfferActionComplete(true),
                        changeIsOfferActionProcessing(false),
                        addAlert({message: 'offers.offerView.recommendOffer.recommendationSent'}),
                    ];
                    return of(...actions);
                }),
                catchError((error) => of(...offerErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerErrorActions(error)))
    );
};

const checkOfferRecommendationAsSeenEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(checkOfferRecommendationAsSeen.type),
        switchMap((action: PayloadAction<IFetchOffer>) => {
            const authToken = authTokenSelector(state$.value),
                offerId = action.payload.offerId;

            return checkOfferRecommendationAsSeenAPI(authToken, offerId).pipe(
                switchMap(() => of()),
                catchError((error) => of(...offerErrorActions(error)))
            );
        }),
        catchError((error) => of(...offerErrorActions(error)))
    );
};

const offerDetailsErrorActions = (error: any): any[] => {
    return [changeIsOfferDetailsLoading(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];
};

const offerErrorActions = (error: any): any[] => {
    return [changeIsOfferActionProcessing(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];
};

const offerDetailsEpic = combineEpics(fetchOfferDetailsEpic, recommendOfferEpic, checkOfferRecommendationAsSeenEpic);

export default offerDetailsEpic;
