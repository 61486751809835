import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {changeIsRelocationFileRemoved, IRelocationFile} from '../../../../../store/reducers/relocationDetailsPageSlice';

interface IRemoveRelocationFileModalProps {
    readonly isModalOpen: boolean;
    readonly toggleModal: (item: any) => void;
    readonly selectedItem: IRelocationFile | null;
    readonly error: string | null;
    readonly removeRelocationFile: (fileId: string) => void;
    readonly isRelocationFileRemoved: boolean;
    readonly changeIsRelocationFileRemoved: typeof changeIsRelocationFileRemoved;
}

interface IRemoveRelocationFileModalState {
    isLoading: boolean;
}

class RemoveRelocationFileModal extends Component<IRemoveRelocationFileModalProps, IRemoveRelocationFileModalState> {
    constructor(props: IRemoveRelocationFileModalProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IRemoveRelocationFileModalProps>) {
        if (
            (this.props.error !== prevProps.error && this.props.error) ||
            (this.props.isRelocationFileRemoved !== prevProps.isRelocationFileRemoved && this.props.isRelocationFileRemoved)
        ) {
            this.setState({isLoading: false});
        }

        if (this.props.isRelocationFileRemoved !== prevProps.isRelocationFileRemoved && this.props.isRelocationFileRemoved) {
            this.props.toggleModal(null);
        }
    }

    componentWillUnmount() {
        this.props.changeIsRelocationFileRemoved(false);
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal(null)}>
                <ModalHeader toggle={() => this.props.toggleModal(null)}>
                    <Translation text="relocationView.files.removeFile.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="text-center mb-3">
                        <Translation text="relocationView.files.removeFile.description" />
                    </p>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button outline color="secondary" className="close-modal-button me-1" onClick={() => this.props.toggleModal(null)}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.cancel" />
                            </span>
                        </Button>
                        <Button color="primary me-2" className="remove-record-button" onClick={() => this.removeRelocationFile()}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.delete" />
                            </span>
                        </Button>
                    </div>
                </ModalBody>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private removeRelocationFile = () => {
        this.setState({isLoading: true});

        if (this.props.selectedItem) {
            this.props.removeRelocationFile(this.props.selectedItem.file.id);
        }
    };
}

export default RemoveRelocationFileModal;
