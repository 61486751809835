import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export function getOffersAPI(
    authToken: string | null,
    params?: typeof RestQueryParams,
    requestHeaders?: {[key: string]: any}
): Observable<any> {
    let queryParams = params ? params : new RestQueryParams(),
        headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    if (requestHeaders) {
        headers = {...headers, ...requestHeaders};
    }

    return jobHunterAPI.get('api/offers/candidate_best_matched', queryParams, headers);
}
