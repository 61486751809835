import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IPurchasedServicesState} from '../reducers/purchasedServicesPageSlice';

export const selectPurchasedServicesPage = (state: RootState): IPurchasedServicesState => {
    return state.purchasedServices;
};

export const purchasedServicesSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.purchasedServices
);

export const purchasedServicesMetadataSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.purchasedServicesMetadata
);

export const purchasedServicesPaginationSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.pagination
);

export const isPurchasedServicesPageLoadingSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.isPurchasedServicesPageLoading
);

export const isPurchasedServicesPageInitializedSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.isPurchasedServicesPageInitialized
);

export const purchasedServicesErrorSelector = createSelector(
    [selectPurchasedServicesPage],
    (state: IPurchasedServicesState) => state.purchasedServicesPageError
);
