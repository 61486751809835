import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IChangeIsOfferActionComplete, IOfferDescription, IOfferListItem, IOfferTechnology, IOfferTechnologyTool} from './offersPageSlice';

export interface IOfferDetails extends IModelApiObject {
    offer: IOfferListItem;
    offerDescription: IOfferDescription;
    technologies: IOfferTechnology[];
    technologyTools: IOfferTechnologyTool[];
}

export interface IOfferDetailsState {
    offerDetails: IOfferDetails | null;
    isOfferDetailsLoading: boolean;
    isOfferDetailsInitialized: boolean;
    offerDetailsError: string | null;
    isOfferActionProcessing: boolean;
    isOfferActionComplete: boolean;
}

export interface ISetOfferItem {
    offer: IOfferDetails | null;
}

export interface IOfferAction {
    offerId: string;
    actionPayload: {email: string};
}

export interface IChangeIsOfferDetailsLoading {
    isOfferDetailsLoading: boolean;
}

export interface IChangeIsOfferDetailsInitialized {
    isOfferDetailsInitialized: boolean;
}

export interface IChangeOfferDetailsError {
    error: string | null;
}

export interface IFetchOffer {
    offerId: string;
}

export interface IChangeIsOfferActionProcessing {
    isOfferActionProcessing: boolean;
}

const initialState: IOfferDetailsState = {
    offerDetails: null,
    isOfferDetailsLoading: true,
    isOfferDetailsInitialized: false,
    offerDetailsError: null,
    isOfferActionProcessing: false,
    isOfferActionComplete: false,
};

const offerDetailsPageSlice = createSlice({
    name: 'offerDetails',
    initialState: initialState,
    reducers: {
        checkOfferRecommendationAsSeen: {
            reducer: (state: IOfferDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare: (offerId: string) => {
                return {
                    payload: {
                        offerId: offerId,
                    },
                };
            },
        },
        changeIsOfferDetailsLoading: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<IChangeIsOfferDetailsLoading>) => {
                return {
                    ...state,
                    isOfferDetailsLoading: action.payload.isOfferDetailsLoading,
                };
            },
            prepare(isOfferDetailsLoading: boolean) {
                return {
                    payload: {isOfferDetailsLoading: isOfferDetailsLoading},
                };
            },
        },
        changeIsOfferDetailsInitialized: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<IChangeIsOfferDetailsInitialized>) => {
                return {
                    ...state,
                    isOfferDetailsInitialized: action.payload.isOfferDetailsInitialized,
                };
            },
            prepare(isOfferDetailsInitialized: boolean) {
                return {
                    payload: {isOfferDetailsInitialized: isOfferDetailsInitialized},
                };
            },
        },
        changeOffersError: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<IChangeOfferDetailsError>) => {
                return {
                    ...state,
                    offerDetailsError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchOfferDetails: {
            reducer: (state: IOfferDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        setOfferDetails: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<ISetOfferItem>) => {
                return {
                    ...state,
                    offerDetails: action.payload.offer,
                };
            },
            prepare(offer: IOfferDetails | null) {
                return {
                    payload: {offer: offer},
                };
            },
        },
        recommendOffer: {
            reducer: (state: IOfferDetailsState) => {
                return {
                    ...state,
                    isOfferActionProcessing: true,
                };
            },
            prepare(offerId: string, actionPayload: {email: string}) {
                return {
                    payload: {
                        offerId: offerId,
                        actionPayload: actionPayload,
                    },
                };
            },
        },
        changeIsOfferActionProcessing: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<IChangeIsOfferActionProcessing>) => {
                return {
                    ...state,
                    isOfferActionProcessing: action.payload.isOfferActionProcessing,
                };
            },
            prepare(isOfferActionProcessing: boolean) {
                return {
                    payload: {
                        isOfferActionProcessing: isOfferActionProcessing,
                    },
                };
            },
        },
        changeIsOfferActionComplete: {
            reducer: (state: IOfferDetailsState, action: PayloadAction<IChangeIsOfferActionComplete>) => {
                return {
                    ...state,
                    isOfferActionComplete: action.payload.isOfferActionComplete,
                };
            },
            prepare(isOfferActionComplete: boolean) {
                return {
                    payload: {isOfferActionComplete: isOfferActionComplete},
                };
            },
        },
        resetToInitialOfferDetailsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeIsOfferDetailsLoading,
    changeIsOfferDetailsInitialized,
    changeOffersError,
    fetchOfferDetails,
    setOfferDetails,
    checkOfferRecommendationAsSeen,
    recommendOffer,
    changeIsOfferActionProcessing,
    changeIsOfferActionComplete,
    resetToInitialOfferDetailsPageState,
} = offerDetailsPageSlice.actions;

export default offerDetailsPageSlice.reducer;
