import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import RelocationStatus from './RelocationStatus';
import {usernameSelector, withRouterWrapper, serverChatContactIntoChatContactMapper, WithRouterWrapperProps} from 'jobhunter-common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import RelocationChat from './RelocationChat';
import RelocationFiles from './RelocationFiles';
import RelocationCalendar from './RelocationCalendar';
import {ICalendarEvent} from '../../../model/calendarEventModel';
import {
    fetchRelocationFiles,
    fetchCalendarDetails,
    removeRelocationFile,
    changeIsRelocationFileRemoved,
    downloadRelocationFile,
    fetchRelocationDetails,
    resetToInitialRelocationDetailsPageState,
    addRelocationEvent,
    changeIsRelocationEventAdded,
    IRelocationFile,
} from '../../../store/reducers/relocationDetailsPageSlice';
import {
    isRelocationFileRemovedSelector,
    isRelocationFilesListLoadingSelector,
    relocationDetailsErrorSelector,
    relocationFilesSelector,
    calendarDetailsSelector,
    isRelocationEventAddedSelector,
    relocationDetailsSelector,
} from '../../../store/selectors/relocationDetailsSelectors';
import {ChatContact, ChatProps} from '@solvee/reactjs-text-chat';
import {IOfferApplication} from '../../../store/reducers/dashboardPageSlice';

declare type WithRouterProps = typeof WithRouterWrapperProps;

//TODO: IApplicationOffer musi mieć typ jakiś na contact
interface IConnectedRelocationViewProps {
    readonly username: string;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly relocationDetails: IOfferApplication | null;
    readonly relocationFiles: IRelocationFile[] | null;
    readonly isRelocationFileRemoved: boolean;
    readonly isRelocationEventAdded: boolean;
    readonly error: string | null;
    readonly isRelocationFilesListLoading: boolean;
    readonly fetchCalendarDetails: typeof fetchCalendarDetails;
    readonly fetchRelocationFiles: typeof fetchRelocationFiles;
    readonly fetchRelocationDetails: typeof fetchRelocationDetails;
    readonly removeRelocationFile: typeof removeRelocationFile;
    readonly changeIsRelocationFileRemoved: typeof changeIsRelocationFileRemoved;
    readonly downloadRelocationFile: typeof downloadRelocationFile;
    readonly addRelocationEvent: typeof addRelocationEvent;
    readonly changeIsRelocationEventAdded: typeof changeIsRelocationEventAdded;
    readonly resetToInitialRelocationDetailsPageState: typeof resetToInitialRelocationDetailsPageState;
}

interface IRelocationViewProps extends IConnectedRelocationViewProps, ChatProps, WithRouterProps {}

interface IRelocationViewState {
    relocationStatus: string | null;
    candidateId: string | null;
    applicationId: string | null;
}

class RelocationView extends Component<IRelocationViewProps, IRelocationViewState> {
    constructor(props: IRelocationViewProps) {
        super(props);

        this.state = {
            relocationStatus: null,
            candidateId: null,
            applicationId: null,
        };
    }

    componentDidMount() {
        this.props.fetchCalendarDetails();
        this.props.fetchRelocationFiles();

        if (this.props.router && this.props.router.location && this.props.router.location.state) {
            this.setState({
                relocationStatus: this.props.router.location.state?.relocationStatus,
                candidateId: this.props.router.location.state.candidateId,
            });
        }

        if (this.props.router && this.props.router.params) {
            const applicationId = this.props.router.params.id;
            this.setState({applicationId: applicationId});
            this.props.fetchRelocationDetails(applicationId);
        }
    }

    componentDidUpdate(prevProps: IRelocationViewProps, prevState: IRelocationViewState) {
        if (
            this.props.chatContactsList &&
            (this.props.relocationDetails !== prevProps.relocationDetails ||
                prevProps.chatContactsList?.length !== this.props.chatContactsList?.length)
        ) {
            const contact = this.props.chatContactsList
                    ? this.props.chatContactsList.find((contact: any) => contact.accountId === this.state.candidateId)
                    : null,
                relocation = this.props.relocationDetails;
            if (!contact && relocation) {
                if (relocation && this.props.joinChannels) {
                    const newContact: ChatContact[] = serverChatContactIntoChatContactMapper(
                        [{contact: relocation.chatContact, unread: 0}],
                        this.props.accountId
                    );

                    const channels = this.props.chatContactsList ? [...this.props.chatContactsList, ...newContact] : newContact;
                    this.props.joinChannels(channels);
                }
            }
            if (contact) {
                this.props.setSelectedChatRoomId(contact.roomId);
            }
        }
    }

    componentWillUnmount() {
        this.props.setSelectedChatRoomId('');
        this.props.resetToInitialRelocationDetailsPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <RelocationChat {...this.props} />
                        <RelocationFiles
                            files={this.props.relocationFiles}
                            removeRelocationFile={this.removeRelocationFile}
                            error={this.props.error}
                            isLoading={this.props.isRelocationFilesListLoading}
                            isRelocationFileRemoved={this.props.isRelocationFileRemoved}
                            changeIsRelocationFileRemoved={this.props.changeIsRelocationFileRemoved}
                            downloadRelocationFile={this.props.downloadRelocationFile}
                            applicationId={this.state.applicationId}
                        />
                    </div>
                    <div className="col-lg-4 col-12">
                        <RelocationStatus relocationStatus={this.state.relocationStatus} />
                        <RelocationCalendar
                            username={this.props.username}
                            applicationId={this.state.applicationId}
                            addRelocationEvent={this.props.addRelocationEvent}
                            calendarEvents={this.props.calendarEvents}
                            isRelocationEventAdded={this.props.isRelocationEventAdded}
                            error={this.props.error}
                            changeIsRelocationEventAdded={this.props.changeIsRelocationEventAdded}
                        />
                    </div>
                </div>
            </LayoutWrapper>
        );
    }

    private removeRelocationFile = (fileId: string) => {
        this.props.removeRelocationFile(fileId);
    };
}

export default connect(
    (state: RootState) => ({
        username: usernameSelector(state),
        calendarEvents: calendarDetailsSelector(state),
        relocationFiles: relocationFilesSelector(state),
        relocationDetails: relocationDetailsSelector(state),
        isRelocationFileRemoved: isRelocationFileRemovedSelector(state),
        isRelocationFilesListLoading: isRelocationFilesListLoadingSelector(state),
        isRelocationEventAdded: isRelocationEventAddedSelector(state),
        error: relocationDetailsErrorSelector(state),
    }),
    {
        fetchCalendarDetails,
        fetchRelocationFiles,
        fetchRelocationDetails,
        removeRelocationFile,
        changeIsRelocationFileRemoved,
        downloadRelocationFile,
        addRelocationEvent,
        changeIsRelocationEventAdded,
        resetToInitialRelocationDetailsPageState,
    }
)(withRouterWrapper(RelocationView));
