import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {IOfferApplication} from './dashboardPageSlice';
import {IFileOutput, IAddRelocationEventPayload} from 'jobhunter-common-web';

export interface IRelocationFileOfferApplication extends IModelApiObject {
    createdAt: string;
    id: string;
}

export interface IRelocationFile extends IModelApiObject {
    readonly file: typeof IFileOutput;
    readonly fileName: string;
    readonly offerApplication: IRelocationFileOfferApplication;
}

export interface IRelocationDetailsState {
    relocationDetails: IOfferApplication | null;
    relocationFiles: IRelocationFile[] | null;
    calendarDetails: any | null;
    isRelocationDetailsLoading: boolean;
    isRelocationDetailsInitialized: boolean;
    isRelocationFileRemoved: boolean;
    isRelocationEventAdded: boolean;
    isRelocationFilesListLoading: boolean;
    relocationDetailsError: string | null;
}

export interface IFetchRelocationDetails {
    relocationId: string;
}

export interface ISetRelocationDetails {
    relocationDetails: IOfferApplication | null;
}

export interface ISetCalendarDetails {
    calendarDetails: any | null;
}

export interface ISetRelocationFiles {
    relocationFiles: IRelocationFile[] | null;
}

export interface IRelocationFileAction {
    fileId: string;
}

export interface IAddRelocationEvent {
    offerApplicationId: string;
    payload: typeof IAddRelocationEventPayload;
}

export interface IChangeIsRelocationDetailsLoading {
    isRelocationDetailsLoading: boolean;
}

export interface IChangeIsRelocationDetailsInitialized {
    isRelocationDetailsInitialized: boolean;
}

export interface IChangeRelocationDetailsError {
    error: string | null;
}

export interface IChangeIsActionUpdated {
    isActionUpdated: boolean;
}

const initialState: IRelocationDetailsState = {
    relocationDetails: null,
    relocationFiles: null,
    calendarDetails: null,
    isRelocationDetailsLoading: true,
    isRelocationDetailsInitialized: false,
    isRelocationFileRemoved: false,
    isRelocationEventAdded: false,
    isRelocationFilesListLoading: false,
    relocationDetailsError: null,
};

const relocationDetailsPageSlice = createSlice({
    name: 'relocationDetails',
    initialState: initialState,
    reducers: {
        fetchRelocationDetails: {
            reducer: (state: IRelocationDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare(relocationId: string) {
                return {
                    payload: {relocationId: relocationId},
                };
            },
        },
        fetchRelocationFiles: (state: IRelocationDetailsState) => {
            return {
                ...state,
                isRelocationFilesListLoading: true,
            };
        },
        fetchCalendarDetails: (state: IRelocationDetailsState) => {
            return {
                ...state,
            };
        },
        setRelocationDetails: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<ISetRelocationDetails>) => {
                return {
                    ...state,
                    relocationDetails: action.payload.relocationDetails,
                };
            },
            prepare(relocationDetails: IOfferApplication | null) {
                return {
                    payload: {relocationDetails: relocationDetails},
                };
            },
        },
        setRelocationFiles: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<ISetRelocationFiles>) => {
                return {
                    ...state,
                    relocationFiles: action.payload.relocationFiles,
                };
            },
            prepare(relocationFiles: IRelocationFile[] | null) {
                return {
                    payload: {relocationFiles: relocationFiles},
                };
            },
        },
        setCalendarDetails: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<ISetCalendarDetails>) => {
                return {
                    ...state,
                    calendarDetails: action.payload.calendarDetails,
                };
            },
            prepare(calendarDetails: any | null) {
                return {
                    payload: {calendarDetails: calendarDetails},
                };
            },
        },
        changeIsRelocationFilesListLoading: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeIsRelocationDetailsLoading>) => {
                return {
                    ...state,
                    isRelocationFilesListLoading: action.payload.isRelocationDetailsLoading,
                };
            },
            prepare(isRelocationDetailsLoading: boolean) {
                return {
                    payload: {isRelocationDetailsLoading: isRelocationDetailsLoading},
                };
            },
        },
        removeRelocationFile: {
            reducer: (state: IRelocationDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare(fileId: string) {
                return {
                    payload: {fileId: fileId},
                };
            },
        },
        downloadRelocationFile: {
            reducer: (state: IRelocationDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare(fileId: string) {
                return {
                    payload: {fileId: fileId},
                };
            },
        },
        changeIsRelocationDetailsLoading: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeIsRelocationDetailsLoading>) => {
                return {
                    ...state,
                    isRelocationDetailsLoading: action.payload.isRelocationDetailsLoading,
                };
            },
            prepare(isRelocationDetailsLoading: boolean) {
                return {
                    payload: {isRelocationDetailsLoading: isRelocationDetailsLoading},
                };
            },
        },
        addRelocationEvent: {
            reducer: (state: IRelocationDetailsState) => {
                return {
                    ...state,
                };
            },
            prepare(offerApplicationId: string, payload: typeof IAddRelocationEventPayload) {
                return {
                    payload: {offerApplicationId: offerApplicationId, payload: payload},
                };
            },
        },
        changeIsRelocationDetailsInitialized: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeIsRelocationDetailsInitialized>) => {
                return {
                    ...state,
                    isRelocationDetailsInitialized: action.payload.isRelocationDetailsInitialized,
                };
            },
            prepare(isRelocationDetailsInitialized: boolean) {
                return {
                    payload: {isRelocationDetailsInitialized: isRelocationDetailsInitialized},
                };
            },
        },
        changeRelocationDetailsError: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeRelocationDetailsError>) => {
                return {
                    ...state,
                    relocationDetailsError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        changeIsRelocationFileRemoved: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeIsActionUpdated>) => {
                return {
                    ...state,
                    isRelocationFileRemoved: action.payload.isActionUpdated,
                };
            },
            prepare(isActionUpdated: boolean) {
                return {
                    payload: {isActionUpdated: isActionUpdated},
                };
            },
        },
        changeIsRelocationEventAdded: {
            reducer: (state: IRelocationDetailsState, action: PayloadAction<IChangeIsActionUpdated>) => {
                return {
                    ...state,
                    isRelocationEventAdded: action.payload.isActionUpdated,
                };
            },
            prepare(isActionUpdated: boolean) {
                return {
                    payload: {isActionUpdated: isActionUpdated},
                };
            },
        },
        resetToInitialRelocationDetailsPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    changeIsRelocationDetailsLoading,
    changeIsRelocationDetailsInitialized,
    changeRelocationDetailsError,
    fetchRelocationDetails,
    setRelocationDetails,
    fetchCalendarDetails,
    fetchRelocationFiles,
    setCalendarDetails,
    setRelocationFiles,
    removeRelocationFile,
    addRelocationEvent,
    downloadRelocationFile,
    changeIsRelocationFileRemoved,
    changeIsRelocationFilesListLoading,
    changeIsRelocationEventAdded,
    resetToInitialRelocationDetailsPageState,
} = relocationDetailsPageSlice.actions;

export default relocationDetailsPageSlice.reducer;
