import React from 'react';
import App from '../App';
import {Route} from 'react-router';
import PanelHost from '../components/PanelHost';
import {NotFound, AuthPanel, UserRole, PrivateRoute} from 'jobhunter-common-web';
import {loginFormConfig} from './authFormConfig/loginFormConfig';
import {newPasswordFormConfig} from './authFormConfig/newPasswordFormConfig';
import {registrationFormConfig} from './authFormConfig/registrationFormConfig';
import {resetPasswordFormConfig} from './authFormConfig/resetPasswordFormConfig';

const routes = [
    <Route path="/" element={<App />} key="home" />,
    <Route
        path="/panel/*"
        key="panel"
        element={
            <PrivateRoute userRole={UserRole.CANDIDATE}>
                <PanelHost />
            </PrivateRoute>
        }
    />,
    <Route
        path="/auth/*"
        element={
            <AuthPanel
                formConfig={{
                    login: loginFormConfig,
                    resetPassword: resetPasswordFormConfig,
                    registration: registrationFormConfig,
                    newPassword: newPasswordFormConfig,
                }}
                envData={process.env}
                userRole={UserRole.CANDIDATE}
            />
        }
        key="auth"
    />,
    <Route path="*" key="not-found" element={<NotFound />} />,
];
export default routes;
