import {FileUpload, Form, FormControlChangeType, IFileOutput, IFormConfig, Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {ArrowLeft, ArrowRight, GitHub, Linkedin, Paperclip} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Button} from 'reactstrap';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {socialLinksFormConfig} from './socialLinksFormConfig';
import classnames from 'classnames';
import styles from './styles.module.scss';
import {
    changeCvData,
    changeExternalLinksData,
    ExternalLinkType,
    ICandidateSkills,
    ICareerEntry,
    IExternalLink,
    removeCareerEntryData,
    removeLanguageSkillData,
    removeTechnologySkillData,
    updateProfilePage,
} from '../../../store/reducers/profilePageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    profileCareerSelector,
    profileCvSelector,
    profileExternalLinksSelector,
    profileSkillsSelector,
} from '../../../store/selectors/profilePageSelectors';
import CareerEntries from './CareerEntries';
import TechnologySkills from './TechnologySkills';
import LanguageSkills from './LanguageSkills';

interface IConnectedCareerProps {
    readonly career: ICareerEntry[] | null;
    readonly skills: ICandidateSkills | null;
    readonly cv: typeof IFileOutput | null;
    readonly externalLinks: IExternalLink[] | null;
    readonly updateProfilePage: typeof updateProfilePage;
    readonly removeCareerEntryData: typeof removeCareerEntryData;
    readonly removeLanguageSkillData: typeof removeLanguageSkillData;
    readonly removeTechnologySkillData: typeof removeTechnologySkillData;
    readonly changeCvData: typeof changeCvData;
    readonly changeExternalLinksData: typeof changeExternalLinksData;
}

interface IExternalCareerProps {
    stepper: any;
}
interface ICareerProps extends IConnectedCareerProps, IExternalCareerProps, WithTranslation {}

interface ICareerState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isFormValid: boolean;
    isLoading: boolean;
}

class Career extends Component<ICareerProps, ICareerState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: ICareerProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: socialLinksFormConfig(
                null,
                () => {
                    return <GitHub />;
                },
                () => {
                    return <Linkedin />;
                },
                () => {
                    return <Paperclip />;
                }
            ),
            isFormValid: true,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setExternalLinks();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap(() => this.setState({isLoading: true})),
                    debounceTime(500),
                    tap((data: any) => {
                        if (this.state.isFormValid) {
                            this.changeSocialLinks(data.value);
                        }
                    })
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<ICareerProps>) {
        if (this.props.externalLinks !== prevProps.externalLinks && this.props.externalLinks) {
            this.setExternalLinks();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const technologySkills = this.props.skills?.technologySkills,
            languageSkills = this.props.skills?.languageSkills;

        return (
            <>
                <div className="wizard-content">
                    <CareerEntries career={this.props.career} removeCareerEntryData={this.props.removeCareerEntryData} />

                    <TechnologySkills
                        technologySkills={technologySkills}
                        removeTechnologySkillData={this.props.removeTechnologySkillData}
                    />

                    <LanguageSkills languageSkills={languageSkills} removeLanguageSkillData={this.props.removeLanguageSkillData} />

                    <div className="career-section">
                        <div className="row">
                            <div className="col-xl-6 file-upload-container">
                                <div className="file-upload-details">
                                    <FileUpload
                                        label={'buttons.upload'}
                                        acceptedFileExtension=".doc, .docx, .pdf"
                                        onFileUpload={this.onFileUploadSuccess}
                                        defaultFileValue={this.props.cv}
                                    />
                                </div>
                            </div>

                            <div className={classnames('col-xl-6', styles.socialLinksForm)}>
                                {this.state.formConfig && (
                                    <Form
                                        config={this.state.formConfig}
                                        onValueStateChange={this.onValueStateChange}
                                        onValidationStateChange={this.formValidityChange}
                                        value={this.state.value}
                                        controlName={'socialLinksForm'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="wizard-actions">
                        <Button color="primary" className="btn-prev career-prev-button" onClick={() => this.props.stepper.previous()}>
                            <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.previous" />
                            </span>
                        </Button>
                        <Button
                            color="primary"
                            className="btn-next career-next-button"
                            onClick={() => {
                                // this.changeCareerData();
                                this.props.stepper.next();
                            }}>
                            {/*<Save size={14} className="align-middle ms-sm-25 me-25" />*/}
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.next" />
                            </span>
                            <ArrowRight size={14} className="align-middle ms-sm-25 ms-0" />
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private formValidityChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private changeSocialLinks = (data: {[key: string]: any}) => {
        this.setState({value: data, isLoading: false}, () => {
            const externalLinks = this.mapFormValuesToExternalLinks();

            this.props.changeExternalLinksData(externalLinks);
        });
    };

    // private changeCareerData = () => {
    //     const externalLinks = this.mapFormValuesToExternalLinks().map((linkItem: IExternalLink) => {
    //         const {link, ...rest} = linkItem;
    //
    //         return {...rest, url: link};
    //     });
    //
    //     const payload: IProfileUpdatePayload = {
    //         careerEntries: this.props.career,
    //         technologySkillEntries: this.props.skills?.technologySkills,
    //         languageEntries: this.props.skills?.languageSkills,
    //         externalLinks: externalLinks,
    //         cvId: this.props.cv?.id,
    //     };
    //     this.props.updateProfilePage(payload);
    // };

    private mapFormValuesToExternalLinks = (): IExternalLink[] => {
        const formData = this.state.value,
            externalLinks: IExternalLink[] = [];
        if (null === formData) {
            return externalLinks;
        }

        Object.keys(formData).forEach((key: string) => {
            if (formData[key]) {
                const linkType: ExternalLinkType =
                    key === 'github'
                        ? ExternalLinkType.GITHUB
                        : key === 'linkedin'
                        ? ExternalLinkType.LINKEDIN
                        : ExternalLinkType.PORTFOLIO;

                externalLinks.push({
                    link: formData[key],
                    type: linkType,
                });
            }
        });

        return externalLinks;
    };

    private setExternalLinks = () => {
        const value = {
            github: this.props.externalLinks?.filter((link: {[key: string]: any}) => link.type === ExternalLinkType.GITHUB)[0]?.link,
            linkedin: this.props.externalLinks?.filter((link: {[key: string]: any}) => link.type === ExternalLinkType.LINKEDIN)[0]?.link,
            portfolio: this.props.externalLinks?.filter((link: {[key: string]: any}) => link.type === ExternalLinkType.PORTFOLIO)[0]?.link,
        };

        this.setState({value});
    };

    private onFileUploadSuccess = (file: any) => {
        this.props.changeCvData(file);
    };
}

export default connect(
    (state: RootState) => ({
        career: profileCareerSelector(state),
        skills: profileSkillsSelector(state),
        cv: profileCvSelector(state),
        externalLinks: profileExternalLinksSelector(state),
    }),
    {
        updateProfilePage,
        removeCareerEntryData,
        removeLanguageSkillData,
        removeTechnologySkillData,
        changeCvData,
        changeExternalLinksData,
    }
)(withTranslation()(Career));
