import {
    InputBasic,
    InputType,
    isSameValue,
    IModelApiResponseViewObject,
    Loader,
    LoaderType,
    Pagination,
    Translation,
} from 'jobhunter-common-web';
import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import styles from './styles.module.scss';
import {NavLink, Route, Routes} from 'react-router-dom';
import {withTranslation, WithTranslation} from 'react-i18next';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import Applications from './Applications';
import Recruitment from './Recruitment';
import ObservedOffers from './ObservedOffers';
import {CheckSquare, Eye, Mail, MessageSquare} from 'react-feather';
import Offers from './Offers';
import {connect} from 'react-redux';
import {
    applyOffersFilters,
    changeOffersFilters,
    changeOffersPagination,
    IOfferFilters,
    OffersListType,
    resetToInitialOfferPageState,
} from '../../store/reducers/offersPageSlice';
import {RootState} from '../../store/reducers';
import {
    isOffersLoadingSelector,
    offerFiltersSelector,
    offersListTypeSelector,
    offersMetadataSelector,
} from '../../store/selectors/offersPageSelectors';

interface IConnectedOffersHostProps {
    readonly isOfferListLoading: boolean;
    readonly offerFilters: IOfferFilters | null;
    readonly offersListType: OffersListType;
    readonly offersMetadata: typeof IModelApiResponseViewObject | null;
    readonly resetToInitialOfferPageState: typeof resetToInitialOfferPageState;
    readonly changeOffersFilters: typeof changeOffersFilters;
    readonly applyOffersFilters: typeof applyOffersFilters;
    readonly changeOffersPagination: typeof changeOffersPagination;
}

interface IExternalOffersHostProps {}

interface IOffersHostProps extends IConnectedOffersHostProps, IExternalOffersHostProps, WithTranslation {}

interface IOffersHostState {
    searchValue: string;
}

class OffersHost extends Component<IOffersHostProps, IOffersHostState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOffersHostProps) {
        super(props);

        this.state = {
            searchValue: '',
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data),
                    // debounceTime(100),
                    tap((data: any) => this.onFormValueChange(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IOffersHostProps>) {
        if (!isSameValue(this.props.offersListType, prevProps.offersListType)) {
            this.setState({searchValue: ''});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.resetToInitialOfferPageState();
    }

    render() {
        const {t} = this.props;
        return (
            <LayoutWrapper>
                <div className={styles.offersHost}>
                    <div className={styles.offersHeader}>
                        <p className={styles.title}>
                            <Translation text="offers.title" />
                        </p>
                        <p className={styles.description}>
                            <Translation text="offers.description" />
                        </p>

                        <div className="form-control input-search offers-form-control">
                            <InputBasic
                                type={InputType.TEXT}
                                placeholder={t('offers.form.placeholders.search')}
                                inputStyles="offer-name-input"
                                value={this.state.searchValue}
                                name="searchInput"
                                handleChange={(e: any) => this.onValueStateChange(e)}
                                autoComplete="off"
                            />
                        </div>

                        <div className={styles.tabs}>
                            <NavLink
                                end
                                to={'/panel/offers'}
                                className={(props) => `${styles.tabsButton} ${props.isActive ? styles.active : ''} offers-tab-link`}>
                                <Mail />
                                <p className={styles.buttonText}>
                                    <Translation text="offers.tabs.offers" />
                                </p>
                            </NavLink>

                            <NavLink
                                to={'/panel/offers/applications'}
                                className={(props) => `${styles.tabsButton} ${props.isActive ? styles.active : ''} applications-tab-link`}>
                                <MessageSquare />
                                <p className={styles.buttonText}>
                                    <Translation text="offers.tabs.myApplications" />
                                </p>
                            </NavLink>

                            <NavLink
                                to={'/panel/offers/recruitment'}
                                className={(props) => `${styles.tabsButton} ${props.isActive ? styles.active : ''} recruitment-tab-link`}>
                                <CheckSquare />
                                <p className={styles.buttonText}>
                                    <Translation text="offers.tabs.myRecruitment" />
                                </p>
                            </NavLink>

                            <NavLink
                                to={'/panel/offers/observed'}
                                className={(props) => `${styles.tabsButton} ${props.isActive ? styles.active : ''} observed-tab-link`}>
                                <Eye />
                                <p className={styles.buttonText}>
                                    <Translation text="offers.tabs.observed" />
                                </p>
                            </NavLink>
                        </div>
                    </div>

                    <div>
                        <Routes>
                            <Route path="/" element={<Offers />} key="offers" />
                            <Route path="applications" element={<Applications />} key="applications" />
                            <Route path="recruitment" element={<Recruitment />} key="recruitment" />
                            <Route path="observed" element={<ObservedOffers />} key="observed" />
                        </Routes>

                        <Pagination
                            listMetadata={this.props.offersMetadata}
                            changePage={this.props.changeOffersPagination}
                            itemsPerPage={10}
                        />
                    </div>
                </div>
                <Loader showLoader={this.props.isOfferListLoading} type={LoaderType.Local} />
            </LayoutWrapper>
        );
    }

    private onValueStateChange = (value: any) => {
        this.onValueStateChange$.next({value: value.target.value});
    };

    private onFormValueChange = (value: any) => {
        this.setState({
            searchValue: value,
        });

        const filters: IOfferFilters =
            this.props.offerFilters !== null && this.props.offersListType === OffersListType.OFFERS
                ? {...this.props.offerFilters}
                : {
                      offerTechnologies: null,
                      seniority: null,
                      contractTypes: null,
                      industries: null,
                      companyTypes: null,
                      workTypes: null,
                      employmentTypes: null,
                      minimumSalary: null,
                      relocation: false,
                      best_match: false,
                  };

        if (this.props.offersListType === OffersListType.OFFERS || this.props.offersListType === OffersListType.OBSERVED) {
            filters.name = value;
        }

        if (this.props.offersListType === OffersListType.APPLICATIONS || this.props.offersListType === OffersListType.RECRUITMENT) {
            filters.offer = {name: value};
            delete filters.name;
        }

        this.props.changeOffersFilters(filters);
        this.props.applyOffersFilters();
    };
}

export default connect(
    (state: RootState) => ({
        offerFilters: offerFiltersSelector(state),
        offersListType: offersListTypeSelector(state),
        isOfferListLoading: isOffersLoadingSelector(state),
        offersMetadata: offersMetadataSelector(state),
    }),
    {
        changeOffersFilters,
        applyOffersFilters,
        resetToInitialOfferPageState,
        changeOffersPagination,
    }
)(withTranslation()(OffersHost));
