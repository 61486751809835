import React from 'react';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {Shield} from 'react-feather';
import {Translation} from 'jobhunter-common-web';

interface IRelocationStatusProps {
    relocationStatus: string | null;
}

const RelocationStatus = (props: IRelocationStatusProps) => {
    return (
        <Card className="relocation-status-card">
            <CardTitle>
                <div className="card-header">
                    <div className="relocation-status-header">
                        <Shield size={20} />
                        <Translation text="relocationView.status.title" />
                    </div>
                </div>
            </CardTitle>
            <CardBody>
                <p className="relocation-status">{props.relocationStatus ? props.relocationStatus : '---'}</p>
            </CardBody>
        </Card>
    );
};

export default RelocationStatus;
