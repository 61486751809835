import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'jobhunter-common-web';

export const preferencesFormConfig: typeof IFormConfig = (
    technologies: typeof IMultiselectOption[] | [],
    cities: typeof IMultiselectOption[] | [],
    companyTypes: typeof IMultiselectOption[] | [],
    industries: typeof IMultiselectOption[] | [],
    contractTypes: typeof IMultiselectOption[] | [],
    workTypes: typeof IMultiselectOption[] | [],
    employmentTypes: typeof IMultiselectOption[] | [],
    preferenceTags?: typeof IMultiselectOption[] | []
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'technology',
            controlType: 'group',
            class: 'row',
            controls: {
                technology: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: technologies,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.technology',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'location',
            controlType: 'group',
            class: 'row',
            controls: {
                location: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: cities,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.location',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'companyType',
            controlType: 'group',
            class: 'row',
            controls: {
                companyType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: companyTypes,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.companyType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'industry',
            controlType: 'group',
            class: 'row',
            controls: {
                industry: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: industries,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.industry',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'contractType',
            controlType: 'group',
            class: 'row',
            controls: {
                contractType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: contractTypes,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.contractType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'location_options',
            controlType: 'group',
            class: 'row',
            controls: {
                employmentType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: workTypes,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.employmentType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
                workType: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: employmentTypes,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.workType',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
        {
            key: 'work_options',
            controlType: 'group',
            class: 'row',
            controls: {
                onlyRelocation: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'profile.preferences.addPreferences.form.labels.onlyRelocation',
                    hostClass: 'col-xl-4 form-control switch-inline-container',
                },
            },
        },
        {
            key: 'salary',
            controlType: 'group',
            class: 'row custom-row',
            controls: {
                salary: {
                    controlType: 'control',
                    formControlType: FormControlType.QUANTITY_INPUT,
                    hostClass: 'col-xl-6 form-control',
                    label: 'profile.preferences.addPreferences.form.labels.salary',
                    minValue: 0,
                    stepValue: 100,
                    type: InputType.NUMBER,
                },
            },
        },
        {
            key: 'tag_preferences',
            controlType: 'group',
            class: 'row',
            controls: {
                preferenceTags: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.DEFAULT,
                    multiselectOptions: preferenceTags,
                    validationRules: [],
                    placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                    label: 'profile.preferences.addPreferences.form.labels.tagPreferences',
                    hostClass: 'col-xl-12 form-control',
                    inputDataMapper: multiCollectionInputDataMapper,
                    outputDataMapper: multiCollectionOutputDataMapper,
                },
            },
        },
    ],
});
