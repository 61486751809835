import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
    addRelocationFileAPI,
    authTokenSelector,
    Form,
    FormControlChangeType,
    IFormConfig,
    Loader,
    LoaderType,
    Translation,
} from 'jobhunter-common-web';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {addFileFormConfig} from './addFileFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../../store/reducers';
import {fetchRelocationFiles} from '../../../../../store/reducers/relocationDetailsPageSlice';
import {fixInjectedProperties, lazyInject} from '../../../../../ioc';
import {IAlertManagerService} from '../../../../../service/alertManagerService';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedAddRelocationFileProps {
    readonly authToken: string | null;
    readonly fetchRelocationFiles: typeof fetchRelocationFiles;
}

interface IExternalAddRelocationFileProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    applicationId: string | null;
}

interface IAddRelocationFileProps extends IConnectedAddRelocationFileProps, IExternalAddRelocationFileProps, WithTranslation {}

interface IAddRelocationFileState {
    isLoading: boolean;
    value: any;
    formConfig: typeof IFormConfig | null;
}

class AddRelocationFile extends Component<IAddRelocationFileProps, IAddRelocationFileState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IAddRelocationFileProps) {
        super(props);

        this.state = {
            isLoading: false,
            value: null,
            formConfig: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="relocationView.files.addFile.title" />
                </ModalHeader>
                <ModalBody>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'addRelocationFileForm'}
                            onButtonClicked={this.addVerificationDocument}
                        />
                    )}
                </ModalBody>
                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private setFormConfig = () => {
        this.setState({
            formConfig: addFileFormConfig(),
        });
    };

    private addVerificationDocument = () => {
        const verificationFile = this.state.value.document,
            {t} = this.props;

        if (verificationFile && verificationFile.length && this.props.applicationId) {
            const formData = new FormData();
            formData.append('file', verificationFile[0].blobFile);
            formData.append('offerApplicationId', this.props.applicationId);

            this.setState({isLoading: true});

            this.subscriptions.push(
                addRelocationFileAPI(this.props.authToken, formData)
                    .pipe(
                        tap(() => {
                            this.alertManager?.addAlert(t('relocationView.files.addFile.fileAdded'));
                            this.props.fetchRelocationFiles();
                            this.props.toggleModal();
                        }),
                        catchError((error: any) => {
                            this.setState({isLoading: false});
                            this.alertManager?.handleApiError(error);
                            return of();
                        })
                    )
                    .subscribe()
            );
        }
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
    }),
    {
        fetchRelocationFiles,
    }
)(withTranslation()(AddRelocationFile));
