import {
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    formatRangePickerLabel,
    multiCollectionInputDataMapper,
    multiCollectionOutputDataMapper,
    MultiSelectType,
} from 'jobhunter-common-web';

export const filtersFormConfig: typeof IFormConfig = (
    isFormExpanded: boolean,
    technologyList: typeof IMultiselectOption[] | [],
    seniorityLevels: any[],
    contractTypeList: typeof IMultiselectOption[] | [],
    industryList: typeof IMultiselectOption[] | [],
    companyTypeList: typeof IMultiselectOption[] | [],
    workTypes: typeof IMultiselectOption[] | [],
    employmentTypes: typeof IMultiselectOption[] | [],
    isBestMatchDisabled: boolean,
    value?: any
) => {
    let additionalControls: any[] = [];
    if (isFormExpanded) {
        additionalControls = additionalConfigFormControls(
            contractTypeList,
            industryList,
            companyTypeList,
            workTypes,
            employmentTypes,
            value
        );
    }

    return {
        controlType: 'form',
        class: 'default-form offer-filters-form',
        dataAccessor: (data: any) => data,
        outputDataMapper: (data: any, previousStateSnapshot: any) => {
            Object.assign(previousStateSnapshot, data);
            return previousStateSnapshot;
        },
        controls: [
            {
                key: 'technology_salary',
                controlType: 'group',
                class: 'row',
                controls: {
                    technology: {
                        controlType: 'control',
                        defaultValue: '',
                        formControlType: FormControlType.AUTOCOMPLETE,
                        multiselectType: MultiSelectType.DEFAULT,
                        multiselectOptions: technologyList ? technologyList : [],
                        validationRules: [],
                        placeholder: 'offers.filters.form.placeholders.select',
                        label: 'profile.preferences.addPreferences.form.labels.technology',
                        hostClass: 'col-xl-4 form-control',
                        inputDataMapper: multiCollectionInputDataMapper,
                        outputDataMapper: multiCollectionOutputDataMapper,
                        disabled: true === value?.bestMatch,
                    },
                    seniority: {
                        hostClass: 'col-xl-4 form-control',
                        controlType: 'control',
                        defaultValue: value && value.seniority ? value.seniority : 0,
                        formControlType: FormControlType.RANGE_PICKER,
                        validationRules: [],
                        rangeLabels: seniorityLevels,
                        label: 'offers.filters.form.labels.seniority',
                        labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                        disabled: value?.bestMatch,
                        maxRangeStep: Object.keys(seniorityLevels).length * 10,
                        isRangeLabelTranslated: true,
                        isMultiRangeControl: true,
                    },
                    salary: {
                        controlType: 'control',
                        defaultValue: value ? value.salary : '',
                        formControlType: FormControlType.QUANTITY_INPUT,
                        hostClass: 'col-xl-2 form-control',
                        label: 'offers.filters.form.labels.minSalary',
                        minValue: 0,
                        stepValue: 100,
                        type: InputType.NUMBER,
                        disabled: value?.bestMatch,
                    },
                    bestMatch: {
                        controlType: 'control',
                        defaultValue: value?.bestMatch ? value.bestMatch : false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        label: 'offers.filters.form.labels.bestMatch',
                        hostClass: 'col-xl-2 form-control',
                        tooltipText: isBestMatchDisabled ? 'dashboard.bestMatch.completeProfile' : null,
                        disabled: isBestMatchDisabled,
                    },
                },
            },
            ...additionalControls,
        ],
    };
};

export const additionalConfigFormControls = (
    contractTypeList: typeof IMultiselectOption[] | [],
    industryList: typeof IMultiselectOption[] | [],
    companyTypeList: typeof IMultiselectOption[] | [],
    workTypes: typeof IMultiselectOption[] | [],
    employmentTypes: typeof IMultiselectOption[] | [],
    value?: any
) => [
    {
        controlType: 'group',
        key: 'contract_industry_details',
        class: 'row',
        controls: {
            contractType: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.DEFAULT,
                multiselectOptions: contractTypeList ? contractTypeList : [],
                validationRules: [],
                placeholder: 'offers.filters.form.placeholders.select',
                label: 'offers.filters.form.labels.contactType',
                hostClass: 'col-xl-4 form-control',
                inputDataMapper: multiCollectionInputDataMapper,
                outputDataMapper: multiCollectionOutputDataMapper,
                disabled: true === value?.bestMatch,
            },
            industry: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.DEFAULT,
                multiselectOptions: industryList ? industryList : [],
                validationRules: [],
                placeholder: 'offers.filters.form.placeholders.select',
                label: 'profile.preferences.addPreferences.form.labels.industry',
                hostClass: 'col-xl-4 form-control',
                inputDataMapper: multiCollectionInputDataMapper,
                outputDataMapper: multiCollectionOutputDataMapper,
                disabled: true === value?.bestMatch,
            },
            companyType: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.DEFAULT,
                multiselectOptions: companyTypeList ? companyTypeList : [],
                validationRules: [],
                placeholder: 'offers.filters.form.placeholders.select',
                label: 'profile.preferences.addPreferences.form.labels.companyType',
                hostClass: 'col-xl-4 form-control',
                inputDataMapper: multiCollectionInputDataMapper,
                outputDataMapper: multiCollectionOutputDataMapper,
                disabled: true === value?.bestMatch,
            },
        },
    },
    {
        controlType: 'group',
        key: 'job_employment_type',
        class: 'row',
        controls: {
            workType: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.DEFAULT,
                multiselectOptions: workTypes,
                validationRules: [],
                placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                label: 'profile.preferences.addPreferences.form.labels.workType',
                hostClass: 'col-xl-4 form-control',
                inputDataMapper: multiCollectionInputDataMapper,
                outputDataMapper: multiCollectionOutputDataMapper,
                disabled: true === value?.bestMatch,
            },
            employmentType: {
                controlType: 'control',
                defaultValue: '',
                formControlType: FormControlType.AUTOCOMPLETE,
                multiselectType: MultiSelectType.DEFAULT,
                multiselectOptions: employmentTypes,
                validationRules: [],
                placeholder: 'profile.preferences.addPreferences.form.placeholders.select',
                label: 'profile.preferences.addPreferences.form.labels.employmentType',
                hostClass: 'col-xl-4 form-control',
                inputDataMapper: multiCollectionInputDataMapper,
                outputDataMapper: multiCollectionOutputDataMapper,
                disabled: true === value?.bestMatch,
            },
            // jobType: {
            //     key: 'job_type',
            //     controlType: 'group',
            //     class: 'col-4',
            //     groupLabel: 'Job type',
            //     controls: {
            //         onlyRemote: {
            //             controlType: 'control',
            //             defaultValue: false,
            //             formControlType: FormControlType.SWITCH,
            //             validationRules: [],
            //             placeholder: '',
            //             label: 'offers.filters.form.labels.onlyRemote',
            //             hostClass: 'col-xl-12 form-control switch-inline-container',
            //             disabled: true === value?.bestMatch,
            //         },
            //         onlyStationary: {
            //             controlType: 'control',
            //             defaultValue: false,
            //             formControlType: FormControlType.SWITCH,
            //             validationRules: [],
            //             placeholder: '',
            //             label: 'offers.filters.form.labels.onlyStationary',
            //             hostClass: 'col-xl-12 form-control switch-inline-container',
            //             disabled: true === value?.bestMatch,
            //         },
            //         mixed: {
            //             controlType: 'control',
            //             defaultValue: false,
            //             formControlType: FormControlType.SWITCH,
            //             validationRules: [],
            //             placeholder: '',
            //             label: 'offers.filters.form.labels.mixedRemoteStationary',
            //             hostClass: 'col-xl-12 form-control switch-inline-container',
            //             disabled: true === value?.bestMatch,
            //         },
            //     },
            // },
            // employmentType: {
            //     key: 'employment_type',
            //     controlType: 'group',
            //     class: 'col-4',
            //     groupLabel: 'Employment type',
            //     controls: {
            //         fullTime: {
            //             controlType: 'control',
            //             defaultValue: false,
            //             formControlType: FormControlType.SWITCH,
            //             validationRules: [],
            //             placeholder: '',
            //             label: 'offers.filters.form.labels.fullTime',
            //             hostClass: 'col-xl-12 form-control switch-inline-container',
            //             disabled: true === value?.bestMatch,
            //         },
            //         partTime: {
            //             controlType: 'control',
            //             defaultValue: false,
            //             formControlType: FormControlType.SWITCH,
            //             validationRules: [],
            //             placeholder: '',
            //             label: 'offers.filters.form.labels.partTime',
            //             hostClass: 'col-xl-12 form-control switch-inline-container',
            //             disabled: true === value?.bestMatch,
            //         },
            //     },
            // },
            companyType: {
                key: 'relocation',
                controlType: 'group',
                class: 'col-4',
                groupLabel: 'Relocation',
                controls: {
                    withRelocation: {
                        controlType: 'control',
                        defaultValue: false,
                        formControlType: FormControlType.SWITCH,
                        validationRules: [],
                        placeholder: '',
                        label: 'offers.filters.form.labels.withRelocation',
                        hostClass: 'col-xl-12 form-control switch-inline-container',
                        disabled: true === value?.bestMatch,
                    },
                },
            },
        },
    },
];
