import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../reducers';
import {IOfferDetailsState} from '../reducers/offerDetailsPageSlice';

export const selectOfferDetailsPage = (state: RootState): IOfferDetailsState => {
    return state.offerDetailsPage;
};

export const offerDetailsSelector = createSelector([selectOfferDetailsPage], (state: IOfferDetailsState) => state.offerDetails);

export const isOfferDetailsLoadingSelector = createSelector(
    [selectOfferDetailsPage],
    (state: IOfferDetailsState) => state.isOfferDetailsLoading
);

export const isOfferDetailsInitializedSelector = createSelector(
    [selectOfferDetailsPage],
    (state: IOfferDetailsState) => state.isOfferDetailsInitialized
);

export const offerDetailsErrorSelector = createSelector([selectOfferDetailsPage], (state: IOfferDetailsState) => state.offerDetailsError);

export const isOfferActionProcessingSelector = createSelector(
    [selectOfferDetailsPage],
    (state: IOfferDetailsState) => state.isOfferActionProcessing
);

export const isOfferActionCompleteSelector = createSelector(
    [selectOfferDetailsPage],
    (state: IOfferDetailsState) => state.isOfferActionComplete
);
