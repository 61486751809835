import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Translation} from 'jobhunter-common-web';

interface IConnectedDeleteSkillsModalProps {}

interface IExternalDeleteSkillsModalProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    removeAction?: () => void;
}

interface IDeleteSkillsModalProps extends IConnectedDeleteSkillsModalProps, IExternalDeleteSkillsModalProps {}

interface IDeleteSkillsModalState {}

class DeleteSkillsModal extends Component<IDeleteSkillsModalProps, IDeleteSkillsModalState> {
    render() {
        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader toggle={() => this.props.toggleModal()}>
                    <Translation text="profile.career.removeRecord.title" />
                </ModalHeader>
                <ModalBody>
                    <p className="modal-description">
                        <Translation text="profile.career.removeRecord.description" />
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                        <Button color="primary me-2" className="remove-record-button" onClick={() => this.removeSkill()}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.yes" />
                            </span>
                        </Button>
                        <Button outline color="secondary" className="close-modal-button" onClick={() => this.props.toggleModal()}>
                            <span className="align-middle d-sm-inline-block d-none">
                                <Translation text="buttons.no" />
                            </span>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    private removeSkill = () => {
        if (this.props.removeAction) {
            this.props.removeAction();
            this.props.toggleModal();
        }
    };
}

export default DeleteSkillsModal;
