import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    changeIsRelocationsPageLoading,
    changeRelocationsPageError,
    fetchRelocations,
    getErrorMessage,
    setRelocations,
} from 'jobhunter-common-web';
import {RootState} from '../reducers';
import {getRelocationsAPI} from '../../api/getRelocationsAPI';

const fetchRelocationsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchRelocations.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getRelocationsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setRelocations(resp['hydra:member'])]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error)))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const successActions = (changeSliceList?: any[]): any[] => {
    const actions = [changeIsRelocationsPageLoading(false)];

    if (changeSliceList) {
        return actions.concat(changeSliceList);
    }
    return actions;
};

const updateListErrorActions = (error: any): any[] => {
    return [
        changeIsRelocationsPageLoading(false),
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeRelocationsPageError(getErrorMessage(error)),
    ];
};

const relocationsPageEpic = combineEpics(fetchRelocationsEpic);

export default relocationsPageEpic;
