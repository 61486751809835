import React, {Component} from 'react';
import {
    companyTypesSelector,
    contractTypesSelector,
    convertToMultiselectLabels,
    employmentTypesSelector,
    Form,
    FormControlChangeType,
    industriesSelector,
    IFormConfig,
    IModelCity,
    IModelDictionaryDatum,
    technologiesSelector,
    Translation,
    workTypesSelector,
    citiesSelector,
    convertPriceToInputValue,
    convertPriceToPayloadValue,
    preferenceTagsSelector,
    sortMultiselectLabels,
} from 'jobhunter-common-web';
import {preferencesFormConfig} from './preferencesFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {Button} from 'reactstrap';
import {ArrowLeft, Save} from 'react-feather';
import {changePreferencesData, ICandidatePreferences, updateProfilePage} from '../../../store/reducers/profilePageSlice';
import {profilePreferencesSelector} from '../../../store/selectors/profilePageSelectors';
import {RootState} from '../../../store/reducers';
import {connect} from 'react-redux';
import {IUpdateCandidatePreferences} from '../../../api/updateFullAccountData';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedPreferencesProps {
    readonly profilePreferences: ICandidatePreferences | null;
    readonly technologies: typeof IModelDictionaryDatum[] | null;
    readonly cities: typeof IModelCity[] | null;
    readonly companyTypes: typeof IModelDictionaryDatum[] | null;
    readonly industries: typeof IModelDictionaryDatum[] | null;
    readonly contractTypes: typeof IModelDictionaryDatum[] | null;
    readonly workTypes: typeof IModelDictionaryDatum[] | null;
    readonly employmentTypes: typeof IModelDictionaryDatum[] | null;
    readonly preferenceTags: typeof IModelDictionaryDatum[] | null;
    readonly changePreferencesData: typeof changePreferencesData;
    readonly updateProfilePage: typeof updateProfilePage;
}

interface IExternalPreferencesProps {
    stepper: any;
}

interface IPreferencesProps extends IConnectedPreferencesProps, IExternalPreferencesProps, WithTranslation {}

interface IPreferencesState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class Preferences extends Component<IPreferencesProps, IPreferencesState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IPreferencesProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.setFormValuesFromState();
        this.setFormConfig();
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap(() => this.setState({isLoading: true})),
                    debounceTime(500),
                    tap((data: any) => this.changePersonalData(data.value))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IPreferencesProps>) {
        if (this.props.profilePreferences !== prevProps.profilePreferences) {
            this.setFormValuesFromState();
        }

        if (
            this.props.technologies !== prevProps.technologies ||
            this.props.cities !== prevProps.cities ||
            this.props.companyTypes !== prevProps.companyTypes ||
            this.props.industries !== prevProps.industries ||
            this.props.contractTypes !== prevProps.contractTypes ||
            this.props.workTypes !== prevProps.workTypes ||
            this.props.employmentTypes !== prevProps.employmentTypes
        ) {
            this.setFormConfig();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="wizard-content">
                <div className="career-section preferences-section">
                    <div className="header">
                        <div>
                            <p className="title">
                                <Translation text="profile.preferences.title" />
                            </p>
                            <p className="description">
                                <Translation text="profile.preferences.addPreferences.description" />
                            </p>
                            <p className="sub-title">
                                <Translation text="profile.preferences.addPreferences.job" />
                            </p>
                        </div>
                    </div>

                    <div>
                        {this.state.formConfig && (
                            <Form
                                config={this.state.formConfig}
                                onValueStateChange={this.onValueStateChange}
                                value={this.state.value}
                                controlName={'preferencesForm'}
                            />
                        )}
                    </div>
                </div>

                <div className="wizard-actions">
                    <Button color="primary" className="btn-prev preferences-prev-button" onClick={() => this.props.stepper.previous()}>
                        <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.previous" />
                        </span>
                    </Button>
                    <Button
                        color="primary"
                        className="btn-submit save-profile-button"
                        onClick={() => this.updatePreferences(this.state.value)}>
                        <Save size={14} className="align-middle ms-sm-25 me-25" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.save" />
                        </span>
                    </Button>
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changePersonalData = (data: {[key: string]: any}) => {
        this.setState({value: data, isLoading: false});
    };

    private setFormConfig = () => {
        const {t} = this.props;
        const technologies = this.props.technologies ? sortMultiselectLabels(convertToMultiselectLabels(this.props.technologies, t)) : [],
            cities = this.props.cities ? sortMultiselectLabels(convertToMultiselectLabels(this.props.cities, t)) : [],
            companyTypes = this.props.companyTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.companyTypes, t)) : [],
            industries = this.props.industries ? sortMultiselectLabels(convertToMultiselectLabels(this.props.industries, t)) : [],
            contractTypes = this.props.contractTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.contractTypes, t)) : [],
            workTypes = this.props.workTypes ? sortMultiselectLabels(convertToMultiselectLabels(this.props.workTypes, t)) : [],
            employmentTypes = this.props.employmentTypes
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.employmentTypes, t, true))
                : [],
            preferenceTags = this.props.preferenceTags
                ? sortMultiselectLabels(convertToMultiselectLabels(this.props.preferenceTags, t, true))
                : [];

        this.setState({
            formConfig: preferencesFormConfig(
                technologies,
                cities,
                companyTypes,
                industries,
                contractTypes,
                employmentTypes,
                workTypes,
                preferenceTags
            ),
        });
    };

    private setFormValuesFromState = () => {
        const preferences = this.props.profilePreferences,
            values = {
                companyType: preferences?.companyTypes.map((type) => type.id),
                contractType: preferences?.contractTypes.map((type) => type.id),
                industry: preferences?.industries.map((industry) => industry.id),
                location: preferences?.cities.map((city) => city.id),
                onlyRelocation: preferences?.relocationOnly,
                workType: preferences?.workTypes.map((type) => type.id),
                employmentType: preferences?.employmentTypes.map((type) => type.id),
                salary: convertPriceToInputValue(preferences?.minimumSalary),
                technology: preferences?.technologies.map((technology) => technology.id),
                preferenceTags: preferences?.preferenceTags.map((tag) => tag.id),
            };

        this.setState({value: values});
    };

    private updatePreferences = (value: {[key: string]: any}) => {
        const candidatePreferences: IUpdateCandidatePreferences = {
            technologies: value.technology ? value.technology : [],
            cities: value.location ? value.location : [],
            companyTypes: value.companyType ? value.companyType : [],
            industries: value.industry ? value.industry : [],
            contractTypes: value.contractType ? value.contractType : [],
            employmentTypes: value.employmentType ? value.employmentType : [],
            workTypes: value.workType ? value.workType : [],
            minimumSalary: convertPriceToPayloadValue(value.salary),
            relocationOnly: value.onlyRelocation,
            preferenceTags: value.preferenceTags ? value.preferenceTags : [],
        };
        this.props.updateProfilePage(candidatePreferences);
    };
}

export default connect(
    (state: RootState) => ({
        profilePreferences: profilePreferencesSelector(state),
        technologies: technologiesSelector(state),
        cities: citiesSelector(state),
        companyTypes: companyTypesSelector(state),
        industries: industriesSelector(state),
        contractTypes: contractTypesSelector(state),
        workTypes: workTypesSelector(state),
        employmentTypes: employmentTypesSelector(state),
        preferenceTags: preferenceTagsSelector(state),
    }),
    {
        changePreferencesData,
        updateProfilePage,
    }
)(withTranslation()(Preferences));
