import React, {Component} from 'react';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {Calendar} from 'react-feather';
import {Translation} from 'jobhunter-common-web';
import EventCalendar from '../../../Dashboard/EventCalendar';
import {ICalendarEvent} from '../../../../model/calendarEventModel';
import AddRelocationEvent from './AddRelocationEvent';
import {IAddRelocationCalendarEventPayload} from '../../../../api/addRelocationCalendarEventAPI';
import {addRelocationEvent, changeIsRelocationEventAdded} from '../../../../store/reducers/relocationDetailsPageSlice';

interface IRelocationCalendarProps {
    readonly username: string;
    readonly calendarEvents: ICalendarEvent[] | null;
    readonly applicationId: string | null;
    readonly isRelocationEventAdded: boolean;
    readonly error: string | null;
    readonly addRelocationEvent: typeof addRelocationEvent;
    readonly changeIsRelocationEventAdded: typeof changeIsRelocationEventAdded;
}

interface IRelocationCalendarState {
    isAddEventModalShown: boolean;
}

class RelocationCalendar extends Component<IRelocationCalendarProps, IRelocationCalendarState> {
    constructor(props: IRelocationCalendarProps) {
        super(props);

        this.state = {
            isAddEventModalShown: false,
        };
    }

    render() {
        return (
            <>
                <Card className="relocation-events-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <Calendar size={20} />
                                    <Translation text="relocationView.calendar.title" />
                                </div>
                                <button className="btn btn-primary offers-button" onClick={() => this.toggleAddEventModal()}>
                                    <Translation text="buttons.addNew" />
                                </button>
                            </div>
                        </CardTitle>
                        <EventCalendar
                            username={this.props.username}
                            calendarEvents={this.props.calendarEvents}
                            isCalendarLoading={false}
                        />
                    </CardBody>
                </Card>
                {this.state.isAddEventModalShown ? (
                    <AddRelocationEvent
                        isModalOpen={this.state.isAddEventModalShown}
                        toggleModal={this.toggleAddEventModal}
                        addRelocationEvent={this.addRelocation}
                        isRelocationEventAdded={this.props.isRelocationEventAdded}
                        changeIsRelocationEventAdded={this.props.changeIsRelocationEventAdded}
                        error={this.props.error}
                    />
                ) : null}
            </>
        );
    }

    private toggleAddEventModal = () => {
        this.setState({isAddEventModalShown: !this.state.isAddEventModalShown});
    };

    private addRelocation = (payload: IAddRelocationCalendarEventPayload) => {
        if (!this.props.applicationId) {
            return;
        }

        this.props.addRelocationEvent(this.props.applicationId, payload);
    };
}

export default RelocationCalendar;
