import {Observable} from 'rxjs';
import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';

export interface IUpdateCareerEntry {
    from: string;
    to: string | null;
    company: string;
    position: string;
    seniorityId: string;
    id?: string;
}

export interface IUpdateTechnologyEntry {
    technologyId: string;
    technologyTools: string[];
    seniorityId: string;
    id?: string;
}

export interface IUpdateLanguageEntry {
    languageId: string;
    languageLevelId: string;
    id?: string;
}

export interface IUpdateExternalLink {
    url: string;
    type: string;
    id?: string;
}

export interface IUpdateCandidatePreferences {
    technologies?: string[];
    cities?: string[];
    companyTypes?: string[];
    industries?: string[];
    contractTypes?: string[];
    employmentTypes?: string[];
    workTypes?: string[];
    minimumSalary?: string;
    relocationOnly?: boolean;
    preferenceTags?: string[];
}

export interface IUpdateCandidate {
    candidatePreferences: IUpdateCandidatePreferences;
    careerEntries?: IUpdateCareerEntry[];
    technologySkillEntries?: IUpdateTechnologyEntry[];
    languageEntries?: IUpdateLanguageEntry[];
    externalLinks?: IUpdateExternalLink[];
    cvId?: string;
}

export interface IUpdateAccountPayload {
    candidate?: IUpdateCandidate | null;
    firstName?: string;
    lastName?: string;
    phone?: string;
    birthDate?: string | null;
    cityName?: string;
    countryId?: string;
    about?: string;
    avatar?: string;
}

export function updateFullAccountDataAPI(authToken: string | null, accountId: string, payload: IUpdateAccountPayload): Observable<any> {
    let headers = undefined;
    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.put(`api/accounts/${accountId}/change_candidate_full_info`, payload, new RestQueryParams(), headers);
}
