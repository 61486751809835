const en = {
    dashboard: {
        bestMatch: {
            title: 'Best match',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    publication: 'Publication',
                },
            },
            noData: 'There are no matched offers',
            completeProfile:
                'You need to complete the profile preferences data and personal details information to see the most suitable offers.',
        },
        applications: {
            title: 'Your applications',
            table: {
                headers: {
                    project: 'Project',
                    city: 'City',
                    salary: 'Salary',
                    status: 'Status',
                },
            },
            noData: 'There are no applications',
        },
        profile: {
            title: 'Profile',
            personalInformation: {
                title: 'Personal',
                description: 'Complete your personal information',
            },
            career: {
                title: 'Career',
                description: 'Complete your career information',
            },
            skills: {
                title: 'Skill tests',
                description: 'Complete your skill tests',
            },
            preferences: {
                title: 'Preferences',
                description: 'Complete your preferences',
            },
        },
        calendar: {
            title: 'Calendar',
            eventCalendar: {
                title: 'Events',
                noEvents: 'There are no events',
            },
        },
    },
    profile: {
        personal: {
            title: 'Personal',
            description: 'Enter information',
            personalInformation: 'Personal Information',
            enterInformation: 'Enter your information',
            form: {
                placeholders: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    phoneNumber: 'Phone number',
                    birthDate: 'Birth date',
                    city: 'City',
                    country: 'Country',
                    description: 'Write something about you',
                },
                labels: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    phoneNumber: 'Phone number',
                    birthDate: 'Birth date',
                    city: 'City',
                    country: 'Country',
                    description: 'About me',
                    relocation: 'Relocation available:',
                    remote: 'Only remote:',
                },
                buttons: {
                    upload: 'Upload',
                    change: 'Change',
                    remove: 'Remove',
                },
            },
        },
        career: {
            title: 'Career',
            description: 'Lorem ipsum',
            careerTimeline: 'Career timeline:',
            noCareer: 'You have not yet added any jobs. Select "Add new" and update your virtual CV.',
            noTechnologySkills: 'Add programming skills and select their level.  We will then suggest job opportunities to you.',
            noLanguages: 'Add the languages you know and select their level.',
            socialLinks: {
                placeholders: {
                    github: 'http://github.com/',
                    linkedin: 'http://linkedin.com',
                    portfolio: 'http://portfolio.com',
                },
                socialLinksUpdated: 'Social links have been updated',
                cvUpdated: 'CV information has been updated',
            },
            addLanguage: {
                title: 'Add your language skills',
                description: 'Choose languages you know and its corresponding levels',
                form: {
                    labels: {
                        language: 'Language',
                        level: 'Level',
                    },
                },
                languagesUpdated: 'Language entries have been updated.',
            },
            editLanguage: {
                title: 'Edit language entry',
            },
            addCareer: {
                title: 'Add your job',
                description: 'Provide information on your job experience',
                form: {
                    labels: {
                        company: 'Company name',
                        position: 'Position',
                        startDate: 'Start date',
                        endDate: 'End date',
                        isWorking: 'Currently working here',
                    },
                },
            },
            editCareer: {
                title: 'Edit career entry',
            },
            addTechnology: {
                title: 'Add your technology skills',
                description: 'Choose a programming language and the tools and frameworks you know',
                form: {
                    labels: {
                        technology: 'Technology',
                        tools: 'Tools and frameworks',
                    },
                    placeholders: {
                        select: 'Write or select',
                    },
                },
                technologiesUpdated: 'Technology entries have been updated.',
                testEmailSent: 'We sent an email with a test link',
            },
            editTechnology: {
                title: 'Edit technology entry',
            },
            removeRecord: {
                title: 'Remove record',
                description: 'Are you sure you want to remove selected record?',
            },
            careersUpdated: 'Career entries have been updated',
        },
        skillTest: {
            title: 'Skill test',
            description: 'Lorem ipsum',
            testSkills: 'Test your skills with ...',
            noTestsTaken: 'You have not added any skills yet.',
            technologySkills: 'Technology skills:',
            languageSkills: 'Language skills:',
            takeSkillTest: 'Test yourself',
        },
        preferences: {
            title: 'Preferences',
            description: 'Lorem ipsum',
            addPreferences: {
                description: 'Enter your information',
                job: 'Job:',
                form: {
                    labels: {
                        technology: 'Technology',
                        location: 'Location',
                        companyType: 'Company type',
                        industry: 'Industry',
                        contractType: 'Contract type',
                        workType: 'Work type',
                        employmentType: 'Employment type',
                        onlyRemote: 'Remote',
                        partTimeRemote: 'Part time remote',
                        onlyStationary: 'Stationary',
                        fullTime: 'Full time',
                        partTime: 'Part time',
                        onlyRelocation: 'Only relocation',
                        salary: 'Salary min.',
                        tagPreferences: 'Preference tags',
                    },
                    placeholders: {
                        select: 'Select',
                        tagPreferences: 'Type in tags',
                    },
                },
            },
        },
        profileForm: {
            profileUpdated: 'Profile data has been updated',
        },
    },
    relocations: {
        title: 'Relocations',
        table: {
            employer: 'Employer',
            hireDate: 'Hire date',
            status: 'Status',
            actions: 'Actions',
            noData: 'There are no relocations available.',
        },
        tooltips: {
            view: 'View relocation details',
        },
    },
    relocationView: {
        title: 'Relocation',
        chat: {
            title: 'Chat',
            unknownUser: 'Unknown user',
        },
        status: {
            title: 'Status',
            form: {
                labels: {
                    currentStatus: 'Current status',
                },
                placeholders: {
                    currentStatus: 'Enter current status',
                },
            },
        },
        calendar: {
            title: 'Calendar',
            events: 'Events',
            tooltips: {
                delete: 'Delete an event',
                view: 'View an event',
            },
            addEvent: {
                title: 'Add event',
                form: {
                    labels: {
                        title: 'Title',
                        description: 'Description',
                        date: 'Date',
                        type: 'Type',
                    },
                    placeholders: {
                        title: 'Title',
                        description: 'Enter description',
                        date: 'Select date',
                        type: 'Select type',
                    },
                },
                eventAdded: 'Relocation event has been added',
            },
            removeEvent: {
                title: 'Are you sure you want to delete this event?',
                description: 'Click Delete to confirm',
            },
            eventView: {
                noTitle: 'Unknown event',
                noDescription: 'Description of the event was not provided.',
            },
            eventTypes: {
                alert: 'Alert',
                info: 'Info',
                warning: 'Warning',
            },
        },
        files: {
            title: 'Files',
            table: {
                fileName: 'File name',
                actions: 'Actions',
                noData: 'There are no files added.',
            },
            tooltips: {
                download: 'Download file',
                delete: 'Delete file',
            },
            addFile: {
                title: 'Add file',
                form: {
                    labels: {
                        selectFile: 'Select relocation file',
                    },
                },
                fileAdded: 'Relocation file has been added.',
            },
            removeFile: {
                title: 'Are you sure you want to delete this file?',
                description: 'Click Delete to confirm',
                fileRemoved: 'Relocation file has been removed',
            },
            downloadFile: {
                fileDownloaded: 'Relocation file has been downloaded',
            },
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Footer containing contact information and social links',
            addressColumn: {
                title: 'JobHunter Sp z o.o',
                address1: 'ul. Zygmunta Krasińskiego 2',
                address2: '20-709 Lublin',
                nip: 'NIP: 9662131616',
            },
            copyright: 'JobHunter {{date}} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link to Facebooku profile',
                instagram: 'Link to Instagram profile',
                linkedin: 'Link to Linkedin profile',
            },
        },
        formControls: {
            placeholderName: 'First Name',
            placeholderSurname: 'Last Name',
            placeholderEmail: 'Email',
            placeholderPassword: 'Password',
            placeholderPhone: 'Phone',
            placeholderConfirmPassword: 'Confirm Password',
            email: 'Email',
            password: 'Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            registrationTermsAgreement: ``,
            phone: 'Phone',
            firstName: 'First Name',
            lastName: 'Last Name',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
            GDPRInfo: `By clicking 'Sign up' I hereby agree to the processing of personal data provided in this document for realizing the recruitment process pursuant to the Personal Data Protection Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in agreement with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).`,
        },
        register: {
            title: 'Welcome to Job Hunter 👋🏻',
            subtitle:
                'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
            haveAccount: 'Already have an account?',
            logIn: 'Sign in instead',
            backToLogin: 'Back to login',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google',
            },
        },
        confirmRegistration: {
            title: 'Confirm registration',
            subtitle: 'In order to complete registration on the platform click button bellow',
        },
        login: {
            title: 'Welcome to Job Hunter 👋🏻',
            subtitle: '',
            haveAccount: 'New on our platform?',
            register: 'Create an account',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google',
            },
        },
        resetPassword: {
            title: 'Reset Password 🔐',
            subtitle: 'Please provide an email used during registration process. Further instructions will be sent to your email',
            placeholderRepeatPassword: 'Confirm Password',
            ConfirmPassword: 'Confirm Password',
        },
        newPassword: {
            title: 'Change Password 🔐',
            subtitle: 'Enter a new password',
        },
        alert: {
            loginSuccess: 'Login successful.',
            loginFailed: 'Incorrect login or password. Please try again.',
            logout: 'You have been logged out.',
            registrationSuccess: 'Registration data sent correctly, confirm registration by clicking on the link provided in the email.',
            confirmRegistrationSuccess: 'Your email has been confirmed. You can now login to the application.',
            sendResetPasswordMailSuccess: 'Please check your email for password reset link.',
            confirmResetPasswordSuccess: 'Your password has been changed. You can log in to the panel.',
            authenticationError: 'You must be logged in to access the data.',
        },
    },
    settings: {
        changePassword: {
            title: 'Change password',
            form: {
                labels: {
                    currentPassword: 'Current password',
                    newPassword: 'New password',
                    confirmPassword: 'Confirm new password',
                },
                placeholders: {
                    currentPassword: 'Enter current password',
                    newPassword: 'Enter new password',
                    confirmPassword: 'Confirm your new password',
                },
            },
            passwordRequirements: {
                title: 'Password requirements:',
                length: 'Minimum 8 characters long - the more, the better',
                uppercase: 'At least one lowercase character',
                characters: 'At least one number, symbol, or whitespace character',
            },
            alerts: {
                passwordChanged: 'Password sucsessfully changed',
            },
        },
    },
    paymentCard: {
        form: {
            labels: {
                cardholderName: 'Cardholder name',
                cardNumber: 'Card number',
            },
            placeholders: {
                cardholderName: 'Cardholder name',
            },
        },
    },
    offers: {
        title: 'Find your new job',
        description: 'Find job in Poland',
        tabs: {
            offers: 'Offers',
            myApplications: 'My applications',
            myRecruitment: 'My recruitment',
            observed: 'Observed',
        },
        form: {
            placeholders: {
                search: 'Search',
            },
        },
        filters: {
            moreFilters: 'More filters',
            lessFilters: 'Less filters',
            form: {
                placeholders: {
                    select: 'Select',
                },
                labels: {
                    technology: 'Technology',
                    seniority: 'Seniority',
                    minSalary: 'Min. salary EUR',
                    bestMatch: 'Best match',
                    contactType: 'Contract type',
                    industry: 'Industry',
                    companyType: 'Company type',
                    jobType: 'Job type',
                    employmentType: 'Employment type',
                    relocation: 'Relocation',
                    onlyRemote: 'Only remote',
                    onlyStationary: 'Only stationary',
                    mixedRemoteStationary: 'Mixed remote & stationary',
                    fullTime: 'Full time',
                    partTime: 'Part time',
                    withRelocation: 'With relocation',
                },
            },
        },
        table: {
            project: 'Project',
            seniority: 'Seniority',
            salary: 'Salary',
            remote: 'Remote / Stationary',
            status: 'Status',
            actions: 'Actions',
            tooltips: {
                observe: 'Observe',
                unobserve: 'Remove from observed',
                apply: 'Apply',
                unapply: 'Unapply',
                details: 'Details',
                consultation: 'Join consultation',
                share: 'Share',
                recommend: 'Recommend',
            },
            noData: 'There are no offers found.',
        },
        offerView: {
            noData: 'There is no information on the offer available.',
            labels: {
                seniority: 'Seniority',
                employees: 'Employees',
                relocation: 'Relocation',
                salary: 'Salary',
                industry: 'Industry',
                companyType: 'Company type',
                contractType: 'Contract type',
                employmentType: 'Employment type',
                jobType: 'Job type',
            },
            description: {
                technology: 'Tech stack',
                jobDescription: 'Job description',
                responsibilities: 'Your responsibilities',
                benefits: 'What we offer',
                preferenceTags: 'Preference tags',
            },
            applicationForm: {
                title: 'Are you sure you want to apply?',
                description: 'If you choose to apply, your application will be submitted to the employer.',
                labels: {
                    applicationAgreement: 'I confirm that I want to apply for this job.',
                    dataProcessingAgreement: 'I agree to the processing of my personal data for recruitment procedures',
                },
                removeApplicationTitle: 'Remove application',
                removeApplicationDescription: 'Are you sure you want to remove application',
            },
            recommendOffer: {
                title: 'Recommend',
                description: 'Enter email and send this offer to your friend.',
                send: 'Send to friend',
                recommendationSent: 'Offer recommendation has been sent',
            },
        },
        alerts: {
            shareOffer: 'Link to offer has been copied to clipboard',
        },
    },
    marketplace: {
        title: 'Find a service',
        description: 'find the service that fits your needs',
        form: {
            labels: {
                serviceType: 'Service type',
                salaryRange: 'Minimum price',
            },
        },
        serviceDetails: {
            by: 'by',
            readMore: 'Read more',
            readLess: 'Read less',
            purchaseDisabled: 'You cannot buy selected service since you do not have any applications yet.',
            freeService: 'Free',
        },
        buyService: {
            title: 'Payment',
            description: 'Add card for further billing',
            billingSummary: 'Billing summary',
            servicePurchased: 'Service was successfully purchased',
        },
        purchaseComplete: {
            title: 'Thanks for purchase!',
            invoice: 'Invoice #',
            serviceName: 'Service name',
            subTotal: 'Subtotal',
            tax: 'Tax',
            total: 'Total',
            details: 'Your details',
            serviceTitle: 'Service title',
            companyName: 'Company name',
            noticeError: 'Notice something wrong?',
            report: 'let us know',
        },
    },
    services: {
        title: 'My services',
        table: {
            headers: {
                invoice: 'Invoice',
                service: 'Service',
                info: 'Info',
                serviceDescription: 'Service description',
                cost: 'Cost',
                status: 'Status',
                actions: 'Actions',
            },
            tooltips: {
                buyAgain: 'Buy again',
                details: 'Details',
            },
            noData: 'You have not purchased any services yet.',
            status: {
                paid: 'Paid',
                pending: 'Pending',
                expired: 'Expired',
            },
        },
    },
    videoChat: {
        consultationStart: 'Meeting starts at ',
        consultationTimeZone: 'Time zone: Eastern European Time',
        wailTillConsultationStarts: 'Wait for the HR specialist to let you in.',
        noAccess: 'You cannot join the consultation.',
        contactAdmin: 'Check URL or contact admin.',
        consultationEnded: 'Meeting is already over',
        rescheduleConsultation: 'In case you have missed the meeting, please contact the admin.',
        consultationTimeTooltip:
            'The timer will start count down 10 minutes before the end of the consultation. Once the time is up, there will be no possibility to return to the conversation.',
        settings: {
            title: 'Settings',
            select: 'Select',
            selectCamera: 'Choose a camera',
            selectSpeaker: 'Choose a speaker',
            selectMicrophone: 'Choose a microphone',
            testMicrophone: 'Say something to test a microphone',
            poor: 'poor',
            excellent: 'excellent',
            saveChanges: 'Save preferences',
        },
        chat: {
            errorMessage: 'Nie udało się wysłać wiadomości',
            placeholder: 'Write a message',
            noMessages: 'You do not have any messages',
            status: {
                online: 'Online',
            },
        },
    },
    buttons: {
        add: 'Add',
        addNew: 'Add new',
        confirm: 'Confirm',
        upload: 'Upload',
        more: 'More',
        login: 'Login',
        saveChanges: 'Save changes',
        discard: 'Discard',
        apply: 'Apply',
        buy: 'Buy',
        readLess: 'Read less',
        readMore: 'Read more',
        close: 'Close',
        join: 'Join',
        showAll: 'Show all',
        next: 'Next',
        previous: 'Previous',
        register: 'Sign Up',
        submit: 'Submit',
        change: 'Change',
        remove: 'Remove',
        save: 'Save',
        setNewPassword: 'Set new password',
        choosePhoto: 'Choose photo',
        yes: 'Yes',
        no: 'No',
        sendEmail: 'Send email',
        delete: 'Delete',
        cancel: 'Cancel',
    },
    formValidation: {
        errors: {
            minLength: 'The value is to short',
            isEmailValid: 'Email is not valid',
            isRequired: 'Field is required',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Password cannot be different',
            isStartDateValid: 'Please select future date',
            isStartDateGreaterOrEvenValid: 'Please select future date',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
            stripeError: 'Stripe: płatność nie powiodła się.',
            isValidUrl: 'Provided input is not a valid url.',
            isPositiveNumberOrZero: 'Value should be greater than 0',
        },
    },
    form: {
        fileUploadAllowedFiles: 'Allowed file types:',
    },
    footer: {
        copyright: `JobHunter.io {{date}} © All rights reserved`,
    },
    chat: {
        noUserListProvided: 'There are no contacts added.',
        fileIsReadyToSent: 'Files are ready to be sent:',
        fileSize: 'File size:',
        noNodeConnection: 'Server connection failed. Chat is currently unavailable.',
        noConnectionReasons: 'Possible Reasons:',
        already_online: 'Someone else is using this account.',
        expired_token: 'Token has expired. You have to relog your account',
        not_authorized: 'You have no authorisation rights to use chat. Contact with admin',
        no_token: 'No token provided. Relog your account',
        'websocket error': 'Unexpected connection error. Try again later',
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony',
    },
    db: {
        seniority: {
            novice: 'Novice',
            trainee: 'Trainee',
            junior: 'Junior',
            middle: 'Mid',
            senior: 'Senior',
            architect: 'Architect',
        },
        technology: {
            javascript: 'JavaScript',
            php: 'PHP',
            java: 'Java',
            cpp: 'C++',
            csharp: 'C#',
            python: 'Python',
            html: 'HTML',
            html5: 'HTML5',
            ruby: 'Ruby',
            scala: 'Scala',
            golang: 'Go',
            sql: 'SQL',
            rust: 'Rust',
        },
        technology_tool: {
            laravel: 'Laravel',
            reactjs: 'Reactjs',
            yii: 'Yii',
            angularjs: 'Angular',
            angular: 'Angular',
            symfony: 'Symfony',
            doctrine: 'Doctrine',
            phalcon: 'Phalcon',
            spring: 'Spring',
            hibernate: 'Hibernate',
            javalin: 'Javalin',
            dotnet: '.NET',
            xamarin: 'Xamarin',
            infernet: 'Infer.NET',
            vuejs: 'Vue.js',
            gatsby: 'Gatsby',
            django: 'Django',
            flask: 'Flask',
            framework7: 'Framework7',
            kendoui: 'Kendo UI',
            rubyonrails: 'Ruby on Rails',
            sinatra: 'Sinatra',
            hanami: 'Hanami',
            grape: 'Grape',
            ramaze: 'Ramaze',
            slick: 'Slick',
            playframework: 'Play Framework',
            akka: 'Akka',
            gin: 'Gin',
            echo: 'Echo',
            kit: 'Kit',
            fasthttp: 'Fasthttp',
            aeron: 'Aeron',
            tensorflow: 'TensorFlow',
            devart: 'Devart',
            dbvisualizer: 'DbVisualizer',
            dbschema: 'DbSchema',
            manageengine: 'ManageEngine',
            tauri: 'Tauri',
            yew: 'Yew',
            rocket: 'Rocket',
            juniper: 'Juniper',
            dioxus: 'Dioxus',
        },
        language: {
            de: 'German',
            en: 'English',
            pl: 'Polish',
        },
        language_level: {
            a1: 'A1',
            a2: 'A2',
            b1: 'B1',
            b2: 'B2',
            c1: 'C1',
            c2: 'C2',
        },
        contract_type: {
            employment: 'Employment',
            freelance: 'Freelance',
            b2b: 'B2B',
        },
        company_type: {
            support: 'Support',
            hoster: 'Hoster',
            softwarehouse: 'Softwarehouse',
            agency: 'Agency',
            integrator: 'Integrator',
            startup: 'Startup',
            interactive_agency: 'Interactive Agency',
            team_leasing_company: 'Team Leasing Company',
            body_leasing_company: 'Body Leasing Company',
            own_it_department: 'Own IT department',
        },
        work_type: {
            partially_remote: 'Partially remote',
            stationary: 'Stationary',
            remote: 'Remote',
        },
        industry: {
            government: 'Government',
            finance: 'Finance',
            it: 'IT',
            education: 'Education',
            healthcare: 'Healthcare',
            hardware: 'Hardware',
            software: 'Software',
            saas: 'Software as a Service',
            platforms: 'Platforms',
            systems_integrator: 'Systems Integrator',
            business_process_outsourcing: 'Business Process Outsourcing',
            infrastructure: 'Infrastructure',
            data_centers: 'Data Centers',
            telecom: 'Telecom',
            it_consulting: 'IT Consulting',
            creative_services: 'Creative Services',
            information_security: 'Information Security',
            data_services: 'Data Services',
            ai: 'Artificial Intelligence',
            robotics: 'Robotics',
            gaming: 'Gaming',
            media: 'Media',
            simulation: 'Simulation',
            research_n_development: 'Research & Development',
            ecommerce: 'Ecommerce',
            energy: 'Energy',
            marketing: 'Marketing',
            market_research: 'Market Research',
            competitive_intelligence: 'Competitive Intelligence',
            public_services: 'Public Services',
            smart_cities: 'Smart Cities',
            building_n_home_automation: 'Building & Home Automation',
            immersive_environments: 'Immersive Environments',
            sharing_economy: 'Sharing Economy',
            consumer_products: 'Consumer Products',
            mass_customization: 'Mass Customization',
            consumer_services: 'Consumer Services',
            business_services: 'Business Services',
            reputation_systems: 'Reputation Systems',
            architecture: 'Architecture',
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            others: 'Others',
        },
        employment_type: {
            part_time: 'Part time',
            full_time: 'Full time',
        },
        city: {
            warsaw: 'Warsaw',
            poznan: 'Poznan',
            wroclaw: 'Wroclaw',
            krakow: 'Krakow',
        },
        preference_tag: {
            clubs_high: 'Clubs high',
            nature_high: 'Nature high',
            education_medium: 'Education medium',
            'holidays_26-30': 'Holidays 26-30',
            climate_hot: 'Climate hot',
            pace_of_life_slow: 'Pace of life slow',
            climate_dry: 'Climate dry',
            climate_forest: 'Climate forest',
            climate_mountains: 'Climate mountains',
            nature_medium: 'Nature medium',
            'holidays_21-25': 'Holidays 21-25',
            climate_humid: 'Climate humid',
            holidays_11_15: 'Holidays 11-15',
            education_low: 'Education low',
            nature_low: 'Nature low',
            holidays_no_more_than_10: 'Holidays no more than 10',
            religion_buddhism: 'Religion buddhism',
            climate_snow: 'Climate snow',
            religion_judaism: 'Religion judaism',
            holidays_31_or_more: 'Holidays 31 or more',
            religion_muslim: 'Religion muslim',
            religion_hindu: 'Religion hindu',
            clubs_medium: 'Clubs medium',
            climate_cold: 'Climate cold',
            education_high: 'Education high',
            pace_of_life_medium: 'Pace of life medium',
            religion_taoist: 'Religion taoist',
            religion_protestant: 'Religion protestant',
            'holidays_16-20': 'Holidays 16-20',
            climate_temperate: 'Climate temperate',
            pace_of_life_fast: 'Pace of life fast',
            religion_catholic: 'Religion catholic',
            clubs_low: 'Clubs low',
        },
        country: {
            AF: 'Afghanistan',
            AL: 'Albania',
            DZ: 'Algeria',
            AS: 'American Samoa',
            AD: 'Andorra',
            AO: 'Angola',
            AI: 'Anguilla',
            AQ: 'Antarctica',
            AG: 'Antigua and Barbuda',
            AR: 'Argentina',
            AM: 'Armenia',
            AW: 'Aruba',
            AU: 'Australia',
            AT: 'Austria',
            AZ: 'Azerbaijan',
            BS: 'Bahamas (the)',
            BH: 'Bahrain',
            BD: 'Bangladesh',
            BB: 'Barbados',
            BY: 'Belarus',
            BE: 'Belgium',
            BZ: 'Belize',
            BJ: 'Benin',
            BM: 'Bermuda',
            BT: 'Bhutan',
            BO: 'Bolivia (Plurinational State of)',
            BQ: 'Bonaire, Sint Eustatius and Saba',
            BA: 'Bosnia and Herzegovina',
            BW: 'Botswana',
            BV: 'Bouvet Island',
            BR: 'Brazil',
            IO: 'British Indian Ocean Territory (the)',
            BN: 'Brunei Darussalam',
            BG: 'Bulgaria',
            BF: 'Burkina Faso',
            BI: 'Burundi',
            CV: 'Cabo Verde',
            KH: 'Cambodia',
            CM: 'Cameroon',
            CA: 'Canada',
            KY: 'Cayman Islands (the)',
            CF: 'Central African Republic (the)',
            TD: 'Chad',
            CL: 'Chile',
            CN: 'China',
            CX: 'Christmas Island',
            CC: 'Cocos (Keeling) Islands (the)',
            CO: 'Colombia',
            KM: 'Comoros (the)',
            CD: 'Congo (the Democratic Republic of the)',
            CG: 'Congo (the)',
            CK: 'Cook Islands (the)',
            CR: 'Costa Rica',
            HR: 'Croatia',
            CU: 'Cuba',
            CW: 'Curaçao',
            CY: 'Cyprus',
            CZ: 'Czechia',
            CI: "Côte d'Ivoire",
            DK: 'Denmark',
            DJ: 'Djibouti',
            DM: 'Dominica',
            DO: 'Dominican Republic (the)',
            EC: 'Ecuador',
            EG: 'Egypt',
            SV: 'El Salvador',
            GQ: 'Equatorial Guinea',
            ER: 'Eritrea',
            EE: 'Estonia',
            SZ: 'Eswatini',
            ET: 'Ethiopia',
            FK: 'Falkland Islands (the) [Malvinas]',
            FO: 'Faroe Islands (the)',
            FJ: 'Fiji',
            FI: 'Finland',
            FR: 'France',
            GF: 'French Guiana',
            PF: 'French Polynesia',
            TF: 'French Southern Territories (the)',
            GA: 'Gabon',
            GM: 'Gambia (the)',
            GE: 'Georgia',
            DE: 'Germany',
            GH: 'Ghana',
            GI: 'Gibraltar',
            GR: 'Greece',
            GL: 'Greenland',
            GD: 'Grenada',
            GP: 'Guadeloupe',
            GU: 'Guam',
            GT: 'Guatemala',
            GG: 'Guernsey',
            GN: 'Guinea',
            GW: 'Guinea-Bissau',
            GY: 'Guyana',
            HT: 'Haiti',
            HM: 'Heard Island and McDonald Islands',
            VA: 'Holy See (the)',
            HN: 'Honduras',
            HK: 'Hong Kong',
            HU: 'Hungary',
            IS: 'Iceland',
            IN: 'India',
            ID: 'Indonesia',
            IR: 'Iran (Islamic Republic of)',
            IQ: 'Iraq',
            IE: 'Ireland',
            IM: 'Isle of Man',
            IL: 'Israel',
            IT: 'Italy',
            JM: 'Jamaica',
            JP: 'Japan',
            JE: 'Jersey',
            JO: 'Jordan',
            KZ: 'Kazakhstan',
            KE: 'Kenya',
            KI: 'Kiribati',
            KP: "Korea (the Democratic People's Republic of)",
            KR: 'Korea (the Republic of)',
            KW: 'Kuwait',
            KG: 'Kyrgyzstan',
            LA: "Lao People's Democratic Republic (the)",
            LV: 'Latvia',
            LB: 'Lebanon',
            LS: 'Lesotho',
            LR: 'Liberia',
            LY: 'Libya',
            LI: 'Liechtenstein',
            LT: 'Lithuania',
            LU: 'Luxembourg',
            MO: 'Macao',
            MG: 'Madagascar',
            MW: 'Malawi',
            MY: 'Malaysia',
            MV: 'Maldives',
            ML: 'Mali',
            MT: 'Malta',
            MH: 'Marshall Islands (the)',
            MQ: 'Martinique',
            MR: 'Mauritania',
            MU: 'Mauritius',
            YT: 'Mayotte',
            MX: 'Mexico',
            FM: 'Micronesia (Federated States of)',
            MD: 'Moldova (the Republic of)',
            MC: 'Monaco',
            MN: 'Mongolia',
            ME: 'Montenegro',
            MS: 'Montserrat',
            MA: 'Morocco',
            MZ: 'Mozambique',
            MM: 'Myanmar',
            NA: 'Namibia',
            NR: 'Nauru',
            NP: 'Nepal',
            NL: 'Netherlands (the)',
            NC: 'New Caledonia',
            NZ: 'New Zealand',
            NI: 'Nicaragua',
            NE: 'Niger (the)',
            NG: 'Nigeria',
            NU: 'Niue',
            NF: 'Norfolk Island',
            MP: 'Northern Mariana Islands (the)',
            NO: 'Norway',
            OM: 'Oman',
            PK: 'Pakistan',
            PW: 'Palau',
            PS: 'Palestine, State of',
            PA: 'Panama',
            PG: 'Papua New Guinea',
            PY: 'Paraguay',
            PE: 'Peru',
            PH: 'Philippines (the)',
            PN: 'Pitcairn',
            PL: 'Poland',
            PT: 'Portugal',
            PR: 'Puerto Rico',
            QA: 'Qatar',
            MK: 'Republic of North Macedonia',
            RO: 'Romania',
            RU: 'Russian Federation (the)',
            RW: 'Rwanda',
            RE: 'Réunion',
            BL: 'Saint Barthélemy',
            SH: 'Saint Helena, Ascension and Tristan da Cunha',
            KN: 'Saint Kitts and Nevis',
            LC: 'Saint Lucia',
            MF: 'Saint Martin (French part)',
            PM: 'Saint Pierre and Miquelon',
            VC: 'Saint Vincent and the Grenadines',
            WS: 'Samoa',
            SM: 'San Marino',
            ST: 'Sao Tome and Principe',
            SA: 'Saudi Arabia',
            SN: 'Senegal',
            RS: 'Serbia',
            SC: 'Seychelles',
            SL: 'Sierra Leone',
            SG: 'Singapore',
            SX: 'Sint Maarten (Dutch part)',
            SK: 'Slovakia',
            SI: 'Slovenia',
            SB: 'Solomon Islands',
            SO: 'Somalia',
            ZA: 'South Africa',
            GS: 'South Georgia and the South Sandwich Islands',
            SS: 'South Sudan',
            ES: 'Spain',
            LK: 'Sri Lanka',
            SD: 'Sudan (the)',
            SR: 'Suriname',
            SJ: 'Svalbard and Jan Mayen',
            SE: 'Sweden',
            CH: 'Switzerland',
            SY: 'Syrian Arab Republic',
            TW: 'Taiwan (Province of China)',
            TJ: 'Tajikistan',
            TZ: 'Tanzania, United Republic of',
            TH: 'Thailand',
            TL: 'Timor-Leste',
            TG: 'Togo',
            TK: 'Tokelau',
            TO: 'Tonga',
            TT: 'Trinidad and Tobago',
            TN: 'Tunisia',
            TR: 'Turkey',
            TM: 'Turkmenistan',
            TC: 'Turks and Caicos Islands (the)',
            TV: 'Tuvalu',
            UG: 'Uganda',
            UA: 'Ukraine',
            AE: 'United Arab Emirates (the)',
            GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
            UM: 'United States Minor Outlying Islands (the)',
            US: 'United States of America (the)',
            UY: 'Uruguay',
            UZ: 'Uzbekistan',
            VU: 'Vanuatu',
            VE: 'Venezuela (Bolivarian Republic of)',
            VN: 'Viet Nam',
            VG: 'Virgin Islands (British)',
            VI: 'Virgin Islands (U.S.)',
            WF: 'Wallis and Futuna',
            EH: 'Western Sahara',
            YE: 'Yemen',
            ZM: 'Zambia',
            ZW: 'Zimbabwe',
            AX: 'Åland Islands',
        },
        service_type: {
            language: 'Language',
            relocation: 'Relocation',
            family: 'Family',
            housing: 'Housing',
            buddy: 'Buddy',
        },
        organization_size: {
            tiny: 'Tiny',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
            huge: 'Huge',
        },
    },
    alerts: {
        noAccessError: 'You are not authorized to login to panel.',
        baseError: 'Something went wrong. Please try again later.',
        authError: 'The username or password you entered is incorrect. Please try again',
        loginError: 'An error occurred while logging in',
        tryAgain: 'Please try again',
        tokenRefreshed: 'Your session has been renewed',
        unknown: 'Unknown error occurred.',
    },
    accessibilityMenu: {
        title: 'Accessibility Menu',
        openAccessibilityMenu: 'Open accessibility menu',
        closeAccessibilityMenu: 'Close accessibility menu',
        contrastMode: {
            title: 'Contrast Mode {{mode}}',
            toggleButton: 'Toggle contrast mode',
            on: 'on',
            off: 'off',
        },
        fontSize: {
            title: 'Font Size',
            increase: 'Increase Font Size',
            decrease: 'Decrease Font Size',
        },
    },
};

export default en;
