import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Form, FormControlChangeType, IFormConfig, Loader, LoaderType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {offerApplicationFormConfig} from './offerApplicationFormConfig';
import {
    addOfferToApplied,
    IOfferListItem,
    OffersListType,
    OfferStatus,
    removeOfferFromApplied,
    changeIsOfferActionComplete,
} from '../../../../store/reducers/offersPageSlice';
import {connect} from 'react-redux';
import {isOfferActionCompleteSelector, offersErrorSelector} from '../../../../store/selectors/offersPageSelectors';
import {RootState} from '../../../../store/reducers';

interface IConnectedOfferApplicationModalProps {
    readonly isOfferActionComplete: boolean;
    readonly offersError: string | null;
    readonly addOfferToApplied: typeof addOfferToApplied;
    readonly removeOfferFromApplied: typeof removeOfferFromApplied;
    readonly changeIsOfferActionComplete: typeof changeIsOfferActionComplete;
}

interface IExternalOfferApplicationModalProps {
    isModalOpen: boolean;
    toggleModal: (item?: IOfferListItem | null) => void;
    offer: IOfferListItem | null;
    listType?: OffersListType;
}

interface IOfferApplicationModalProps extends IConnectedOfferApplicationModalProps, IExternalOfferApplicationModalProps {}

interface IOfferApplicationModalState {
    value: any;
    formConfig: typeof IFormConfig | null;
    isLoading: boolean;
}

class OfferApplicationModal extends Component<IOfferApplicationModalProps, IOfferApplicationModalState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IOfferApplicationModalProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: offerApplicationFormConfig(),
            isLoading: false,
        };
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IOfferApplicationModalProps>) {
        if (this.props.isOfferActionComplete !== prevProps.isOfferActionComplete && this.props.isOfferActionComplete) {
            this.props.toggleModal();
        }

        if (this.props.offersError !== prevProps.offersError && this.props.offersError) {
            this.setState({isLoading: false});
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.props.changeIsOfferActionComplete(false);
    }

    render() {
        const isOfferApplied =
            this.props.offer?.status === OfferStatus.APPLIED ||
            this.props.offer?.status === OfferStatus.SCHEDULED ||
            this.props.listType === OffersListType.APPLICATIONS;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader className="custom-header" toggle={() => this.props.toggleModal()} />
                {isOfferApplied ? this.renderRemoveApplicationContent() : this.renderApplyModalContent()}

                <Loader type={LoaderType.Local} showLoader={this.state.isLoading} />
            </Modal>
        );
    }

    private renderApplyModalContent = () => {
        return (
            <ModalBody>
                <p className="modal-title application-title">
                    <Translation text="offers.offerView.applicationForm.title" />
                </p>

                <p className="modal-description">
                    <Translation text="offers.offerView.applicationForm.description" />
                </p>
                {this.state.formConfig && (
                    <Form
                        config={this.state.formConfig}
                        onValueStateChange={this.onValueStateChange}
                        value={this.state.value}
                        submitForm={() => this.apply(this.props.offer?.offer.id)}
                        controlName={'offerApplicationForm'}
                    />
                )}
            </ModalBody>
        );
    };

    private renderRemoveApplicationContent = () => {
        return (
            <ModalBody>
                <p className="modal-title">
                    <Translation text="offers.offerView.applicationForm.removeApplicationTitle" />
                </p>

                <p className="modal-description">
                    <Translation text="offers.offerView.applicationForm.removeApplicationDescription" />
                </p>
                <div className="d-flex justify-content-center align-items-center">
                    <Button color="primary me-2" onClick={() => this.removeOfferFromApplied(this.props.offer?.offer.id)}>
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.yes" />
                        </span>
                    </Button>
                    <Button outline color="secondary" onClick={() => this.props.toggleModal()}>
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.no" />
                        </span>
                    </Button>
                </div>
            </ModalBody>
        );
    };

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private apply = (offerId: string | undefined) => {
        this.setState({isLoading: true});
        return undefined === offerId ? null : this.props.addOfferToApplied(offerId);
    };

    private removeOfferFromApplied = (offerId: string | undefined) => {
        return undefined === offerId ? null : this.props.removeOfferFromApplied(offerId);
    };
}

export default connect(
    (state: RootState) => ({
        isOfferActionComplete: isOfferActionCompleteSelector(state),
        offersError: offersErrorSelector(state),
    }),
    {
        addOfferToApplied,
        removeOfferFromApplied,
        changeIsOfferActionComplete,
    }
)(OfferApplicationModal);
