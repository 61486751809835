import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {RootState} from '../reducers';
import {
    addAlert,
    AlertType,
    authTokenSelector,
    IModelCity,
    IModelDictionaryDatum,
    getErrorMessage,
    mapResponseAccountToCandidateFullInfo,
    setAccountState,
    authAccountIdSelector,
} from 'jobhunter-common-web';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {
    IUpdateAccountPayload,
    IUpdateCandidate,
    IUpdateCandidatePreferences,
    IUpdateCareerEntry,
    IUpdateExternalLink,
    IUpdateLanguageEntry,
    IUpdateTechnologyEntry,
    updateFullAccountDataAPI,
} from '../../api/updateFullAccountData';
import {
    addLanguageTest,
    addTechnologyTest,
    changeCareerData,
    changeCvData,
    changeExternalLinksData,
    changeIsProfileLoading,
    changeLanguageSkillsData,
    changeProfileError,
    changeTechnologySkillsData,
    fetchProfileAccountData,
    IAddLanguageTest,
    IAddTechnologyTest,
    ICandidatePreferences,
    ICareerEntry,
    IExternalLink,
    ILanguageEntry,
    ISetProfileData,
    ITechnologyEntry,
    removeCareerEntryData,
    removeLanguageSkillData,
    removeTechnologySkillData,
    setProfileData,
    updateProfilePage,
} from '../reducers/profilePageSlice';
import {PayloadAction} from '@reduxjs/toolkit';
import {getAccountDataAPI} from '../../api/getAccountData';
import {
    profileCareerSelector,
    profileCvSelector,
    profileExternalLinksSelector,
    profilePreferencesSelector,
    profileSkillsSelector,
} from '../selectors/profilePageSelectors';
import {addLanguageTestResultAPI} from '../../api/addLanguageTestResultAPI';
import {addTechnologyTestResultAPI} from '../../api/addTechnologyTestResultAPI';

export interface IProfileUpdatePayload {
    careerEntries?: ICareerEntry[] | null;
    technologySkillEntries?: ITechnologyEntry[] | null;
    languageEntries?: ILanguageEntry[] | null;
    externalLinks?: IUpdateExternalLink[] | null;
    cvId?: string;
    technologies?: string;
    cities?: string;
    companyTypes?: string;
    industries?: string;
    contractTypes?: string;
    employmentTypes?: string;
    workTypes?: string;
    minimumSalary?: string;
    relocationOnly?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    birthDate?: string;
    city?: string;
    country?: string;
    description?: string;
    avatar?: string;
}

const updateProfilePageEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(updateProfilePage.type),
        switchMap((action: PayloadAction<{[key: string]: any}>): any => {
            return updateAccountData(state$, action.payload);
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const updateCareersEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeCareerData.type, removeCareerEntryData.type),
        switchMap((): any => {
            const updatedCareers = profileCareerSelector(state$.value),
                careerPayload = {
                    careerEntries: updatedCareers,
                };

            return updateAccountData(state$, careerPayload);
        }),
        switchMap(() => of(addAlert({message: 'profile.career.careersUpdated'}), fetchProfileAccountData())),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const updateTechnologyEntriesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeTechnologySkillsData.type, removeTechnologySkillData.type),
        switchMap((): any => {
            const updatedSkills = profileSkillsSelector(state$.value),
                technologiesPayload = {
                    technologySkillEntries: updatedSkills?.technologySkills,
                };

            return updateAccountData(state$, technologiesPayload);
        }),
        switchMap(() => of(addAlert({message: 'profile.career.addTechnology.technologiesUpdated'}), fetchProfileAccountData())),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const updateLanguageEntriesEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeLanguageSkillsData.type, removeLanguageSkillData.type),
        switchMap((): any => {
            const updatedSkills = profileSkillsSelector(state$.value),
                languagesPayload = {
                    languageEntries: updatedSkills?.languageSkills,
                };

            return updateAccountData(state$, languagesPayload);
        }),
        switchMap(() => of(addAlert({message: 'profile.career.addLanguage.languagesUpdated'}), fetchProfileAccountData())),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const updateExternalLinksEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeExternalLinksData.type),
        switchMap((): any => {
            const updatedExternalLinks = profileExternalLinksSelector(state$.value),
                externalLinks = updatedExternalLinks?.map((linkItem: IExternalLink) => {
                    const {link, ...rest} = linkItem;

                    return {...rest, url: link};
                }),
                payload = {
                    externalLinks: externalLinks,
                };

            return updateAccountData(state$, payload);
        }),
        switchMap(() => of(addAlert({message: 'profile.career.socialLinks.socialLinksUpdated'}), fetchProfileAccountData())),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const updateCvEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(changeCvData.type),
        switchMap((): any => {
            const updatedCv = profileCvSelector(state$.value),
                cvPayload = {
                    cvId: updatedCv ? updatedCv.id : null,
                };

            return updateAccountData(state$, cvPayload);
        }),
        switchMap(() => of(addAlert({message: 'profile.career.socialLinks.cvUpdated'}), fetchProfileAccountData())),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const setProfileDataEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(fetchProfileAccountData.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                accountId = authAccountIdSelector(state$.value);
            if (accountId === null) {
                return of();
            }

            return getAccountDataAPI(authToken, accountId).pipe(
                mergeMap((response: any) => updateAccountSuccessActions(response)),
                catchError((error: any) => {
                    return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
                })
            );
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const addLanguageTestEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(addLanguageTest.type),
        switchMap((action: PayloadAction<IAddLanguageTest>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = {languageId: action.payload.languageId};
            return addLanguageTestResultAPI(authToken, payload).pipe(
                switchMap(() => of(addAlert({message: 'profile.career.addTechnology.testEmailSent'}))),
                catchError((error) => {
                    return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
                })
            );
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

const addTechnologyTestEpic: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        ofType(addTechnologyTest.type),
        switchMap((action: PayloadAction<IAddTechnologyTest>): any => {
            const authToken = authTokenSelector(state$.value),
                payload = {technologyId: action.payload.technologyId};
            return addTechnologyTestResultAPI(authToken, payload).pipe(
                switchMap(() => of(addAlert({message: 'profile.career.addTechnology.testEmailSent'}))),
                catchError((error) => {
                    return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
                })
            );
        }),
        catchError((error) => {
            return of(addAlert({message: getErrorMessage(error), type: AlertType.WARNING}), changeIsProfileLoading(false));
        })
    );

// const fetchListSuccessActions = (resp: any, changeSliceList: any, changeIsSliceLoading: any, changeIsSliceInitialized: any): any[] => {
//     return [changeSliceList(resp), changeIsSliceLoading(false), changeIsSliceInitialized(true)];
// };

const updateAccountData = (state$: StateObservable<RootState>, payload: {[key: string]: any}) => {
    const authToken = authTokenSelector(state$.value),
        accountId = authAccountIdSelector(state$.value);
    if (accountId === null) {
        return;
    }
    const convertedPayload = mapDataToAccountPayload(payload, state$);

    return updateFullAccountDataAPI(authToken, accountId, convertedPayload).pipe(
        switchMap((response: any) => updateAccountSuccessActions(response, [addAlert({message: 'profile.profileForm.profileUpdated'})])),
        catchError((error: any) => {
            return of(...fetchListErrorActions(error, changeProfileError, changeIsProfileLoading));
        })
    );
};

const mapDataToAccountPayload = (payload: {[key: string]: any}, state: StateObservable<RootState>): IUpdateAccountPayload => {
    const candidatePreferences = profilePreferencesSelector(state.value),
        candidatePayload: IUpdateCandidate = {
            candidatePreferences: mapToCandidatePreferencesPayload(payload, candidatePreferences),
        },
        profilePayload: IUpdateAccountPayload = {
            candidate: candidatePayload,
        };

    // candidate
    if (payload.careerEntries) {
        candidatePayload.careerEntries = mapToUpdateCareerEntry(payload.careerEntries);
    }

    if (payload.technologySkillEntries) {
        candidatePayload.technologySkillEntries = mapToUpdateTechnologyEntry(payload.technologySkillEntries);
    }

    if (payload.languageEntries) {
        candidatePayload.languageEntries = mapToUpdateLanguageEntry(payload.languageEntries);
    }

    if (payload.externalLinks) {
        candidatePayload.externalLinks = payload.externalLinks;
    }

    if (payload.hasOwnProperty('cvId')) {
        candidatePayload.cvId = payload.cvId;
    }

    // general data
    if (payload.firstName) {
        profilePayload.firstName = payload.firstName;
    }
    if (payload.lastName) {
        profilePayload.lastName = payload.lastName;
    }
    if (payload.phone) {
        profilePayload.phone = payload.phone;
    }
    if (payload.birthDate) {
        profilePayload.birthDate = new Date(payload.birthDate).toISOString();
    }

    if (payload.city) {
        profilePayload.cityName = payload.city;
    }

    if (payload.country) {
        profilePayload.countryId = payload.country;
    }

    if (payload.description) {
        profilePayload.about = payload.description;
    }

    if (payload.avatar) {
        profilePayload.avatar = payload.avatar;
    }

    return profilePayload;
};

const mapToCandidatePreferencesPayload = (
    payload: {[key: string]: any},
    candidatePreferences: ICandidatePreferences | null
): IUpdateCandidatePreferences => {
    return {
        technologies: payload.technologies ? payload.technologies : mapDictionaryDatumToId(candidatePreferences?.technologies),
        cities: payload.cities ? payload.cities : mapDictionaryDatumToId(candidatePreferences?.cities),
        companyTypes: payload.companyTypes ? payload.companyTypes : mapDictionaryDatumToId(candidatePreferences?.companyTypes),
        industries: payload.industries ? payload.industries : mapDictionaryDatumToId(candidatePreferences?.industries),
        contractTypes: payload.contractTypes ? payload.contractTypes : mapDictionaryDatumToId(candidatePreferences?.contractTypes),
        employmentTypes: payload.employmentTypes ? payload.employmentTypes : mapDictionaryDatumToId(candidatePreferences?.employmentTypes),
        workTypes: payload.workTypes ? payload.workTypes : mapDictionaryDatumToId(candidatePreferences?.workTypes),
        minimumSalary: payload.minimumSalary ? payload.minimumSalary : candidatePreferences?.minimumSalary,
        relocationOnly: payload.relocationOnly ? payload.relocationOnly : candidatePreferences?.relocationOnly,
        preferenceTags: payload.preferenceTags ? payload.preferenceTags : mapDictionaryDatumToId(candidatePreferences?.preferenceTags),
    };
};

const mapToUpdateCareerEntry = (careerEntries: ICareerEntry[]): IUpdateCareerEntry[] => {
    return careerEntries.map((careerEntry) => {
        return {
            from: careerEntry.from,
            to: careerEntry.to,
            company: careerEntry.company,
            position: careerEntry.position,
            seniorityId: careerEntry.seniority.id,
            id: careerEntry?.id,
        };
    });
};

const mapToUpdateTechnologyEntry = (technologyEntries: ITechnologyEntry[]): IUpdateTechnologyEntry[] => {
    return technologyEntries.map((technologyEntry) => {
        return {
            technologyId: technologyEntry.technology.id,
            technologyTools: technologyEntry.technologyTool.map((tool) => tool.id),
            seniorityId: technologyEntry.seniority.id,
            id: technologyEntry?.id,
        };
    });
};

const mapToUpdateLanguageEntry = (languageEntries: ILanguageEntry[]): IUpdateLanguageEntry[] => {
    return languageEntries.map((languageEntry) => {
        return {
            languageId: languageEntry.language.id,
            languageLevelId: languageEntry.languageLevel.id,
        };
    });
};

const mapDictionaryDatumToId = (preferences: typeof IModelDictionaryDatum[] | typeof IModelCity[] | null | undefined): string[] | [] => {
    if (preferences === null || preferences === undefined) {
        return [];
    }

    return preferences.map((preference) => {
        return preference.id;
    });
};

const mapDataFromServerToAccount = (account: {[key: string]: any}): ISetProfileData => {
    const candidateFullInfo = account.candidateFullInfo,
        candidateAccount = candidateFullInfo.account,
        candidatePreferences = candidateFullInfo?.preferences;

    return {
        candidateAccount: {
            firstName: candidateAccount?.firstName,
            lastName: candidateAccount?.lastName,
            phone: candidateAccount?.phone,
            birthDate: candidateAccount?.birthDate,
            cityName: candidateAccount?.cityName,
            country: candidateAccount.country
                ? {
                      id: candidateAccount?.country?.id,
                      name: candidateAccount?.country?.name,
                  }
                : null,
            about: candidateAccount?.about,
            avatar: {
                contentUrl: candidateAccount?.avatar?.contentUrl,
                fileUrls: candidateAccount?.avatar?.fileUrls,
                id: candidateAccount?.avatar?.id,
            },
            id: candidateAccount?.id,
            locale: candidateAccount?.locale,
            filled: candidateAccount?.filled,
        },
        career: candidateFullInfo.careers,
        cv: {
            contentUrl: candidateFullInfo?.cv?.contentUrl,
            fileUrls: candidateFullInfo?.cv?.fileUrls,
            id: candidateFullInfo?.cv?.id,
        },
        skills: {
            technologySkills: candidateFullInfo.technologies,
            languageSkills: candidateFullInfo.languages,
        },
        externalLinks: candidateFullInfo.externalLinks,
        preferences: {
            technologies: candidatePreferences.technologies.map((technology: typeof IModelDictionaryDatum) => {
                return {id: technology.id, name: technology.name};
            }),
            cities: candidatePreferences.cities.map((city: typeof IModelCity) => {
                return {
                    id: city.id,
                    name: city.name,
                    country: city.country,
                };
            }),
            companyTypes: candidatePreferences.companyTypes.map((type: typeof IModelDictionaryDatum) => {
                return {id: type.id, name: type.name};
            }),
            industries: candidatePreferences.industries.map((industry: typeof IModelDictionaryDatum) => {
                return {id: industry.id, name: industry.name};
            }),
            contractTypes: candidatePreferences.contractTypes.map((type: typeof IModelDictionaryDatum) => {
                return {id: type.id, name: type.name};
            }),
            employmentTypes: candidatePreferences.employmentTypes.map((type: typeof IModelDictionaryDatum) => {
                return {id: type.id, name: type.name};
            }), // full_time, part_time
            workTypes: candidatePreferences.workTypes.map((type: typeof IModelDictionaryDatum) => {
                return {id: type.id, name: type.name};
            }), // remote, part_remote, stationary
            minimumSalary: candidatePreferences.minimumSalary,
            relocationOnly: candidatePreferences.relocationOnly,
            preferenceTags: candidatePreferences.preferenceTags.map((tag: typeof IModelDictionaryDatum) => {
                return {id: tag.id, name: tag.name};
            }),
        },
        technologyTestResults: candidateFullInfo.technologyTestResults,
        languageTestResults: candidateFullInfo.languageTestResults,
    };
};

export const updateAccountSuccessActions = (response: any, successActions?: any[]): Observable<any> => {
    const preferencesAccount = mapDataFromServerToAccount(response),
        account = {
            candidateFullInfo: mapResponseAccountToCandidateFullInfo(response),
            organizationFullInfo: null,
        };

    let actions = [
        setProfileData(
            preferencesAccount.candidateAccount,
            preferencesAccount.career,
            preferencesAccount.cv,
            preferencesAccount.skills,
            preferencesAccount.externalLinks,
            preferencesAccount.preferences,
            preferencesAccount.technologyTestResults,
            preferencesAccount.languageTestResults
        ),
        changeIsProfileLoading(false),
        setAccountState(account),
    ];
    if (successActions) {
        actions = actions.concat(successActions);
    }
    return of(...actions);
};

export const fetchListErrorActions = (error: any, setSliceError: any, setSliceIsLoading: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        setSliceError(getErrorMessage(error)),
        setSliceIsLoading(false),
    ];
};

const dictionaryDataEpic = combineEpics(
    updateProfilePageEpic,
    setProfileDataEpic,
    addLanguageTestEpic,
    addTechnologyTestEpic,
    updateCareersEpic,
    updateTechnologyEntriesEpic,
    updateLanguageEntriesEpic,
    updateExternalLinksEpic,
    updateCvEpic
);

export default dictionaryDataEpic;
