import React, {Component} from 'react';
import {
    AccountImageHost,
    accountSelector,
    authTokenSelector,
    citiesSelector,
    convertToMultiselectLabels,
    countriesSelector,
    Form,
    FormControlChangeType,
    IFileOutput,
    IFormConfig,
    IModelCity,
    IModelDictionaryDatum,
    isSameValue,
    sortMultiselectLabels,
    Translation,
} from 'jobhunter-common-web';
import {personalInformationFormConfig} from './personalInformationFormConfig';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {Button} from 'reactstrap';
import {ArrowLeft, Save} from 'react-feather';
import {ICandidateAccount, updateProfilePage} from '../../../store/reducers/profilePageSlice';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {profileCandidateAccountSelector} from '../../../store/selectors/profilePageSelectors';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IConnectedPersonalInformationProps {
    readonly cities: typeof IModelCity[] | null;
    readonly countries: typeof IModelDictionaryDatum[] | null;
    readonly authToken: string | null;
    readonly account: any;
    readonly candidateProfile: ICandidateAccount | null;
    readonly updateProfilePage: typeof updateProfilePage;
}

interface IExternalPersonalInformationProps {
    stepper: any;
}

interface IPersonalInformationProps extends IConnectedPersonalInformationProps, IExternalPersonalInformationProps, WithTranslation {}

interface IPersonalInformationState {
    value: any;
    formConfig: typeof IFormConfig | null;
}

class PersonalInformation extends Component<IPersonalInformationProps, IPersonalInformationState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IPersonalInformationProps) {
        super(props);

        this.state = {
            value: null,
            formConfig: null,
        };
    }

    componentDidMount(): void {
        if (this.props.candidateProfile) {
            this.setFormValues();
        }

        this.setFormConfig();

        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    debounceTime(500),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentDidUpdate(prevProps: Readonly<IPersonalInformationProps>): void {
        if (!isSameValue(this.props.countries, prevProps.countries) || !isSameValue(this.props.cities, prevProps.cities)) {
            this.setFormConfig();
        }

        if (!isSameValue(this.props.candidateProfile, prevProps.candidateProfile) && this.props.candidateProfile) {
            this.setFormValues();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        return (
            <div className="wizard-content">
                <div className="personal-information-section">
                    <div className="header">
                        <div>
                            <p className="title">
                                <Translation text="profile.personal.personalInformation" />
                            </p>
                            <p className="sub-title">
                                <Translation text="profile.personal.enterInformation" />
                            </p>
                        </div>
                    </div>

                    <div className="image-upload-host">
                        <AccountImageHost imageUploadAction={(file: typeof IFileOutput) => this.changePersonalData({avatar: file})} />
                    </div>
                </div>

                <div>
                    {this.state.formConfig && (
                        <Form
                            config={this.state.formConfig}
                            onValueStateChange={this.onValueStateChange}
                            value={this.state.value}
                            controlName={'personalInformationForm'}
                        />
                    )}
                </div>

                <div className="wizard-actions">
                    <Button color="secondary" className="btn-prev personal-prev-button" outline disabled>
                        <ArrowLeft size={14} className="align-middle me-sm-25 me-0" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.previous" />
                        </span>
                    </Button>
                    <Button
                        color="primary"
                        className="btn-next personal-next-button"
                        onClick={() => {
                            this.changePersonalData(this.state.value);
                        }}>
                        <Save size={14} className="align-middle ms-sm-25 me-25" />
                        <span className="align-middle d-sm-inline-block d-none">
                            <Translation text="buttons.save" />
                        </span>
                    </Button>
                </div>
            </div>
        );
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private changePersonalData = (data: {[key: string]: any}) => {
        if (data.avatar) {
            data.avatar = data.avatar.id;
        }

        if (data.birthDate) {
            data.birthDate = new Date(data.birthDate).toISOString();
        }

        this.props.updateProfilePage(data);
    };

    private setFormValues = () => {
        const candidateProfile = this.props.candidateProfile,
            values = {
                firstName: candidateProfile?.firstName,
                lastName: candidateProfile?.lastName,
                phone: candidateProfile?.phone,
                birthDate: candidateProfile?.birthDate,
                city: candidateProfile?.cityName,
                country: candidateProfile?.country?.id,
                description: candidateProfile?.about,
            };

        this.setState({value: values});
    };

    private setFormConfig = () => {
        const {t} = this.props,
            countries = sortMultiselectLabels(convertToMultiselectLabels(this.props.countries, t)),
            cities = sortMultiselectLabels(convertToMultiselectLabels(this.props.cities, t));

        const formConfig = personalInformationFormConfig(countries, cities);
        this.setState({formConfig});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        account: accountSelector(state),
        candidateProfile: profileCandidateAccountSelector(state),
        cities: citiesSelector(state),
        countries: countriesSelector(state),
    }),
    {
        updateProfilePage,
    }
)(withTranslation()(PersonalInformation));
