import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    IFileOutput,
    IModelApiResponseViewObject,
    IModelCity,
    IModelDictionaryDatum,
    IModelSeniority,
    isSameValue,
} from 'jobhunter-common-web';
import {IModelApiObject} from '../../model/base';
import {IOfferConsultation} from '../../model/offer';

export enum OfferStatus {
    ACTIVE = 'active',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
    APPLIED = 'applied',
    SCHEDULED = 'scheduled',
    HIRED = 'hired',
}

export interface IOffer extends IModelApiObject {
    id: string;
    avatar: typeof IFileOutput | null;
    name: string;
    seniority: typeof IModelSeniority;
    city: typeof IModelCity;
    minimumSalary: string;
    maximumSalary: string;
    publishAt: string;
    workTypes: typeof IModelDictionaryDatum[];
    relocation: boolean;
    industries: typeof IModelDictionaryDatum[];
    companyTypes: typeof IModelDictionaryDatum[];
    contractTypes: typeof IModelDictionaryDatum[];
    employmentTypes: typeof IModelDictionaryDatum[];
    organizationSize: typeof IModelDictionaryDatum[];
    positionId: string | null;
    positionName: string | null;
    preferenceTags: typeof IModelDictionaryDatum[];
    soughtPositionId: string | null;
}

export interface IOfferListItem extends IModelApiObject {
    offer: IOffer;
    observed?: boolean;
    status?: OfferStatus;
    consultation?: IOfferConsultation | null;
}

export interface IOfferDescription extends IModelApiObject {
    description: string;
    id: string;
}

export interface IOfferTechnology extends IModelApiObject {
    id: string;
    seniority: typeof IModelSeniority;
    technology: typeof IModelDictionaryDatum;
}

export interface IOfferTechnologyTool extends IModelApiObject {
    id: string;
    seniority: typeof IModelSeniority;
    technologyTool: typeof IModelDictionaryDatum;
}

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export enum OffersListType {
    OFFERS = 'offers',
    APPLICATIONS = 'applications',
    RECRUITMENT = 'recruitment',
    OBSERVED = 'observed',
}

export interface IOfferFilters {
    offerTechnologies: {
        technology: {
            id: string[];
        };
    } | null;
    seniority: {
        id: string[];
    } | null;
    contractTypes: {
        id: string[];
    } | null;
    industries: {
        id: string[];
    } | null;
    companyTypes: {
        id: string[];
    } | null;
    workTypes: {
        id: string[];
    } | null;
    employmentTypes: {
        id: string[];
    } | null;
    minimumSalary: string | null;
    relocation: boolean;
    name?: string;
    best_match: boolean | null;
    exists?: {
        soughtPosition: boolean;
    } | null;
    soughtPosition?: {
        id: string[];
    } | null;
    offer?: {
        name?: string;
    } | null;
}

export interface IOffersState {
    filters: IOfferFilters | null;
    pagination: IPaginationParams | null;
    offersMetadata: typeof IModelApiResponseViewObject | null;
    offersList: IOfferListItem[] | null;
    searchId: string | null;
    offersListType: OffersListType;
    isOfferListLoading: boolean;
    isOffersInitialized: boolean;
    isOfferActionComplete: boolean;
    offersError: string | null;
}

export interface ISetOffersList {
    offersList: IOfferListItem[] | null;
}

export interface IChangeIsOffersLoading {
    isOfferListLoading: boolean;
}

export interface IChangeIsOffersInitialized {
    isOffersInitialized: boolean;
}

export interface IChangeOffersError {
    error: string | null;
}

export interface IChangeOffersFilters {
    filters: IOfferFilters | null;
}

export interface IChangeOffersListType {
    listType: OffersListType;
}

export interface IChangeOfferAction {
    offerId: string;
}

export interface ISetOffersMetadata {
    offersMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangeOffersPagination {
    pagination: IPaginationParams;
}

export interface IChangeSearchId {
    searchId: string | null;
}

export interface IChangeIsOfferActionComplete {
    isOfferActionComplete: boolean;
}

const initialState: IOffersState = {
    filters: null,
    offersList: null,
    pagination: {
        itemsPerPage: 10,
        page: 1,
    },
    offersMetadata: null,
    searchId: null,
    offersListType: OffersListType.OFFERS,
    isOfferListLoading: false,
    isOffersInitialized: false,
    isOfferActionComplete: false,
    offersError: null,
};

const offersPageSlice = createSlice({
    name: 'offers',
    initialState: initialState,
    reducers: {
        setOffersData: {
            reducer: (state: IOffersState, action: PayloadAction<ISetOffersList>) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                    offersList: action.payload.offersList,
                };
            },
            prepare(offersList: IOfferListItem[]) {
                return {
                    payload: {
                        offersList: offersList,
                    },
                };
            },
        },
        applyOffersFilters: (state: IOffersState) => {
            return {
                ...state,
                isOfferListLoading: true,
            };
        },
        resetOffersFiltersToDefaultAccountFilters: (state: IOffersState) => {
            return {
                ...state,
                isOfferListLoading: true,
            };
        },
        changeOffersFilters: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeOffersFilters>) => {
                if (isSameValue(action.payload.filters, state.filters)) {
                    return {
                        ...state,
                    };
                }

                return {
                    ...state,
                    isOfferListLoading: true,
                    filters: action.payload.filters,
                };
            },
            prepare(filters: IOfferFilters | null) {
                return {
                    payload: {
                        filters: filters
                            ? {
                                  offerTechnologies: filters.offerTechnologies,
                                  seniority: filters.seniority,
                                  contractTypes: filters.contractTypes,
                                  industries: filters.industries,
                                  companyTypes: filters.companyTypes,
                                  workTypes: filters.workTypes,
                                  employmentTypes: filters.employmentTypes,
                                  soughtPosition: filters.soughtPosition,
                                  minimumSalary: filters.minimumSalary,
                                  relocation: filters.relocation,
                                  name: filters.name,
                                  best_match: filters.best_match,
                                  exists: filters.exists,
                                  offer: filters.offer,
                              }
                            : null,
                    },
                };
            },
        },
        changeIsOffersLoading: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeIsOffersLoading>) => {
                if (action.payload.isOfferListLoading === state.isOfferListLoading) {
                    return state;
                }

                return {
                    ...state,
                    isOfferListLoading: action.payload.isOfferListLoading,
                };
            },
            prepare(isOfferListLoading: boolean) {
                return {
                    payload: {isOfferListLoading: isOfferListLoading},
                };
            },
        },
        changeIsOffersInitialized: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeIsOffersInitialized>) => {
                return {
                    ...state,
                    isOffersInitialized: action.payload.isOffersInitialized,
                };
            },
            prepare(isOffersInitialized: boolean) {
                return {
                    payload: {isOffersInitialized: isOffersInitialized},
                };
            },
        },
        changeOffersError: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeOffersError>) => {
                return {
                    ...state,
                    offersError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        updateOffersPage: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        changeOffersListType: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeOffersListType>) => {
                return {
                    ...state,
                    offersListType: action.payload.listType,
                    isOfferListLoading: true,
                    pagination: initialState.pagination,
                };
            },
            prepare(listType: OffersListType) {
                return {
                    payload: {listType: listType},
                };
            },
        },
        fetchListData: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                };
            },
            prepare(listType: OffersListType) {
                return {
                    payload: {listType: listType},
                };
            },
        },
        addOfferToObserved: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        addOfferToApplied: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        removeOfferFromObserved: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        removeOfferFromApplied: {
            reducer: (state: IOffersState) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                };
            },
            prepare(offerId: string) {
                return {
                    payload: {offerId: offerId},
                };
            },
        },
        changeIsOfferActionComplete: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeIsOfferActionComplete>) => {
                return {
                    ...state,
                    isOfferActionComplete: action.payload.isOfferActionComplete,
                };
            },
            prepare(isOfferActionComplete: boolean) {
                return {
                    payload: {isOfferActionComplete: isOfferActionComplete},
                };
            },
        },
        setOffersMetadata: {
            reducer: (state: IOffersState, action: PayloadAction<ISetOffersMetadata>) => {
                return {
                    ...state,
                    offersMetadata: action.payload.offersMetadata,
                };
            },
            prepare(offersMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        offersMetadata: offersMetadata,
                    },
                };
            },
        },
        changeOffersPagination: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeOffersPagination>) => {
                return {
                    ...state,
                    isOfferListLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        changeOffersSearchId: {
            reducer: (state: IOffersState, action: PayloadAction<IChangeSearchId>) => {
                return {
                    ...state,
                    searchId: action.payload.searchId,
                };
            },
            prepare(searchId: string | null) {
                return {
                    payload: {searchId: searchId},
                };
            },
        },
        resetToInitialOfferPageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setOffersData,
    changeIsOffersLoading,
    changeIsOffersInitialized,
    changeOffersError,
    updateOffersPage,
    applyOffersFilters,
    resetOffersFiltersToDefaultAccountFilters,
    changeOffersFilters,
    changeOffersListType,
    fetchListData,
    addOfferToObserved,
    addOfferToApplied,
    removeOfferFromObserved,
    removeOfferFromApplied,
    changeIsOfferActionComplete,
    setOffersMetadata,
    changeOffersPagination,
    changeOffersSearchId,
    resetToInitialOfferPageState,
} = offersPageSlice.actions;

export default offersPageSlice.reducer;
