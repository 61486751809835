import React from 'react';
import {CheckSquare, Eye, FileText, Grid, Home, Mail, MessageSquare, Shuffle, User} from 'react-feather';
import {ISideMenuGroup, ISideMenuHeader, ISideMenuItem} from 'jobhunter-common-web';

const menu = (): (typeof ISideMenuGroup | typeof ISideMenuItem | typeof ISideMenuHeader)[] => [
    {
        id: 'dashboards',
        title: 'Dashboards',
        icon: <Home size={20} />,
        navLink: '/panel/dashboard',
        badge: 'primary',
        navLinkClassname: 'dashboard-link',
        // badgeText is hidden in current version
        // badgeText: '2',
    },
    {
        id: 'profile',
        title: 'Profile',
        icon: <User size={20} />,
        navLink: '/panel/profile',
        navLinkClassname: 'profile-link',
    },
    {
        id: 'offers',
        title: 'Offers',
        icon: <Mail size={20} />,
        navLink: '/panel/offers',
        navLinkClassname: 'offers-link',
        children: [
            {
                id: 'applications',
                title: 'My applications',
                icon: <MessageSquare size={20} />,
                navLink: '/panel/offers/applications',
                navLinkClassname: 'applications-link',
            },
            {
                id: 'recruitment',
                title: 'My recruitment',
                icon: <CheckSquare size={20} />,
                navLink: '/panel/offers/recruitment',
                navLinkClassname: 'recruitment-link',
            },
            {
                id: 'favourite',
                title: 'Observed',
                icon: <Eye size={20} />,
                navLink: '/panel/offers/observed',
                navLinkClassname: 'observed-link',
            },
        ],
    },
    {
        id: 'marketplace',
        title: 'Marketplace',
        icon: <Grid size={20} />,
        navLink: '/panel/marketplace',
        navLinkClassname: 'marketplace-link',
        children: [
            {
                id: 'services',
                title: 'My services',
                icon: <FileText size={20} />,
                navLink: '/panel/marketplace/services',
                navLinkClassname: 'services-link',
            },
            {
                id: 'chat',
                title: 'Chat',
                icon: <MessageSquare size={20} />,
                navLink: '/panel/marketplace/chat',
                navLinkClassname: 'chat-link',
            },
        ],
    },
    {
        id: 'relocations',
        title: 'Relocations',
        icon: <Shuffle size={20} />,
        navLink: '/panel/relocations',
        navLinkClassname: 'relocations-link',
    },
];
export default menu;
