import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import OffersList, {OffersListType} from '../OffersList';

interface IRecruitmentProps {}

class Recruitment extends Component<IRecruitmentProps, {}> {
    render() {
        return (
            <LayoutWrapper wrapperClass={'app-local-content'}>
                <OffersList type={OffersListType.RECRUITMENT} />
            </LayoutWrapper>
        );
    }
}

export default Recruitment;
