import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {
    ListItemDetails,
    Loader,
    LoaderType,
    Pagination,
    Tooltip,
    Translation,
    WithNavigate,
    withNavigation,
    IModelApiResponseViewObject,
    changeRelocationsPagination,
    fetchRelocations,
    resetToInitialRelocationsPageState,
    isRelocationsPageLoadingSelector,
    relocationsMetadataSelector,
    relocationsSelector,
    DateComponent,
} from 'jobhunter-common-web';
import {Button, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {Search, Shuffle} from 'react-feather';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {IOfferApplication} from '../../store/reducers/dashboardPageSlice';
import {WithTranslation, withTranslation} from 'react-i18next';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedRelocationsProps {
    readonly relocations: IOfferApplication[] | null;
    readonly relocationsMetadata: typeof IModelApiResponseViewObject | null;
    readonly isRelocationsPageLoading: boolean;
    readonly fetchRelocations: typeof fetchRelocations;
    readonly changeRelocationsPagination: typeof changeRelocationsPagination;
    readonly resetToInitialRelocationsPageState: typeof resetToInitialRelocationsPageState;
}

interface IRelocationListProps extends IConnectedRelocationsProps, WithNavigationProps, WithTranslation {}

class RelocationList extends Component<IRelocationListProps> {
    componentDidMount() {
        this.props.fetchRelocations();
    }

    componentWillUnmount() {
        this.props.resetToInitialRelocationsPageState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="panel-section-header d-flex justify-content-between align-items-center">
                    <Translation text="relocations.title" />
                </div>
                <Card className="relocations-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">
                                <div>
                                    <Shuffle size={20} />
                                    <Translation text="relocations.title" />
                                </div>
                            </div>
                        </CardTitle>
                        <Table responsive className="relocations-table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translation text="relocations.table.employer" />
                                    </th>
                                    <th>
                                        <Translation text="relocations.table.hireDate" />
                                    </th>
                                    <th>
                                        <Translation text="relocations.table.status" />
                                    </th>
                                    <th className="text-center">
                                        <Translation text="relocations.table.actions" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{this.renderRelocationsTableRows()}</tbody>
                        </Table>
                    </CardBody>
                </Card>

                <Pagination listMetadata={this.props.relocationsMetadata} changePage={this.props.changeRelocationsPagination} />

                <Loader type={LoaderType.Local} showLoader={this.props.isRelocationsPageLoading} />
            </LayoutWrapper>
        );
    }

    private renderRelocationsTableRows() {
        const {t} = this.props,
            relocations: IOfferApplication[] | null = this.props.relocations;

        if (null === relocations || !relocations.length) {
            return (
                <tr key={'no-data'}>
                    <td colSpan={5}>
                        <p>
                            <Translation text="relocations.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return relocations.map((item: IOfferApplication) => {
            return (
                <tr key={`relocations_${item.offer.id}`}>
                    <td>
                        <ListItemDetails avatar={item.offer.avatar} name={item.offer.name} />
                    </td>
                    <td>
                        <DateComponent date={item.createdAt} format="DD.MM.YYYY" />
                    </td>
                    <td>
                        <span className="align-middle">{item.relocationStatus ? item.relocationStatus : '---'}</span>
                    </td>
                    <td className="text-center">
                        <Button color="flat-primary" className="view-relocation-button" onClick={() => this.viewRelocationDetails(item)}>
                            <Search id={`view_${item.id}`} size={20} />
                            <Tooltip target={`view_${item.id}`} tooltipText={t('relocations.tooltips.view')} />
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    private viewRelocationDetails = (item: IOfferApplication) => {
        this.props.navigate(`/panel/relocations/${item.id}`, {
            state: {relocationStatus: item.relocationStatus, candidateId: item.chatContact?.organizationFullInfo?.user?.id},
        });
    };
}

export default connect(
    (state: RootState) => ({
        relocations: relocationsSelector(state),
        relocationsMetadata: relocationsMetadataSelector(state),
        isRelocationsPageLoading: isRelocationsPageLoadingSelector(state),
    }),
    {
        fetchRelocations,
        changeRelocationsPagination,
        resetToInitialRelocationsPageState,
    }
)(withNavigation(withTranslation()(RelocationList)));
