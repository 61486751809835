import React, {Component} from 'react';
import {Badge, Card, CardBody, CardTitle, Table} from 'reactstrap';
import {File} from 'react-feather';
import {convertToPriceString, ListItemDetails, Loader, LoaderType, Translation, WithNavigate, withNavigation} from 'jobhunter-common-web';
import {IOfferApplication} from '../../../store/reducers/dashboardPageSlice';
import {IOffer, OfferStatus} from '../../../store/reducers/offersPageSlice';

declare type WithNavigationProps = typeof WithNavigate;

interface IConnectedDashboardApplicationsProps {}

interface IExternalDashboardApplicationsProps {
    readonly applications: IOfferApplication[] | null;
    readonly isApplicationsListLoading: boolean;
}

interface IDashboardApplicationsProps
    extends IConnectedDashboardApplicationsProps,
        IExternalDashboardApplicationsProps,
        WithNavigationProps {}

class DashboardApplications extends Component<IDashboardApplicationsProps> {
    render() {
        return (
            <Card className="applications-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header">
                            <div>
                                <File size={20} />
                                <Translation text="dashboard.applications.title" />
                            </div>
                            <button className="btn btn-primary applications-button" onClick={() => this.redirectToApplications()}>
                                <Translation text="buttons.showAll" />
                            </button>
                        </div>
                    </CardTitle>
                    <Table responsive className="applications-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="dashboard.applications.table.headers.project" />
                                </th>
                                <th>
                                    <Translation text="dashboard.applications.table.headers.city" />
                                </th>
                                <th>
                                    <Translation text="dashboard.applications.table.headers.salary" />
                                </th>
                                <th>
                                    <Translation text="dashboard.applications.table.headers.status" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableRows()}</tbody>
                    </Table>
                </CardBody>

                <Loader type={LoaderType.Local} showLoader={this.props.isApplicationsListLoading} />
            </Card>
        );
    }

    private renderTableRows() {
        const applications = this.props.applications;

        if (null === applications || applications.length <= 0) {
            return (
                <tr key="applications_no_data">
                    <td colSpan={4}>
                        <Translation text="dashboard.applications.noData" />
                    </td>
                </tr>
            );
        } else {
            return applications.map((item: IOfferApplication) => {
                const offer: IOffer = item.offer,
                    badgeColor =
                        item.status === OfferStatus.ACTIVE
                            ? 'light-success'
                            : item.status === OfferStatus.APPLIED || item.status === OfferStatus.SCHEDULED
                            ? 'light-info'
                            : 'light-danger';

                return (
                    <tr key={`applications_${offer.id}`}>
                        <td>
                            <ListItemDetails avatar={offer.avatar} name={offer.name} />
                        </td>
                        <td>
                            <Translation text={offer.city.name} />
                        </td>
                        <td>
                            {convertToPriceString(offer.minimumSalary)} - {convertToPriceString(offer.maximumSalary)}
                        </td>
                        <td>
                            <Badge color={badgeColor} pill>
                                <span className="align-middle">{item.status}</span>
                            </Badge>
                        </td>
                    </tr>
                );
            });
        }
    }

    private redirectToApplications = () => {
        this.props.navigate('/panel/offers/applications');
    };
}

export default withNavigation(DashboardApplications);
