import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IModelApiObject} from '../../model/base';
import {
    IModelCity,
    IModelDictionaryDatum,
    IModelSeniority,
    IFileOutput,
    ITechnologyTestResult,
    ILanguageTestResult,
} from 'jobhunter-common-web';
import {ICandidateAccountCompletion} from 'jobhunter-common-web';

export enum ExternalLinkType {
    LINKEDIN = 'linkdedin',
    GITHUB = 'github',
    PORTFOLIO = 'portfolio',
}

export interface ICandidateAccount {
    firstName: string;
    lastName: string;
    phone: string;
    birthDate: string;
    cityName: string;
    country: {
        id: string;
        name: string;
    } | null;
    about: string;
    avatar: {
        contentUrl: string;
        fileUrls: any[];
        id: string;
    };
    id: string;
    locale: string; // "en-GB"
    filled: typeof ICandidateAccountCompletion | null;
}

export interface ICareerEntry {
    from: string;
    to: string | null;
    company: string;
    position: string;
    seniority: typeof IModelSeniority;
    id?: string;
}

export interface ITechnologyEntry {
    technology: typeof IModelDictionaryDatum;
    technologyTool: typeof IModelDictionaryDatum[];
    seniority: typeof IModelSeniority;
    id?: string;
}

export interface ILanguageEntry extends IModelApiObject {
    language: typeof IModelDictionaryDatum;
    languageLevel: typeof IModelDictionaryDatum;
}

export interface ICandidateSkills {
    technologySkills: ITechnologyEntry[];
    languageSkills: ILanguageEntry[];
}

export interface IExternalLink {
    link: string;
    type: ExternalLinkType;
    id?: string;
}

export interface ICandidatePreferences {
    technologies: typeof IModelDictionaryDatum[];
    cities: typeof IModelCity[];
    companyTypes: typeof IModelDictionaryDatum[];
    industries: typeof IModelDictionaryDatum[];
    contractTypes: typeof IModelDictionaryDatum[];
    employmentTypes: typeof IModelDictionaryDatum[]; // full_time, part_time
    workTypes: typeof IModelDictionaryDatum[]; // remote, part_remote, stationary
    minimumSalary: string;
    relocationOnly: boolean;
    preferenceTags: typeof IModelDictionaryDatum[];
}

export interface IProfileState {
    candidateAccount: ICandidateAccount | null;
    career: ICareerEntry[] | null;
    cv: typeof IFileOutput | null;
    skills: ICandidateSkills | null;
    externalLinks: IExternalLink[] | null;
    preferences: ICandidatePreferences | null;
    technologyTestResults: typeof ITechnologyTestResult[] | null;
    languageTestResults: typeof ILanguageTestResult[] | null;
    isProfileLoading: boolean;
    isProfileInitialized: boolean;
    profileError: string | null;
}

export interface ISetProfileData {
    candidateAccount: ICandidateAccount | null;
    career: ICareerEntry[] | null;
    cv: typeof IFileOutput | null;
    skills: ICandidateSkills | null;
    externalLinks: IExternalLink[] | null;
    preferences: ICandidatePreferences | null;
    technologyTestResults: typeof ITechnologyTestResult[] | null;
    languageTestResults: typeof ILanguageTestResult[] | null;
}

export interface IChangeCandidateAccountData {
    candidateAccountData: ICandidateAccount;
}

export interface IChangeCareerData {
    careerData: ICareerEntry;
}

export interface IChangeTechnologySkillsData {
    technologySkillsData: ITechnologyEntry;
}

export interface IChangeLanguageSkillsData {
    languageSkillsData: ILanguageEntry;
}

export interface IChangePreferencesData {
    preferencesData: ICandidatePreferences;
}

export interface IChangeExternalLinksData {
    externalLinksData: IExternalLink[];
}

export interface IChangeCvData {
    cvData: typeof IFileOutput | null;
}

export interface IChangeIsProfileLoading {
    isProfileLoading: boolean;
}

export interface IChangeIsProfileInitialized {
    isProfileInitialized: boolean;
}

export interface IRemoveEntry {
    id: string;
}

export interface IAddLanguageTest {
    languageId: string;
}

export interface IAddTechnologyTest {
    technologyId: string;
}

export interface IChangeProfileError {
    error: string | null;
}

const initialState: IProfileState = {
    candidateAccount: null,
    career: null,
    cv: null,
    skills: null,
    externalLinks: null,
    technologyTestResults: null,
    languageTestResults: null,
    preferences: {
        technologies: [],
        cities: [],
        companyTypes: [],
        industries: [],
        contractTypes: [],
        employmentTypes: [],
        workTypes: [],
        minimumSalary: '',
        relocationOnly: false,
        preferenceTags: [],
    },
    isProfileLoading: false,
    isProfileInitialized: false,
    profileError: null,
};

const profilePageSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        setProfileData: {
            reducer: (state: IProfileState, action: PayloadAction<ISetProfileData>) => {
                return {
                    ...state,
                    candidateAccount: action.payload.candidateAccount,
                    career: action.payload.career,
                    cv: action.payload.cv,
                    skills: action.payload.skills,
                    externalLinks: action.payload.externalLinks,
                    preferences: action.payload.preferences,
                    technologyTestResults: action.payload.technologyTestResults,
                    languageTestResults: action.payload.languageTestResults,
                };
            },
            prepare(
                candidateAccount: ICandidateAccount | null,
                career: ICareerEntry[] | null,
                cv: typeof IFileOutput | null,
                skills: ICandidateSkills | null,
                externalLinks: IExternalLink[] | null,
                preferences: ICandidatePreferences | null,
                technologyTestResults: typeof ITechnologyTestResult[] | null,
                languageTestResults: typeof ILanguageTestResult[] | null
            ) {
                return {
                    payload: {
                        candidateAccount: candidateAccount,
                        career: career,
                        cv: cv,
                        skills: skills,
                        externalLinks: externalLinks,
                        preferences: preferences,
                        technologyTestResults: technologyTestResults,
                        languageTestResults: languageTestResults,
                    },
                };
            },
        },
        changePersonalData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeCandidateAccountData>) => {
                return {
                    ...state,
                    candidateAccount: action.payload.candidateAccountData,
                };
            },
            prepare(candidateAccountData: ICandidateAccount) {
                return {
                    payload: {candidateAccountData: candidateAccountData},
                };
            },
        },
        changeCareerData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeCareerData>) => {
                const careerList = null !== state.career ? [...state.career] : [];
                return {
                    ...state,
                    career: [...careerList.filter((career) => career.id !== action.payload.careerData.id), action.payload.careerData],
                };
            },
            prepare(careerData: ICareerEntry) {
                return {
                    payload: {careerData: careerData},
                };
            },
        },
        changeTechnologySkillsData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeTechnologySkillsData>) => {
                const technologyList =
                    null !== state.skills && null !== state.skills.technologySkills ? [...state.skills.technologySkills] : [];

                return {
                    ...state,
                    skills: {
                        languageSkills: null !== state.skills ? state.skills.languageSkills : [],
                        technologySkills: [
                            ...technologyList.filter((technology) => technology.id !== action.payload.technologySkillsData.id),
                            action.payload.technologySkillsData,
                        ],
                    },
                };
            },
            prepare(technologySkillsData: ITechnologyEntry) {
                return {
                    payload: {technologySkillsData: technologySkillsData},
                };
            },
        },
        changeLanguageSkillsData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeLanguageSkillsData>) => {
                const languageList =
                    null !== state.skills && null !== state.skills?.languageSkills ? [...state.skills?.languageSkills] : [];

                return {
                    ...state,
                    skills: {
                        languageSkills: [
                            ...languageList.filter((language) => language.language.id !== action.payload.languageSkillsData.language.id),
                            action.payload.languageSkillsData,
                        ],
                        technologySkills: null !== state.skills ? state.skills.technologySkills : [],
                    },
                };
            },
            prepare(languageSkillsData: ILanguageEntry) {
                return {
                    payload: {languageSkillsData: languageSkillsData},
                };
            },
        },
        removeCareerEntryData: {
            reducer: (state: IProfileState, action: PayloadAction<IRemoveEntry>) => {
                const careerList = null !== state.career ? [...state.career] : [];
                return {
                    ...state,
                    career: careerList.filter((career) => career.id !== action.payload.id),
                };
            },
            prepare(id: string) {
                return {
                    payload: {id: id},
                };
            },
        },
        removeLanguageSkillData: {
            reducer: (state: IProfileState, action: PayloadAction<IRemoveEntry>) => {
                const languageList =
                    null !== state.skills && null !== state.skills?.languageSkills ? [...state.skills?.languageSkills] : [];

                return {
                    ...state,
                    skills: {
                        languageSkills: languageList.filter((language) => language.language.id !== action.payload.id),
                        technologySkills: null !== state.skills ? state.skills.technologySkills : [],
                    },
                };
            },
            prepare(id: string) {
                return {
                    payload: {id: id},
                };
            },
        },
        removeTechnologySkillData: {
            reducer: (state: IProfileState, action: PayloadAction<IRemoveEntry>) => {
                const technologyList =
                    null !== state.skills && null !== state.skills.technologySkills ? [...state.skills.technologySkills] : [];
                return {
                    ...state,
                    skills: {
                        languageSkills: null !== state.skills ? state.skills.languageSkills : [],
                        technologySkills: technologyList.filter((technology) => technology.id !== action.payload.id),
                    },
                };
            },
            prepare(id: string) {
                return {
                    payload: {id: id},
                };
            },
        },
        changeExternalLinksData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeExternalLinksData>) => {
                return {
                    ...state,
                    externalLinks: action.payload.externalLinksData,
                };
            },
            prepare(externalLinksData: IExternalLink[]) {
                return {
                    payload: {externalLinksData: externalLinksData},
                };
            },
        },
        changeCvData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeCvData>) => {
                return {
                    ...state,
                    cv: action.payload.cvData,
                };
            },
            prepare(cvData: typeof IFileOutput | null) {
                return {
                    payload: {cvData: cvData},
                };
            },
        },
        addLanguageTest: {
            reducer: (state: IProfileState) => {
                return {
                    ...state,
                };
            },
            prepare(languageId: string) {
                return {
                    payload: {languageId: languageId},
                };
            },
        },
        addTechnologyTest: {
            reducer: (state: IProfileState) => {
                return {
                    ...state,
                };
            },
            prepare(technologyId: string) {
                return {
                    payload: {technologyId: technologyId},
                };
            },
        },
        changePreferencesData: {
            reducer: (state: IProfileState, action: PayloadAction<IChangePreferencesData>) => {
                return {
                    ...state,
                    preferences: action.payload.preferencesData,
                };
            },
            prepare(preferencesData: ICandidatePreferences) {
                return {
                    payload: {preferencesData: preferencesData},
                };
            },
        },
        changeIsProfileLoading: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeIsProfileLoading>) => {
                return {
                    ...state,
                    isProfileLoading: action.payload.isProfileLoading,
                };
            },
            prepare(isProfileLoading: boolean) {
                return {
                    payload: {isProfileLoading: isProfileLoading},
                };
            },
        },
        changeIsProfileInitialized: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeIsProfileInitialized>) => {
                return {
                    ...state,
                    isProfileInitialized: action.payload.isProfileInitialized,
                };
            },
            prepare(isProfileInitialized: boolean) {
                return {
                    payload: {isProfileInitialized: isProfileInitialized},
                };
            },
        },
        changeProfileError: {
            reducer: (state: IProfileState, action: PayloadAction<IChangeProfileError>) => {
                return {
                    ...state,
                    profileError: action.payload.error,
                };
            },
            prepare(error: any) {
                return {
                    payload: {error: error},
                };
            },
        },
        updateProfilePage: {
            reducer: (state: IProfileState) => {
                return {
                    ...state,
                    isProfileLoading: true,
                };
            },
            prepare(payload: {[key: string]: any}) {
                return {
                    payload: payload,
                };
            },
        },
        fetchProfileAccountData: (state: IProfileState) => {
            return {
                ...state,
                isProfileLoading: true,
            };
        },
        resetToInitialProfilePageState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setProfileData,
    changePersonalData,
    changeCareerData,
    changeLanguageSkillsData,
    changeTechnologySkillsData,
    removeCareerEntryData,
    removeLanguageSkillData,
    removeTechnologySkillData,
    changePreferencesData,
    changeExternalLinksData,
    changeIsProfileLoading,
    changeIsProfileInitialized,
    changeProfileError,
    changeCvData,
    updateProfilePage,
    addLanguageTest,
    addTechnologyTest,
    fetchProfileAccountData,
    resetToInitialProfilePageState,
} = profilePageSlice.actions;

export default profilePageSlice.reducer;
