import {FormButtonType, FormControlType, IFormConfig, InputType, JobHunterDocuments, ValidationRules} from 'jobhunter-common-web';

export const registrationFormConfig: typeof IFormConfig = {
    controlType: 'form',
    class: 'auth-form',
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        // {
        //     key: 'registration_name',
        //     controlType: 'group',
        //     class: 'row',
        //     controls: {
        //         firstName: {
        //             controlType: 'control',
        //             defaultValue: '',
        //             formControlType: FormControlType.INPUT,
        //             validationRules: [{name: ValidationRules.IS_REQUIRED}],
        //             placeholder: 'auth.formControls.placeholderName',
        //             isLabelHidden: false,
        //             label: 'auth.formControls.firstName',
        //             type: InputType.TEXT,
        //             hostClass: 'col-xl-6 form-control required',
        //             class: 'test',
        //         },
        //         lastName: {
        //             controlType: 'control',
        //             defaultValue: '',
        //             formControlType: FormControlType.INPUT,
        //             validationRules: [{name: ValidationRules.IS_REQUIRED}],
        //             placeholder: 'auth.formControls.placeholderSurname',
        //             isLabelHidden: false,
        //             label: 'auth.formControls.lastName',
        //             type: InputType.TEXT,
        //             hostClass: 'col-xl-6 form-control required',
        //         },
        //     },
        // },
        {
            key: 'registration_email',
            controlType: 'group',
            class: 'row',
            controls: {
                email: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.IS_EMAIL, params: {}},
                    ],
                    placeholder: 'auth.formControls.placeholderEmail',
                    isLabelHidden: false,
                    label: 'auth.formControls.email',
                    type: InputType.EMAIL,
                    hostClass: 'col-xl-12 form-control required',
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_password',
            class: 'row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.plainPassword, (value: any) => value.newPassword]},
                },
            ],
            controls: {
                plainPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholderPassword',
                    isLabelHidden: false,
                    label: 'auth.formControls.password',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control required',
                },
                newPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 6}},
                    ],
                    placeholder: 'auth.formControls.placeholderConfirmPassword',
                    label: 'auth.formControls.confirmPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-12 form-control required',
                },
                // phone: {
                //     controlType: 'control',
                //     defaultValue: '',
                //     formControlType: FormControlType.INPUT,
                //     validationRules: [],
                //     placeholder: 'auth.formControls.placeholderPhone',
                //     isLabelHidden: false,
                //     label: 'auth.formControls.phone',
                //     type: InputType.TEL,
                //     hostClass: 'col-xl-6 form-control',
                // },
            },
        },
        {
            controlType: 'group',
            key: 'registration_agreements',
            class: 'row agreement-container',
            controls: {
                registrationTermsAgreement: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [{name: ValidationRules.IS_REQUIRED, params: {}}],
                    placeholder: '',
                    label: '',
                    isLabelHidden: true,
                    isCheckboxLabelRaw: true,
                    checkboxLabel: `I agree to <a href="${process.env.REACT_APP_API_URL}${JobHunterDocuments.PrivacyPolicy}" target="_blank">privacy policy</a> & <a href="${process.env.REACT_APP_API_URL}${JobHunterDocuments.TermsOfUse}" target="_blank">terms</a>`,
                    hostClass: 'col-xl-12 checkbox-container',
                },
                GDPRInfo: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.TEXT,
                    validationRules: [],
                    placeholder: '',
                    label: 'auth.formControls.GDPRInfo',
                    isLabelHidden: false,
                    hostClass: 'col-xl-12 checkbox-container',
                },
            },
        },
        {
            controlType: 'group',
            key: 'registration_submit',
            class: 'row register-form',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn btn-primary',
                    defaultContainerStyles: '',
                    containerStyles: 'button-container mt-2',
                    defaultValue: null,
                    btnText: 'buttons.register',
                },
            },
        },
    ],
};
