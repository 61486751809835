import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {accountSelector, addAlert, AlertType, authTokenSelector, getErrorMessage, RestQueryParams} from 'jobhunter-common-web';
import {getOffersAPI} from '../../api/getOffers';
import {RootState} from '../reducers';
import {getApplicationOffersAPI} from '../../api/getApplicationOffers';
import {
    changeIsApplicationsListLoading,
    changeIsBestMatchListLoading,
    changeIsCalendarLoading,
    changeIsDashboardLoading,
    fetchApplications,
    fetchBestMatchOffers,
    fetchCalendarDetails,
    fetchProfileDetails,
    IProfileCompletion,
    setApplications,
    setBestMatchOffers,
    setCalendarDetails,
    setProfileDetails,
} from '../reducers/dashboardPageSlice';
import {getCalendarEventsAPI} from '../../api/getCalendarEvents';

const fetchBestMatchOffersEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchBestMatchOffers.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('best_match', true).add('order[createdAt]', 'desc').add('itemsPerPage', '5');

            return getOffersAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const bestMatchList = resp['hydra:member'] ? resp['hydra:member'] : [],
                        actions = successActions([setBestMatchOffers(bestMatchList), changeIsBestMatchListLoading(false)]);
                    return of(...actions);
                }),
                catchError((error) => {
                    return of(...updateListErrorActions(error, [setBestMatchOffers([]), changeIsBestMatchListLoading(false)]));
                })
            );
        }),
        catchError((error) => of(...updateListErrorActions(error, [changeIsBestMatchListLoading(false)])))
    );
};

const fetchApplicationsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchApplications.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value),
                params = new RestQueryParams().add('order[createdAt]', 'desc').add('itemsPerPage', '5');
            return getApplicationOffersAPI(authToken, params).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setApplications(resp['hydra:member']), changeIsApplicationsListLoading(false)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error, [changeIsApplicationsListLoading(false)])))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error, [changeIsApplicationsListLoading(false)])))
    );
};

const fetchProfileDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchProfileDetails.type),
        switchMap((): any => {
            const accountData = accountSelector(state$.value),
                accountCompletion = accountData.candidateFullInfo.account.filled,
                profileDetails: IProfileCompletion = {
                    isPersonalDataComplete: accountCompletion.personal,
                    isCareerComplete: accountCompletion.career,
                    isSkillDataComplete: accountCompletion.skill_tests,
                    isPreferencesDataComplete: accountCompletion.preferences,
                },
                actions = successActions([setProfileDetails(profileDetails)]);
            return of(...actions);
        }),
        catchError((error) => of(...updateListErrorActions(error)))
    );
};

const fetchCalendarDetailsEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchCalendarDetails.type),
        switchMap((): any => {
            const authToken = authTokenSelector(state$.value);
            return getCalendarEventsAPI(authToken).pipe(
                switchMap((resp: any) => {
                    const actions = successActions([setCalendarDetails(resp['hydra:member']), changeIsCalendarLoading(false)]);
                    return of(...actions);
                }),
                catchError((error) => of(...updateListErrorActions(error, [changeIsCalendarLoading(false)])))
            );
        }),
        catchError((error) => of(...updateListErrorActions(error, [changeIsCalendarLoading(false)])))
    );
};

const successActions = (changeSliceList: any[]): any[] => {
    const actions = [changeIsDashboardLoading(false)];
    return [...changeSliceList, ...actions];
};

const updateListErrorActions = (error: any, actionList?: any[]): any[] => {
    let actions = [changeIsDashboardLoading(false), addAlert({message: getErrorMessage(error), type: AlertType.WARNING})];

    if (actionList) {
        actions = actions.concat(actionList);
    }

    return actions;
};

const dashboardEpic = combineEpics(fetchBestMatchOffersEpic, fetchApplicationsEpic, fetchProfileDetailsEpic, fetchCalendarDetailsEpic);

export default dashboardEpic;
