import {FormControlType, IFormConfig, InputType, formatRangePickerLabel, ValidationRules} from 'jobhunter-common-web';

export const addCareerFormConfig: typeof IFormConfig = (seniorityLevels: any[]) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                companyName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'profile.career.addCareer.form.labels.company',
                    isLabelHidden: false,
                    label: 'profile.career.addCareer.form.labels.company',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
                position: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        // {name: ValidationRules.IS_REQUIRED}
                    ],
                    placeholder: 'profile.career.addCareer.form.labels.position',
                    isLabelHidden: false,
                    label: 'profile.career.addCareer.form.labels.position',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            key: 'duration',
            controlType: 'group',
            class: 'row',
            controls: {
                startDate: {
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            },
                        },
                    ],
                    placeholder: 'profile.career.addCareer.form.labels.startDate',
                    isLabelHidden: false,
                    label: 'profile.career.addCareer.form.labels.startDate',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920, 1, 1),
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    openToDate: new Date(1990, 1, 1),
                    hostClass: 'col-xl-6 form-control',
                },
                endDate: {
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            },
                        },
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'profile.career.addCareer.form.labels.endDate',
                    isLabelHidden: false,
                    label: 'profile.career.addCareer.form.labels.endDate',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920, 1, 1),
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    openToDate: new Date(1990, 1, 1),
                    hostClass: 'col-xl-6 form-control',
                },
            },
        },
        {
            key: 'is_working',
            controlType: 'group',
            class: 'row',
            controls: {
                isWorking: {
                    controlType: 'control',
                    defaultValue: false,
                    formControlType: FormControlType.SWITCH,
                    validationRules: [],
                    placeholder: '',
                    label: 'Currently working here',
                    hostClass: 'col-xl-6 form-control switch-inline-container',
                },
            },
        },
        {
            key: 'seniorityLevel',
            controlType: 'group',
            class: 'row',
            controls: {
                seniorityLevel: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.RANGE_PICKER,
                    validationRules: [],
                    rangeLabels: seniorityLevels,
                    label: 'Seniority Level',
                    labelFormat: (value: number) => formatRangePickerLabel(value, seniorityLevels),
                    hostClass: 'col-xl-12 form-control',
                    maxRangeStep: Object.keys(seniorityLevels).length * 10,
                    isRangeLabelTranslated: true,
                },
            },
        },
    ],
});
