import React, {Component} from 'react';
import {Button, Card, CardBody, CardFooter, CardTitle} from 'reactstrap';
import styles from './styles.module.scss';
import BuyServiceModal from './BuyService';
import ServiceDetails from './ServiceDetails';
import {Price, Translation, Tooltip} from 'jobhunter-common-web';
import {IModelService} from '../../../model/service';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IMarketplaceCardProps extends WithTranslation {
    service: IModelService;
}

interface IMarketplaceCardState {
    isDescriptionExpanded: boolean;
    isModalOpen: boolean;
}

class MarketplaceCard extends Component<IMarketplaceCardProps, IMarketplaceCardState> {
    constructor(props: IMarketplaceCardProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
            isModalOpen: false,
        };
    }

    render() {
        const service = this.props.service,
            {t} = this.props;

        return (
            <>
                <Card className="service-details-card">
                    <CardBody>
                        <CardTitle>
                            <div className="card-header">{service.title}</div>
                        </CardTitle>
                        <ServiceDetails service={service} />
                    </CardBody>
                    <CardFooter>
                        <div className={styles.priceDetails}>
                            <div className={styles.price}>
                                {service.grossPrice === '0' ? (
                                    <p>
                                        <Translation text="marketplace.serviceDetails.freeService" />
                                    </p>
                                ) : (
                                    <Price
                                        price={{
                                            amount: service.grossPrice,
                                        }}
                                    />
                                )}
                            </div>
                            <div className="btn-wrapper" id={`buy-service-${service.id}`}>
                                <Button
                                    color="flat-primary"
                                    onClick={() => this.toggleModal()}
                                    className="buy-service-button"
                                    disabled={!service.currentUserCanBuyService}>
                                    <Translation text="buttons.buy" />
                                </Button>
                            </div>
                            {!service.currentUserCanBuyService ? (
                                <Tooltip
                                    target={`buy-service-${service.id}`}
                                    tooltipText={t('marketplace.serviceDetails.purchaseDisabled')}
                                />
                            ) : null}
                        </div>
                    </CardFooter>
                </Card>

                {this.state.isModalOpen ? (
                    <BuyServiceModal isModalOpen={this.state.isModalOpen} toggleModal={this.toggleModal} service={service} />
                ) : null}
            </>
        );
    }

    private toggleModal = () => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };
}

export default withTranslation()(MarketplaceCard);
