import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IPurchasedService} from '../../model/service';
import {IModelApiResponseViewObject} from 'jobhunter-common-web';

export interface IPaginationParams {
    page: number;
    itemsPerPage: number;
}

export interface IPurchasedServicesState {
    purchasedServices: IPurchasedService[] | null;
    purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
    pagination: IPaginationParams | null;
    isPurchasedServicesPageLoading: boolean;
    isPurchasedServicesPageInitialized: boolean;
    purchasedServicesPageError: string | null;
}

export interface ISetPurchasedServices {
    purchasedServices: IPurchasedService[] | null;
}

export interface ISetPurchasedServicesMetadata {
    purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
}

export interface IChangePurchasedServicesPagination {
    pagination: IPaginationParams;
}

export interface IChangeIsPurchasedServicesPageLoading {
    isPurchasedServicesPageLoading: boolean;
}

export interface IChangeIsPurchasedServicesPageInitialized {
    isPurchasedServicesPageInitialized: boolean;
}

export interface IChangePurchasedServicesPageError {
    error: string | null;
}

const initialState: IPurchasedServicesState = {
    purchasedServices: null,
    purchasedServicesMetadata: null,
    pagination: {
        page: 1,
        itemsPerPage: 10,
    },
    isPurchasedServicesPageLoading: false,
    isPurchasedServicesPageInitialized: false,
    purchasedServicesPageError: null,
};

const purchasedServicesPageSlice = createSlice({
    name: 'purchasedServicesPage',
    initialState: initialState,
    reducers: {
        setPurchasedServicesData: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<ISetPurchasedServices>) => {
                return {
                    ...state,
                    purchasedServices: action.payload.purchasedServices,
                };
            },
            prepare(purchasedServices: IPurchasedService[]) {
                return {
                    payload: {
                        purchasedServices: purchasedServices,
                    },
                };
            },
        },
        changeIsPurchasedServicesPageLoading: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<IChangeIsPurchasedServicesPageLoading>) => {
                return {
                    ...state,
                    isPurchasedServicesPageLoading: action.payload.isPurchasedServicesPageLoading,
                };
            },
            prepare(isLoading: boolean) {
                return {
                    payload: {isPurchasedServicesPageLoading: isLoading},
                };
            },
        },
        changeIsPurchasedServicesPageInitialized: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<IChangeIsPurchasedServicesPageInitialized>) => {
                return {
                    ...state,
                    isPurchasedServicesPageInitialized: action.payload.isPurchasedServicesPageInitialized,
                };
            },
            prepare(isInitialized: boolean) {
                return {
                    payload: {isPurchasedServicesPageInitialized: isInitialized},
                };
            },
        },
        changePurchasedServicesPageError: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<IChangePurchasedServicesPageError>) => {
                return {
                    ...state,
                    purchasedServicesPageError: action.payload.error,
                };
            },
            prepare(error: string | null) {
                return {
                    payload: {error: error},
                };
            },
        },
        fetchPurchasedServices: (state: IPurchasedServicesState) => {
            return {
                ...state,
                isPurchasedServicesPageLoading: true,
            };
        },
        setPurchasedServicesMetadata: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<ISetPurchasedServicesMetadata>) => {
                return {
                    ...state,
                    purchasedServicesMetadata: action.payload.purchasedServicesMetadata,
                };
            },
            prepare(purchasedServicesMetadata: typeof IModelApiResponseViewObject | null) {
                return {
                    payload: {
                        purchasedServicesMetadata: purchasedServicesMetadata,
                    },
                };
            },
        },
        changePurchasedServicesPagination: {
            reducer: (state: IPurchasedServicesState, action: PayloadAction<IChangePurchasedServicesPagination>) => {
                return {
                    ...state,
                    isPurchasedServicesPageLoading: true,
                    pagination: action.payload.pagination,
                };
            },
            prepare(pagination: IPaginationParams) {
                return {
                    payload: {pagination: pagination},
                };
            },
        },
        resetToInitialPurchasedServicesState: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setPurchasedServicesData,
    changeIsPurchasedServicesPageLoading,
    changeIsPurchasedServicesPageInitialized,
    changePurchasedServicesPageError,
    fetchPurchasedServices,
    setPurchasedServicesMetadata,
    changePurchasedServicesPagination,
    resetToInitialPurchasedServicesState,
} = purchasedServicesPageSlice.actions;

export default purchasedServicesPageSlice.reducer;
