import React, {Component} from 'react';
import {Badge, Button} from 'reactstrap';
import {ChevronDown, ChevronUp} from 'react-feather';
import styles from './styles.module.scss';
import {Avatar, AvatarColor, AvatarSize, IModelDictionaryDatum, Translation} from 'jobhunter-common-web';
import {IModelService} from '../../../../model/service';

interface IServiceDetailsProps {
    service: IModelService;
}

interface IServiceDetailsState {
    isDescriptionExpanded: boolean;
}

class ServiceDetails extends Component<IServiceDetailsProps, IServiceDetailsState> {
    constructor(props: IServiceDetailsProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
        };
    }

    render() {
        const service = this.props.service,
            shouldDescriptionBeShortened = service?.description.length > 150,
            shortenedDescription = shouldDescriptionBeShortened ? service.description.substr(0, 150) + '...' : service.description;

        return (
            <div className={styles.serviceDetails}>
                <div className={styles.header}>
                    <div className={styles.company}>
                        <Avatar
                            color={AvatarColor.LIGHT_INFO}
                            size={AvatarSize.LG}
                            img={service?.author?.avatar?.fileUrls?.mediumThumb}
                            tag={'div'}
                        />
                        <p>
                            <span>
                                <Translation text="marketplace.serviceDetails.by" />
                            </span>
                            {service?.author?.organization?.organizationCompany?.name}
                        </p>
                    </div>
                    <div className={styles.labels}>
                        <div className="d-flex flex-wrap">
                            <Badge color="primary" pill className="mb-1">
                                {service?.author?.organization?.organizationCompany?.city}
                            </Badge>

                            {service.types.map((type: typeof IModelDictionaryDatum) => {
                                return (
                                    <Badge color="primary" pill key={type.id} className="mb-1">
                                        <Translation text={type.name} />
                                    </Badge>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <p className={styles.description}>{this.state.isDescriptionExpanded ? service.description : shortenedDescription}</p>

                <div className={styles.btnContainer}>
                    {shouldDescriptionBeShortened ? (
                        <Button
                            color="flat-primary"
                            className="read-more-button"
                            onClick={() => this.setState({isDescriptionExpanded: !this.state.isDescriptionExpanded})}>
                            <span className="align-middle me-1">
                                {this.state.isDescriptionExpanded ? (
                                    <Translation text="marketplace.serviceDetails.readLess" />
                                ) : (
                                    <Translation text="marketplace.serviceDetails.readMore" />
                                )}
                            </span>
                            {this.state.isDescriptionExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default ServiceDetails;
