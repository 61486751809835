import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {FormControlChangeType, Translation} from 'jobhunter-common-web';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import styles from './styles.module.scss';
import ServiceDetails from '../../Marketplace/MarketplaceCard/ServiceDetails';
import {IModelService} from '../../../model/service';

interface IServiceViewProps {
    isModalOpen: boolean;
    toggleModal: () => void;
    service: IModelService;
}

interface IServiceViewState {}

class ServiceView extends Component<IServiceViewProps, IServiceViewState> {
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    private subscriptions: Subscription[] = [];

    constructor(props: IServiceViewProps) {
        super(props);

        this.state = {};
    }

    componentDidMount(): void {
        this.subscriptions.push(
            this.onValueStateChange$
                .pipe(
                    filter((data: any) => data && data.changeType === FormControlChangeType.User),
                    tap((data: any) => this.setState({value: data.value}))
                )
                .subscribe()
        );
    }

    componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    render() {
        const service = this.props.service;

        return (
            <Modal isOpen={this.props.isModalOpen} toggle={() => this.props.toggleModal()}>
                <ModalHeader className="custom-header" toggle={() => this.props.toggleModal()} />
                <ModalBody className="service-payment-modal">
                    <p className="modal-title">{service.title}</p>

                    <ServiceDetails service={service} />

                    <hr />

                    <div className={styles.actions}>
                        <Button color="primary" className="close-modal-button" onClick={() => this.props.toggleModal()}>
                            <Translation text="buttons.close" />
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ServiceView;
