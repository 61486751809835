import React, {Component} from 'react';
import LayoutWrapper from '../LayoutWrapper';
import {
    IModelApiResponseViewObject,
    IModelDictionaryDatum,
    Loader,
    LoaderType,
    Pagination,
    Price,
    Tooltip,
    Translation,
} from 'jobhunter-common-web';
import {Badge, Button, Table} from 'reactstrap';
import styles from './styles.module.scss';
import {Search, ShoppingCart} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import ServiceView from './ServiceView';
import BuyServiceModal from '../Marketplace/MarketplaceCard/BuyService';
import {IModelService, IPurchasedService, IServicePayment} from '../../model/service';
import {connect} from 'react-redux';
import {RootState} from '../../store/reducers';
import {
    changePurchasedServicesPagination,
    fetchPurchasedServices,
    resetToInitialPurchasedServicesState,
} from '../../store/reducers/purchasedServicesPageSlice';
import {
    isPurchasedServicesPageLoadingSelector,
    purchasedServicesSelector,
    purchasedServicesMetadataSelector,
} from '../../store/selectors/purchasedServicesPageSelectors';

export enum ServicePaymentStatus {
    PAID = 'paid',
    PENDING = 'pending',
    EXPIRED = 'expired',
}

interface IConnectedServicesProps {
    readonly purchasedServices: IPurchasedService[] | null;
    readonly isLoading: boolean;
    readonly purchasedServicesMetadata: typeof IModelApiResponseViewObject | null;
    readonly changePurchasedServicesPagination: typeof changePurchasedServicesPagination;
    readonly fetchPurchasedServices: typeof fetchPurchasedServices;
    readonly resetToInitialPurchasedServicesState: typeof resetToInitialPurchasedServicesState;
}

interface IExternalServicesProps {}

interface IServicesProps extends IConnectedServicesProps, IExternalServicesProps, WithTranslation {}

interface IServicesState {
    isDetailsModalOpen: boolean;
    isPurchaseModalOpen: boolean;
    selectedService: IModelService | null;
}

class Services extends Component<IServicesProps, IServicesState> {
    constructor(props: IServicesProps) {
        super(props);

        this.state = {
            isDetailsModalOpen: false,
            isPurchaseModalOpen: false,
            selectedService: null,
        };
    }

    componentDidMount() {
        this.props.fetchPurchasedServices();
    }

    componentWillUnmount() {
        this.props.resetToInitialPurchasedServicesState();
    }

    render() {
        return (
            <LayoutWrapper>
                <div className="section services-section">
                    <div className="header">
                        <div>
                            <p className="title">
                                <Translation text="services.title" />
                            </p>
                        </div>
                    </div>
                    <Table striped responsive className="invoice-table">
                        <thead>
                            <tr>
                                <th>
                                    <Translation text="services.table.headers.invoice" />
                                </th>
                                <th>
                                    <Translation text="services.table.headers.service" />
                                </th>
                                <th>
                                    <Translation text="services.table.headers.info" />
                                </th>
                                <th>
                                    <Translation text="services.table.headers.serviceDescription" />
                                </th>
                                <th>
                                    <Translation text="services.table.headers.cost" />
                                </th>
                                <th>
                                    <Translation text="services.table.headers.status" />
                                </th>
                                <th className="text-center">
                                    <Translation text="services.table.headers.actions" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.renderServicesTableRows()}</tbody>
                    </Table>

                    <Pagination
                        listMetadata={this.props.purchasedServicesMetadata}
                        changePage={this.props.changePurchasedServicesPagination}
                        itemsPerPage={10}
                    />

                    {this.state.isDetailsModalOpen && this.state.selectedService ? (
                        <ServiceView
                            isModalOpen={this.state.isDetailsModalOpen}
                            toggleModal={this.showDetails}
                            service={this.state.selectedService}
                        />
                    ) : null}

                    {this.state.isPurchaseModalOpen && this.state.selectedService ? (
                        <BuyServiceModal
                            isModalOpen={this.state.isPurchaseModalOpen}
                            toggleModal={this.togglePurchaseModal}
                            service={this.state.selectedService}
                        />
                    ) : null}
                </div>
                <Loader showLoader={this.props.isLoading} type={LoaderType.Local} />
            </LayoutWrapper>
        );
    }

    private renderServicesTableRows() {
        const {t} = this.props;

        if (null === this.props.purchasedServices || this.props.purchasedServices.length <= 0) {
            return (
                <tr key="no-services">
                    <td colSpan={7}>
                        <p>
                            <Translation text="services.table.noData" />
                        </p>
                    </td>
                </tr>
            );
        }

        return this.props.purchasedServices.map((item: IPurchasedService) => {
            const service = item?.service,
                company = service?.author?.organization?.organizationCompany,
                payment = item?.payment,
                status = this.renderPaymentStatus(payment),
                badge =
                    status === ServicePaymentStatus.PAID
                        ? 'light-success'
                        : status === ServicePaymentStatus.PENDING
                        ? 'light-warning'
                        : 'light-danger';

            return (
                <tr key={item.id}>
                    <td>
                        <p className="fw-bolder">{payment?.invoiceNumber ? `#${payment?.invoiceNumber}` : '---'}</p>
                    </td>
                    <td>
                        <p className="fw-bolder mb-1">{service.title}</p>
                        {service.types.map((type: typeof IModelDictionaryDatum) => (
                            <p key={type.id}>
                                <Translation text={type.name} />
                            </p>
                        ))}
                    </td>
                    <td>
                        <p className="fw-bolder mb-1">{company?.name}</p>
                        <p>{company?.city}</p>
                    </td>
                    <td>
                        <p>{service.description}</p>
                    </td>
                    <td>
                        <p className="fw-bolder">
                            <Price price={{amount: service.grossPrice}} />
                        </p>
                    </td>
                    <td>
                        <Badge pill color={badge}>
                            <Translation text={`services.table.status.${status}`} />
                        </Badge>
                    </td>
                    <td>
                        <div className={styles.actions}>
                            <Button color="flat-primary" className="buy-service-button" onClick={() => this.togglePurchaseModal(service)}>
                                <ShoppingCart id={`buy_${item.id}`} />
                                <Tooltip target={`buy_${item.id}`} tooltipText={t('services.table.tooltips.buyAgain')} />
                            </Button>

                            <Button color="flat-primary" className="remove-service-button" onClick={() => this.showDetails(service)}>
                                <Search id={`details_${item.id}`} />
                                <Tooltip target={`details_${item.id}`} tooltipText={t('services.table.tooltips.details')} />
                            </Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }

    private togglePurchaseModal = (service?: IModelService) => {
        const selectedService = undefined !== service ? service : null;
        this.setState({
            isPurchaseModalOpen: !this.state.isPurchaseModalOpen,
            selectedService: selectedService,
        });
    };

    private showDetails = (service?: IModelService) => {
        const selectedService = undefined !== service ? service : null;
        this.setState({
            isDetailsModalOpen: !this.state.isDetailsModalOpen,
            selectedService: selectedService,
        });
    };

    private renderPaymentStatus = (payment: IServicePayment | null): string | ServicePaymentStatus => {
        let status = '';
        if (payment === null) {
            return status;
        }

        if (payment.paidAt || Number(payment.grossValue) === 0) {
            status = ServicePaymentStatus.PAID;
        } else if (payment.expiresAt) {
            status = ServicePaymentStatus.EXPIRED;
        } else if (payment.paidAt === null && payment.expiresAt === null) {
            status = ServicePaymentStatus.PENDING;
        }
        return status;
    };
}

export default connect(
    (state: RootState) => ({
        purchasedServices: purchasedServicesSelector(state),
        purchasedServicesMetadata: purchasedServicesMetadataSelector(state),
        isLoading: isPurchasedServicesPageLoadingSelector(state),
    }),
    {
        fetchPurchasedServices,
        resetToInitialPurchasedServicesState,
        changePurchasedServicesPagination,
    }
)(withTranslation()(Services));
