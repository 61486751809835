import React, {Component} from 'react';
import OffersList, {OffersListType} from '../OffersList';
import {WithLocation, withLocation} from 'jobhunter-common-web';
import OfferFilters from '../OfferFilters';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedOffersProps {}

interface IExternalOffersProps {}

interface IOffersProps extends IConnectedOffersProps, IExternalOffersProps, WithLocationProps {}

interface IOffersState {
    isBestMatchSet: boolean;
}

class Offers extends Component<IOffersProps, IOffersState> {
    constructor(props: IOffersProps) {
        super(props);

        this.state = {
            isBestMatchSet: false,
        };
    }

    componentDidMount(): void {
        if (this.props.location.state && this.props.location.state.isBestMatchSelected) {
            this.setState({isBestMatchSet: true});
        }
    }

    render() {
        return (
            <div className={'app-local-content'}>
                <OfferFilters isBestMatchSet={this.state.isBestMatchSet} />

                <OffersList type={OffersListType.OFFERS} isBestMatchSet={this.state.isBestMatchSet} />
            </div>
        );
    }
}

export default withLocation(Offers);
