import {Translation} from 'jobhunter-common-web';
import React, {Component} from 'react';
import {Edit3, Trash2} from 'react-feather';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Badge, Button} from 'reactstrap';
import AddLanguage from './AddLanguage';
import {ILanguageEntry, removeLanguageSkillData} from '../../../../store/reducers/profilePageSlice';
import DeleteSkillsModal from '../DeleteSkillsModal';

interface IConnectedLanguageSkillsProps {}

interface IExternalLanguageSkillsProps {
    readonly languageSkills: ILanguageEntry[] | undefined;
    readonly removeLanguageSkillData: typeof removeLanguageSkillData;
}

interface ILanguageSkillsProps extends IConnectedLanguageSkillsProps, IExternalLanguageSkillsProps, WithTranslation {}

interface ILanguageSkillsState {
    isLanguagesModalVisible: boolean;
    isDeleteModalVisible: boolean;
    selectedLanguageEntry: ILanguageEntry | null;
    isLoading: boolean;
}

class LanguageSkills extends Component<ILanguageSkillsProps, ILanguageSkillsState> {
    constructor(props: ILanguageSkillsProps) {
        super(props);

        this.state = {
            isLanguagesModalVisible: false,
            isDeleteModalVisible: false,
            selectedLanguageEntry: null,
            isLoading: false,
        };
    }

    render() {
        return (
            <>
                <div className="career-section">
                    <p className="sub-title">
                        <Translation text="profile.skillTest.languageSkills" />
                    </p>
                    {!this.props.languageSkills || !this.props.languageSkills.length ? (
                        <p className="no-options">
                            <Translation text="profile.career.noLanguages" />
                        </p>
                    ) : (
                        this.props.languageSkills.map((item: any, index: number) => this.renderLanguageItem(item, index))
                    )}

                    <div className="add-btn-container">
                        <Button className="add-new-language-button" color="primary" onClick={() => this.toggleAddLanguage()}>
                            <Translation text="buttons.addNew" />
                        </Button>
                    </div>
                </div>

                {this.state.isLanguagesModalVisible ? (
                    <AddLanguage
                        isModalOpen={this.state.isLanguagesModalVisible}
                        toggleModal={this.toggleAddLanguage}
                        languageSkill={this.state.selectedLanguageEntry}
                        languageSkills={this.props.languageSkills}
                    />
                ) : null}
                {this.state.isDeleteModalVisible ? (
                    <DeleteSkillsModal
                        isModalOpen={this.state.isDeleteModalVisible}
                        toggleModal={this.toggleDeleteModal}
                        removeAction={this.removeLanguageItem}
                    />
                ) : null}
            </>
        );
    }

    private renderLanguageItem = (item: ILanguageEntry, index: number) => {
        return (
            <div className="career-item" key={item.language.id ? item.language.id : `language-${index}`}>
                <div className="career-details">
                    <div className="technology">
                        <p>
                            <Translation text={item.language.name} />
                        </p>
                    </div>
                    <div className="d-flex">
                        <div className="position me-5">
                            <Badge color="light-primary">
                                <Translation text={item.languageLevel.name} />
                            </Badge>
                        </div>
                        <div className="actions">
                            <button className="remove-language-button" onClick={() => this.toggleDeleteModal(item)}>
                                <Trash2 />
                            </button>
                            <button className="edit-language-button" onClick={() => this.toggleAddLanguage(item)}>
                                <Edit3 />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private toggleAddLanguage = (languageEntry?: ILanguageEntry | null) => {
        this.setState({
            isLanguagesModalVisible: !this.state.isLanguagesModalVisible,
            selectedLanguageEntry: languageEntry ? languageEntry : null,
        });
    };

    private toggleDeleteModal = (languageEntry?: ILanguageEntry | null) => {
        this.setState({
            isDeleteModalVisible: !this.state.isDeleteModalVisible,
            selectedLanguageEntry: languageEntry ? languageEntry : null,
        });
    };

    private removeLanguageItem = () => {
        if (this.state.selectedLanguageEntry && this.state.selectedLanguageEntry.language) {
            this.props.removeLanguageSkillData(this.state.selectedLanguageEntry.language.id);
        }
    };
}

export default withTranslation()(LanguageSkills);
