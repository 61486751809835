import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {Observable} from 'rxjs';

export interface IAddTechnologyTestPayload {
    technologyId: string;
}

export function addTechnologyTestResultAPI(authToken: string | null, payload: IAddTechnologyTestPayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/technology_test_results`, payload, new RestQueryParams(), headers);
}
