import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import OffersList, {OffersListType} from '../OffersList';

interface IObservedOffersProps {}

class ObservedOffers extends Component<IObservedOffersProps, {}> {
    render() {
        return (
            <LayoutWrapper wrapperClass={'app-local-content'}>
                <OffersList type={OffersListType.OBSERVED} />
            </LayoutWrapper>
        );
    }
}

export default ObservedOffers;
