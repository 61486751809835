import React, {Component} from 'react';
import LayoutWrapper from '../../LayoutWrapper';
import {Badge, Button, Card, CardBody} from 'reactstrap';
import {
    Avatar,
    AvatarColor,
    AvatarSize,
    isNotNullOrUndefined,
    IModelDictionaryDatum,
    LoaderType,
    Map,
    Tooltip,
    Translation,
    withLocation,
    WithLocation,
    Loader,
    RatingComponent,
    Price,
    HtmlParserComponent,
} from 'jobhunter-common-web';
import {Box, DollarSign, Eye, EyeOff, Share2, TrendingUp, User} from 'react-feather';
import {WithTranslation, withTranslation} from 'react-i18next';
import OfferApplicationModal from './OfferApplicationModal';
import {addOfferToObserved, IOfferTechnologyTool, OfferStatus, removeOfferFromObserved} from '../../../store/reducers/offersPageSlice';
import {
    fetchOfferDetails,
    IOfferDetails,
    resetToInitialOfferDetailsPageState,
    checkOfferRecommendationAsSeen,
} from '../../../store/reducers/offerDetailsPageSlice';
import {connect} from 'react-redux';
import {isOfferDetailsLoadingSelector, offerDetailsSelector} from '../../../store/selectors/offerDetailsPageSelectors';
import {RootState} from '../../../store/reducers';
import {IOffer} from '../../../model/offer';
import RecommendOfferModal from './RecommendOfferModal';

declare type WithLocationProps = typeof WithLocation;

interface IConnectedOfferViewProps {
    readonly offer: IOfferDetails;
    readonly isOfferDetailsLoading: boolean;
    readonly fetchOfferDetails: typeof fetchOfferDetails;
    readonly addOfferToObserved: typeof addOfferToObserved;
    readonly removeOfferFromObserved: typeof removeOfferFromObserved;
    readonly resetToInitialOfferDetailsPageState: typeof resetToInitialOfferDetailsPageState;
    readonly checkOfferRecommendationAsSeen: typeof checkOfferRecommendationAsSeen;
}

interface IExternalOfferViewProps {}

interface IOfferViewProps extends IConnectedOfferViewProps, IExternalOfferViewProps, WithTranslation, WithLocationProps {}

interface IOfferViewState {
    isApplicationModalOpen: boolean;
    isRecommendationModalOpen: boolean;
    selectedOffer: IOffer | null;
}

class OfferView extends Component<IOfferViewProps, IOfferViewState> {
    constructor(props: IOfferViewProps) {
        super(props);
        this.state = {
            isApplicationModalOpen: false,
            isRecommendationModalOpen: false,
            selectedOffer: null,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.pathname) {
            const offerId = this.props.location.pathname.split('/').pop();
            this.props.fetchOfferDetails(offerId);
        }
        if (this.props.location && this.props.location.search) {
            const recommendedOffer = this.props.location.search.split('?recommendation=').pop();
            this.props.checkOfferRecommendationAsSeen(recommendedOffer);
        }
    }

    componentWillUnmount() {
        this.props.resetToInitialOfferDetailsPageState();
    }

    render() {
        const offer = this.props.offer,
            {t} = this.props;
        if (offer === null) {
            return (
                <>
                    <p>
                        <Translation text={'offers.offerView.noData'} />
                    </p>

                    <Loader showLoader={this.props.isOfferDetailsLoading} type={LoaderType.Local} />
                </>
            );
        }
        const offerDetails = offer.offer.offer,
            offerCity = offerDetails.city.name,
            offerCountry = offerDetails.city.country.name,
            offerLocation = `${offerCity ? `${t(offerCity)},` : ''} ${offerCountry ? t(offerCountry) : ''}`,
            salary = `${offerDetails.minimumSalary}, ${offerDetails.maximumSalary}`,
            isOfferApplied = offer.offer.status === OfferStatus.APPLIED || offer.offer.status === OfferStatus.SCHEDULED,
            isOfferClosed = offer.offer.status === OfferStatus.CANCELLED || offer.offer.status === OfferStatus.REJECTED;

        return (
            <LayoutWrapper>
                <Card>
                    <CardBody className="pe-0 ps-0 pt-0 pb-0">
                        <div className="offer-view-container">
                            <div className="map-wrapper">
                                <Map
                                    isMarkerShown={true}
                                    position={{lat: -34.397, lng: 150.644}}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{height: `100%`}} />}
                                    containerElement={<div style={{height: `100%`}} />}
                                    mapElement={<div style={{height: `100%`, borderRadius: 6}} />}
                                />
                                <Button
                                    disabled={isOfferApplied || isOfferClosed}
                                    color="primary"
                                    onClick={() => this.toggleApplicationModal()}>
                                    <Translation text="buttons.apply" />
                                </Button>
                            </div>

                            <div className="position-details">
                                <div className="header">
                                    <h3 className="job-title">
                                        {offerDetails.name ? <span>{offerDetails.name}</span> : null} {offerLocation}
                                    </h3>

                                    <div>
                                        <Button
                                            color="flat-primary"
                                            onClick={() =>
                                                this.addToObserve(
                                                    offerDetails.id,
                                                    isNotNullOrUndefined(offer.offer.observed) && offer.offer.observed
                                                )
                                            }>
                                            {offer.offer.observed ? <EyeOff id={'observe'} /> : <Eye id={'observe'} />}
                                            <Tooltip
                                                target={'observe'}
                                                tooltipText={t(
                                                    `${
                                                        offer.offer.observed
                                                            ? 'offers.table.tooltips.unobserve'
                                                            : 'offers.table.tooltips.observe'
                                                    }`
                                                )}
                                            />
                                        </Button>

                                        <Button color="flat-primary" onClick={() => this.toggleRecommendationModal(offerDetails)}>
                                            <Share2 id={'recommend'} />
                                            <Tooltip target={'recommend'} tooltipText={t('offers.table.tooltips.recommend')} />
                                        </Button>
                                    </div>
                                </div>

                                <div className="summary">
                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_PRIMARY,
                                        TrendingUp,
                                        'seniority',
                                        offerDetails.seniority.name
                                    )}

                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_INFO,
                                        User,
                                        'employees',
                                        (offerDetails.organizationSize as any).name
                                    )}

                                    {this.renderPositionDetails(
                                        AvatarColor.LIGHT_DANGER,
                                        Box,
                                        'relocation',
                                        offerDetails.relocation ? 'buttons.yes' : 'buttons.no'
                                    )}
                                    {this.renderPositionDetails(AvatarColor.LIGHT_SUCCESS, DollarSign, 'salary', salary, true)}
                                </div>

                                <hr />

                                <div className="technology">
                                    <h5 className="subtitle">
                                        <Translation text="offers.offerView.description.technology" />
                                    </h5>
                                    <div className="technology-details">
                                        {offer.technologyTools.map((tool: IOfferTechnologyTool) => {
                                            return this.renderTechnologyDetails(tool.technologyTool.name, tool.seniority.level);
                                        })}
                                    </div>
                                </div>

                                <hr />

                                <div className="job-description">
                                    <h5 className="subtitle">
                                        <Translation text="offers.offerView.description.jobDescription" />
                                    </h5>
                                    <HtmlParserComponent htmlContent={offer.offerDescription.description} />

                                    {/*<h5 className="subtitle">*/}
                                    {/*    <Translation text="offers.offerView.description.responsibilities" />*/}
                                    {/*</h5>*/}
                                    {/*<ul>*/}
                                    {/*    {offer.responsibilities.map((responsibility: string, index: number) => (*/}
                                    {/*        <li key={`responsibility_${index}`}>{responsibility}</li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}

                                    {/*<h5 className="subtitle">*/}
                                    {/*    <Translation text="offers.offerView.description.benefits" />*/}
                                    {/*</h5>*/}
                                    {/*<ul>*/}
                                    {/*    {offer.benefits.map((benefit: string, index: number) => (*/}
                                    {/*        <li key={`benefit_${index}`}>{benefit}</li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}
                                </div>

                                <hr />

                                <div className="additional-information">
                                    {this.renderOfferAdditionalInformation('industry', offerDetails.industries)}
                                    {this.renderOfferAdditionalInformation('companyType', offerDetails.companyTypes)}
                                    {this.renderOfferAdditionalInformation('contractType', offerDetails.contractTypes)}
                                    {this.renderOfferAdditionalInformation('employmentType', offerDetails.employmentTypes)}
                                    {this.renderOfferAdditionalInformation('jobType', offerDetails.workTypes)}
                                </div>

                                {offerDetails.preferenceTags && offerDetails.preferenceTags.length
                                    ? this.renderPreferenceTags(offerDetails.preferenceTags)
                                    : null}
                            </div>
                        </div>
                    </CardBody>
                </Card>

                {this.state.isApplicationModalOpen ? (
                    <OfferApplicationModal
                        isModalOpen={this.state.isApplicationModalOpen}
                        toggleModal={this.toggleApplicationModal}
                        offer={this.props.offer.offer}
                    />
                ) : null}

                {this.state.isRecommendationModalOpen ? (
                    <RecommendOfferModal
                        isModalOpen={this.state.isRecommendationModalOpen}
                        toggleModal={this.toggleRecommendationModal}
                        offer={this.state.selectedOffer}
                    />
                ) : null}

                <Loader showLoader={this.props.isOfferDetailsLoading} type={LoaderType.Local} />
            </LayoutWrapper>
        );
    }

    private toggleApplicationModal = () => {
        this.setState({isApplicationModalOpen: !this.state.isApplicationModalOpen});
    };

    private renderPositionDetails = (color: typeof AvatarColor, Icon: any, label: string, description: string, isPrice?: boolean) => {
        return (
            <div className="summary-item">
                <Avatar color={color} size={AvatarSize.LG} icon={<Icon size={24} />} tag={'div'} />
                <div className="summary-details">
                    <p className="description">
                        {isNotNullOrUndefined(description) ? (
                            isPrice ? (
                                <>
                                    <Price price={{amount: description.split(',')[0]}} /> -{' '}
                                    <Price price={{amount: description.split(',')[1]}} />
                                </>
                            ) : (
                                <Translation text={description} />
                            )
                        ) : (
                            '---'
                        )}
                    </p>
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
            </div>
        );
    };

    private renderTechnologyDetails = (label: string, level: number) => {
        const levelValue = level === 0 ? 1 : level / 10;

        return (
            <div className="technology-item" key={label}>
                <p className="label">
                    <Translation text={label} />
                </p>
                <div className="level">
                    <RatingComponent
                        initialRating={levelValue}
                        emptySymbol={<div className="empty-symbol" />}
                        fullSymbol={<div className="full-symbol" />}
                        readonly
                        stop={6}
                    />
                </div>
            </div>
        );
    };

    private renderOfferAdditionalInformation = (label: string, items: typeof IModelDictionaryDatum[]) => {
        return (
            <div className="information-item">
                <div className="offer-information-label">
                    <p className="label">
                        <Translation text={`offers.offerView.labels.${label}`} />
                    </p>
                </div>
                <div className="offer-information-content">
                    {items.map((item: typeof IModelDictionaryDatum) => (
                        <Badge key={item.id} color="primary" pill>
                            <Translation text={item.name.toLocaleLowerCase()} />
                        </Badge>
                    ))}
                </div>
            </div>
        );
    };

    private renderPreferenceTags = (tags: typeof IModelDictionaryDatum[]) => {
        return (
            <>
                <hr />

                <div className="job-description preference-tags">
                    <h5 className="subtitle">
                        <Translation text="offers.offerView.description.preferenceTags" />
                    </h5>

                    {tags.map((tag) => {
                        return (
                            <Badge key={tag.id} color="primary" className="me-1" pill>
                                <Translation text={tag.name} />
                            </Badge>
                        );
                    })}
                </div>
            </>
        );
    };

    private addToObserve = (offerId: string, isObserved: boolean) => {
        return isObserved ? this.props.removeOfferFromObserved(offerId) : this.props.addOfferToObserved(offerId);
    };

    private toggleRecommendationModal = (item?: any) => {
        this.setState({
            isRecommendationModalOpen: !this.state.isRecommendationModalOpen,
            selectedOffer: item ? item : null,
        });
    };
}

export default connect(
    (state: RootState) => ({
        offer: offerDetailsSelector(state),
        isOfferDetailsLoading: isOfferDetailsLoadingSelector(state),
    }),
    {
        fetchOfferDetails,
        addOfferToObserved,
        removeOfferFromObserved,
        resetToInitialOfferDetailsPageState,
        checkOfferRecommendationAsSeen,
    }
)(withTranslation()(withLocation(OfferView)));
