import {FormButtonType, FormControlType, IFormConfig, InputType, ValidationRules} from 'jobhunter-common-web';

export const changePasswordFormConfig: typeof IFormConfig = (discardChanges: () => void) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'current_password',
            controlType: 'group',
            class: 'row',
            controls: {
                currentPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.currentPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.currentPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                },
            },
        },
        {
            key: 'new_password',
            controlType: 'group',
            class: 'row',
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.password, (value: any) => value.newPassword]},
                },
            ],
            controls: {
                password: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.newPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.newPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                    onCopy: (e: any) => {
                        e.preventDefault();
                        return false;
                    },
                },
                newPassword: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED, params: {}},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 8}},
                    ],
                    placeholder: 'settings.changePassword.form.placeholders.confirmPassword',
                    isLabelHidden: false,
                    label: 'settings.changePassword.form.labels.confirmPassword',
                    type: InputType.PASSWORD,
                    hostClass: 'col-xl-6 form-control',
                    autocomplete: 'off',
                    onPaste: (e: any) => {
                        e.preventDefault();
                        return false;
                    },
                },
            },
        },
        {
            controlType: 'group',
            key: 'change_password_buttons',
            class: 'row',
            controls: {
                submitButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.SUBMIT,
                    inputStyles: 'btn-primary',
                    defaultContainerStyles: '',
                    hostClass: 'mb-0 align-items-end w-auto',
                    containerStyles: '',
                    defaultValue: null,
                    isLabelHidden: true,
                    btnText: 'buttons.saveChanges',
                    buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
                        return !isFormValid;
                    },
                },
                discardChangesButton: {
                    controlType: 'control',
                    formControlType: FormControlType.BUTTON,
                    buttonType: FormButtonType.BUTTON,
                    inputStyles: 'btn-outline-secondary',
                    hostClass: 'mb-0 w-auto',
                    defaultContainerStyles: '',
                    containerStyles: '',
                    defaultValue: null,
                    btnText: 'buttons.discard',
                    buttonDisabled: false,
                    customClickHandler: () => {
                        return discardChanges();
                    },
                },
            },
        },
    ],
});
