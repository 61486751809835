import {jobHunterAPI, RestQueryParams} from 'jobhunter-common-web';
import {Observable} from 'rxjs';

export interface IAddLanguageTestPayload {
    languageId: string;
}

export function addLanguageTestResultAPI(authToken: string | null, payload: IAddLanguageTestPayload): Observable<any> {
    let headers = undefined;

    if (authToken) {
        headers = {
            Authorization: `Bearer ${authToken}`,
        };
    }

    return jobHunterAPI.post(`api/language_test_results`, payload, new RestQueryParams(), headers);
}
