import {
    changeMenuCollapsed,
    changeMenuHidden,
    initLogout,
    menuCollapsedSelector,
    menuHiddenSelector,
    NotFound,
    Sidebar,
    Toast,
    Translation,
    usernameSelector,
    ChatContact,
    getChatContacts,
    chatContactsSelector,
    authAccountIdSelector,
    authTokenSelector,
    isAuthenticatedSelector,
    saveFile,
    sendMessageToOfflineAPI,
    getMessagesFromUserAPI,
    postMessageReadConfirmationAPI,
    candidateFullInfoSelector,
    ICandidateFullInfo,
} from 'jobhunter-common-web';
import {Chat, withChatConnection, IWithChatConnection} from '@solvee/reactjs-text-chat';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {fixInjectedProperties, lazyInject} from '../../ioc';
import {IAlertManagerService} from '../../service/alertManagerService';
import {RootState} from '../../store/reducers';
import themeConfig from './themeConfig';
import classnames from 'classnames';
import Dashboard from '../Dashboard';
import menu from './menu-items';
import Marketplace from '../Marketplace';
import Profile from '../Profile';
import Settings from '../Settings';
import OffersHost from '../OffersHost';
import OfferView from '../OffersHost/OfferView';
import Services from '../Services';
import VideoChatHost from '../VideoChatHost';
import subMenu from './sub-menu-items';
import RelocationList from '../Relocation';
import RelocationView from '../Relocation/RelocationView';
import {NavItem, NavLink} from 'reactstrap';
import {Menu} from 'react-feather';

interface IConnectedPanelHostProps {
    readonly candidateAccount: typeof ICandidateFullInfo;
    readonly accountId: string;
    readonly username: string;
    readonly initLogout: typeof initLogout;
    readonly menuHidden: boolean;
    readonly menuCollapsed: boolean;
    readonly changeMenuHidden: typeof changeMenuHidden;
    readonly changeMenuCollapsed: typeof changeMenuCollapsed;
    readonly authToken: string;
    readonly authenticated: boolean;
    readonly getChatContacts: typeof getChatContacts;
    readonly channelsToJoin: typeof ChatContact[];
}

interface IExternalPanelHostProps {
    fullWidthLayout?: any;
}

interface IPanelHostProps extends IConnectedPanelHostProps, IExternalPanelHostProps, IWithChatConnection {}

interface IPanelHostState {
    width: number;
}

class PanelHost extends React.Component<IPanelHostProps, IPanelHostState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService | undefined;

    constructor(props: IPanelHostProps) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.getChatContacts();

        if (this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        } else {
            this.props.changeMenuHidden(false);
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps: Readonly<IPanelHostProps>, prevState: Readonly<IPanelHostState>, snapshot?: any) {
        if (this.state.width !== prevState.width && this.state.width <= 768) {
            this.props.changeMenuHidden(true);
        }

        if (this.state.width !== prevState.width && this.state.width > 768) {
            this.props.changeMenuHidden(false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const isHidden = this.props.menuHidden,
            menuCollapsed = this.props.menuCollapsed,
            navbarWrapperClasses = {
                floating: 'navbar-floating',
                sticky: 'navbar-sticky',
                static: 'navbar-static',
                hidden: 'navbar-hidden',
            },
            footerClasses = {
                static: 'footer-static',
                sticky: 'footer-fixed',
                hidden: 'footer-hidden',
            },
            navbarType = 'floating',
            footerType = 'static',
            windowWidth = this.state.width;

        return (
            <div className={`${themeConfig.layout.skin}-layout`}>
                <div
                    className={classnames(
                        `wrapper vertical-layout vertical-menu-modern ${navbarWrapperClasses[navbarType] || 'navbar-floating'}
                    ${footerClasses[footerType] || 'footer-static'}`,
                        {
                            'menu-collapsed': (menuCollapsed && !isHidden) || (windowWidth <= 768 && !isHidden),
                            'menu-expanded': !menuCollapsed || windowWidth > 768,
                            'menu-hide': isHidden || windowWidth <= 768,
                            // 'vertical-overlay-menu': menuCollapsed || windowWidth <= 768,
                        }
                    )}>
                    <nav className="header-navbar d-md-none d-sm-flex">
                        <div className="navbar-container d-flex justify-content-between align-items-center">
                            <ul className="bookmark-wrapper">
                                <NavItem className="mobile-menu me-auto">
                                    <NavLink
                                        className="nav-menu-main menu-toggle is-active"
                                        onClick={() => this.props.changeMenuHidden(!isHidden)}>
                                        <Menu className="ficon" />
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>

                    {!isHidden ? (
                        <Sidebar
                            skin={themeConfig.layout.skin}
                            logoImage={themeConfig.app.appLogoImage}
                            logoMobileImage={themeConfig.app.appLogoMobile}
                            menuData={[menu(), subMenu(this.onLogout)]}
                            menuCollapsed={menuCollapsed}
                            setMenuCollapsed={() => this.props.changeMenuCollapsed(!menuCollapsed)}
                            currentActiveItem={null}
                        />
                    ) : null}
                    <div className="panel-wrapper">
                        <div
                            className={classnames('sidenav-overlay d-md-none d-sm-flex', {
                                show: !isHidden,
                            })}
                            onClick={() => this.props.changeMenuHidden(!isHidden)}></div>

                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} key="dashboard" />
                            <Route path="/profile" element={<Profile />} key="profile" />
                            <Route path="/marketplace" element={<Marketplace />} key="marketplace" />
                            <Route path="/marketplace/services" element={<Services />} key="services" />
                            <Route
                                path="/marketplace/chat"
                                element={
                                    <Chat
                                        avatarUrl={this.props.candidateAccount?.account?.avatar?.fileUrls?.smallThumb}
                                        messagesNumberPerHistoryPage={10}
                                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        authToken={this.props.authToken}
                                        getMessagesFromUser={this.props.getMessagesFromUser}
                                        confirmMessageRead={this.props.confirmMessageRead}
                                        accountId={this.props.accountId}
                                        errors={this.props.errors}
                                        errorHandler={(e: any) => this.alertManager?.handleApiError(e)}
                                        showOnlineIndicator={true}
                                        hasUnreadMessages={this.props.hasUnreadMessages}
                                        addMessage={this.props.addMessage}
                                        setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                        selectedChatRoomId={this.props.selectedChatRoomId}
                                        chatContactsList={this.props.allChannels}
                                        peerConnections={this.props.peerConnections}
                                        hasNodeConnection={this.props.hasNodeConnection}
                                        joinChannels={this.props.joinChannels}
                                        allContactsList={this.props.allowedChannels}
                                    />
                                }
                                key="chat"
                            />
                            <Route path="/settings" element={<Settings />} key="settings" />
                            <Route path="/offers/*" element={<OffersHost />} key="offers" />
                            <Route path="/offer-view/:id" element={<OfferView />} key="offer-view" />
                            <Route path="/relocations" element={<RelocationList />} key="relocations" />
                            <Route
                                path="/relocations/:id"
                                element={
                                    <RelocationView
                                        // @ts-ignore MD: używanie importowanego z common routera(?) powoduje, że TS nie widzi komponentu w normalny sposób
                                        avatarUrl={this.props.candidateAccount?.account?.avatar?.fileUrls?.smallThumb}
                                        messagesNumberPerHistoryPage={10}
                                        canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                                        authToken={this.props.authToken}
                                        getMessagesFromUser={this.props.getMessagesFromUser}
                                        confirmMessageRead={this.props.confirmMessageRead}
                                        accountId={this.props.accountId}
                                        errors={this.props.errors}
                                        errorHandler={(e: any) => this.alertManager?.handleApiError(e)}
                                        showOnlineIndicator={true}
                                        hasUnreadMessages={this.props.hasUnreadMessages}
                                        addMessage={this.props.addMessage}
                                        setSelectedChatRoomId={this.props.setSelectedChatRoomId}
                                        selectedChatRoomId={this.props.selectedChatRoomId}
                                        chatContactsList={this.props.allowedChannels}
                                        peerConnections={this.props.peerConnections}
                                        hasNodeConnection={this.props.hasNodeConnection}
                                        joinChannels={this.props.joinChannels}
                                        allContactsList={this.props.allChannels}
                                    />
                                }
                                key="relocation-view"
                            />
                            <Route path="/consultations/:id" element={<VideoChatHost />} key="video-chat" />
                            <Route path="*" element={<NotFound />} key="not-found" />
                        </Routes>

                        <footer
                            className={classnames(`footer footer-light ${footerClasses[footerType] || 'footer-static'}`, {
                                'd-none': footerType !== 'static',
                            })}>
                            <Translation text="footer.copyright" config={{date: new Date().getFullYear()}} />
                        </footer>
                    </div>
                </div>
                <Toast />
            </div>
        );
    }

    private onLogout = () => {
        this.props.initLogout();
        this.alertManager?.logoutSuccess();
    };

    private updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        candidateAccount: candidateFullInfoSelector(state),
        accountId: authAccountIdSelector(state),
        username: usernameSelector(state),
        menuCollapsed: menuCollapsedSelector(state),
        menuHidden: menuHiddenSelector(state),
        channelsToJoin: chatContactsSelector(state),
    }),
    {
        initLogout,
        changeMenuHidden,
        changeMenuCollapsed,
        getChatContacts,
    }
)(
    withChatConnection(PanelHost, {
        webRTCConfig: {
            chatSignalingServerAddress: process.env.REACT_APP_CHAT_SERVER_URL || '',
            serverUrls: [
                {urls: process.env.REACT_APP_CHAT_STUN_SERVER_URL || ''},
                {
                    urls: process.env.REACT_APP_CHAT_TURN_SERVER_URL || '',
                    credential: process.env.REACT_APP_CHAT_TURN_SERVER_PASSWORD || '',
                    username: process.env.REACT_APP_CHAT_TURN_SERVER_USERNAME || '',
                },
            ],
        },
        saveMessagesConfig: {
            saveMessagesOnlyWhenPeerIsOffline: false,
            messagesNumberPerHistoryPage: 10,
            getContacts: getChatContacts,
            saveFile: saveFile,
            sendMessageToOfflineAPI: sendMessageToOfflineAPI,
            getMessagesFromUserAPI: getMessagesFromUserAPI,
            postMessageReadConfirmationAPI: postMessageReadConfirmationAPI,
        },
    })
);
