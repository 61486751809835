import React, {Component} from 'react';
import {Card, CardBody, CardTitle} from 'reactstrap';
import {MessageSquare} from 'react-feather';
import {Translation} from 'jobhunter-common-web';
import {ChatComponent, ChatContact, ChatProps} from '@solvee/reactjs-text-chat';
import {WithTranslation, withTranslation} from 'react-i18next';

interface RelocationChatState {
    nodeErrors: typeof initialErrorsState;
    isLoading: boolean;
    error: string;
}

export enum NodeErrorTypes {
    NOT_AUTHORIZED = 'not_authorized',
    NO_TOKEN = 'no_token',
    ALREADY_ONLINE = 'already_online',
    EXPIRED_TOKEN = 'expired_token',
    WEBSOCKET_ERROR = 'websocket error',
}

const initialErrorsState: {[key in NodeErrorTypes]: boolean} = {
    [NodeErrorTypes.ALREADY_ONLINE]: false,
    [NodeErrorTypes.EXPIRED_TOKEN]: false,
    [NodeErrorTypes.NOT_AUTHORIZED]: false,
    [NodeErrorTypes.NO_TOKEN]: false,
    [NodeErrorTypes.WEBSOCKET_ERROR]: false,
};

interface RelocationChatProps extends ChatProps, WithTranslation {}

class RelocationChat extends Component<RelocationChatProps, RelocationChatState> {
    constructor(props: any) {
        super(props);
        this.state = {
            nodeErrors: initialErrorsState,
            isLoading: false,
            error: '',
        };
    }

    render() {
        return (
            <Card className="relocation-chat-card">
                <CardBody>
                    <CardTitle>
                        <div className="card-header justify-content-start">
                            <MessageSquare size={20} />
                            <Translation text="relocationView.chat.title" />
                        </div>
                    </CardTitle>
                    {this.props.hasNodeConnection ? (
                        <ChatComponent
                            avatarUrl={this.props.avatarUrl}
                            selectedChatRoomId={this.props.selectedChatRoomId}
                            error={this.state.error}
                            errorModuleHandle={this.setError}
                            userName={this.userName}
                            chatContact={this.chatPartnerAccount}
                            peerConnection={
                                this.props.peerConnections ? this.props.peerConnections?.[this.props?.selectedChatRoomId] : null
                            }
                            getMessagesFromUser={this.props.getMessagesFromUser}
                            addMessage={this.props.addMessage}
                            accountId={this.props.accountId}
                            confirmMessageRead={this.props.confirmMessageRead}
                            authToken={this.props.authToken}
                            canSendMessagesToOffline={this.props.canSendMessagesToOffline}
                            messagesNumberPerHistoryPage={this.props.messagesNumberPerHistoryPage}
                            showOnlineIndicator={
                                this.props.showOnlineIndicator && this.props.peerConnections?.[this.props?.selectedChatRoomId]?.peerIsOnline
                            }
                        />
                    ) : (
                        <div className={'col-12 mt-4'}>
                            <i className={'feather icon-alert-triangle'} />
                            <p className="text-danger font-large-1 text-center">
                                <Translation text={'chat.noNodeConnection'} />
                            </p>
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }

    private get chatPartnerAccount(): ChatContact | undefined {
        if (this.props.chatContactsList && this.props.selectedChatRoomId) {
            return this.props.chatContactsList.find((room: ChatContact) => room.roomId === this.props.selectedChatRoomId);
        }
        return;
    }

    private get userName(): string {
        if (this.props.chatContactsList && this.props.selectedChatRoomId) {
            const currentRoom = this.props.chatContactsList.find((room: ChatContact) => room.roomId === this.props.selectedChatRoomId),
                {t} = this.props;
            return currentRoom && currentRoom.firstName && currentRoom.lastName
                ? `${currentRoom.firstName} ${currentRoom.lastName}`
                : t('relocationView.chat.unknownUser');
        }
        return '';
    }

    private setError = (error: string) => {
        this.setState(() => ({
            error: error,
        }));
    };
}

export default withTranslation()(RelocationChat);
