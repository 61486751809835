import {
    collectionInputDataMapper,
    collectionOutputDataMapper,
    FormControlType,
    IFormConfig,
    IMultiselectOption,
    InputType,
    MultiSelectType,
    ValidationRules,
} from 'jobhunter-common-web';

export const personalInformationFormConfig: typeof IFormConfig = (
    countryList: typeof IMultiselectOption[] | []
    // cityList: typeof IMultiselectOption[] | []
) => ({
    controlType: 'form',
    class: 'default-form',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: 'name',
            controlType: 'group',
            class: 'row',
            controls: {
                firstName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'profile.personal.form.placeholders.firstName',
                    isLabelHidden: false,
                    label: 'profile.personal.form.labels.firstName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                lastName: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'profile.personal.form.placeholders.lastName',
                    isLabelHidden: false,
                    label: 'profile.personal.form.labels.lastName',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'basic_information',
            controlType: 'group',
            class: 'row',
            controls: {
                phone: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [],
                    placeholder: 'profile.personal.form.placeholders.phoneNumber',
                    isLabelHidden: false,
                    label: 'profile.personal.form.labels.phoneNumber',
                    type: InputType.TEL,
                    hostClass: 'col-xl-6 form-control required',
                },
                birthDate: {
                    controlType: 'control',
                    defaultValue: '',
                    additionalStyles: '',
                    formControlType: FormControlType.DATE,
                    validationRules: [
                        {
                            name: ValidationRules.IS_DATE_LTE_THAN,
                            params: {
                                valueAccessor: (data: any) => data,
                                compareAccessor: () => new Date(),
                            },
                        },
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    placeholder: 'profile.personal.form.placeholders.birthDate',
                    isLabelHidden: false,
                    label: 'profile.personal.form.labels.birthDate',
                    maxDate: new Date(),
                    type: InputType.DATE,
                    dateFormat: 'dd/MM/yyyy',
                    minDate: new Date(1920, 1, 1),
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    openToDate: new Date(1990, 1, 1),
                    hostClass: 'col-xl-6 form-control required',
                },
            },
        },
        {
            key: 'address',
            controlType: 'group',
            class: 'row',
            controls: {
                city: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.INPUT,
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'profile.personal.form.placeholders.city',
                    isLabelHidden: false,
                    label: 'profile.personal.form.labels.city',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-6 form-control required',
                },
                country: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    multiselectOptions: countryList ? countryList : [],
                    validationRules: [{name: ValidationRules.IS_REQUIRED}],
                    placeholder: 'profile.personal.form.placeholders.country',
                    label: 'profile.personal.form.labels.country',
                    hostClass: 'col-xl-6 form-control required',
                    inputDataMapper: collectionInputDataMapper,
                    outputDataMapper: collectionOutputDataMapper,
                },
            },
        },
        {
            key: 'description',
            controlType: 'group',
            class: 'row',
            controls: {
                description: {
                    controlType: 'control',
                    defaultValue: '',
                    formControlType: FormControlType.TEXTAREA,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {
                            name: ValidationRules.MAX_LENGTH,
                            params: {length: 2000},
                        },
                    ],
                    placeholder: 'profile.personal.form.placeholders.description',
                    isLabelHidden: false,
                    rows: 5,
                    maxLength: 2000,
                    label: 'profile.personal.form.labels.description',
                    type: InputType.TEXT,
                    hostClass: 'col-xl-12 form-control required',
                },
            },
        },
    ],
});
