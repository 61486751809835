import {combineEpics, Epic, ofType, StateObservable} from 'redux-observable';
import {catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, ObservableInput, of} from 'rxjs';
import {
    accountSelector,
    addAlert,
    AlertType,
    authTokenSelector,
    deepCloneObject,
    flattenObj,
    getErrorMessage,
    getMetadataDetails,
    IAccount,
    IModelApiResponseViewObject,
    isNotNullOrUndefined,
    isNullOrUndefined,
    RestQueryParams,
} from 'jobhunter-common-web';
import {
    addOfferToApplied,
    addOfferToObserved,
    applyOffersFilters,
    changeIsOfferActionComplete,
    changeIsOffersLoading,
    changeOffersError,
    changeOffersFilters,
    changeOffersPagination,
    changeOffersSearchId,
    fetchListData,
    IChangeOfferAction,
    IChangeOffersListType,
    IOfferFilters,
    OffersListType,
    removeOfferFromApplied,
    removeOfferFromObserved,
    resetOffersFiltersToDefaultAccountFilters,
    setOffersData,
    setOffersMetadata,
} from '../reducers/offersPageSlice';
import {getOffersAPI} from '../../api/getOffers';
import {
    offerFiltersSelector,
    offersListTypeSelector,
    offersPaginationSelector,
    offersSearchIdSelector,
} from '../selectors/offersPageSelectors';
import {RootState} from '../reducers';
import {getRecruitmentOffersAPI} from '../../api/getRecruitmentOffers';
import {getApplicationOffersAPI} from '../../api/getApplicationOffers';
import {getObservedOffersAPI} from '../../api/getObservedOffers';
import {PayloadAction} from '@reduxjs/toolkit';
import {addOfferToAppliedAPI} from '../../api/addOfferToApplied';
import {addOfferToObservedAPI} from '../../api/addOfferToObserved';
import {removeOfferFromAppliedAPI} from '../../api/removeOfferFromApplied';
import {removeOfferFromObservedAPI} from '../../api/removeOfferFromObserved';

const resetOffersFiltersToDefaultAccountFiltersEpic: Epic = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(resetOffersFiltersToDefaultAccountFilters.type),
        map(() => accountSelector(state$.value)),
        mergeMap((account: typeof IAccount): any => {
            const candidateInfo = account.candidateFullInfo,
                candidatePreferences = candidateInfo.preferences,
                isPreferenceDataSet = candidateInfo.account.filled.preferences;

            const filters: IOfferFilters | null = isPreferenceDataSet
                ? {
                      offerTechnologies: {
                          technology: {
                              id: candidatePreferences.technologies?.map((technology: {[key: string]: any}) => technology.id),
                          },
                      },
                      seniority: {
                          id: [candidateInfo.technologies[0]?.seniority?.id],
                      },
                      minimumSalary: candidatePreferences.minimumSalary,
                      best_match: false,
                      contractTypes: {
                          id: candidatePreferences.contractTypes.map((type: {[key: string]: any}) => type.id),
                      },
                      industries: {
                          id: candidatePreferences.industries.map((industry: {[key: string]: any}) => industry.id),
                      },
                      companyTypes: {
                          id: candidatePreferences.companyTypes.map((type: {[key: string]: any}) => type.id),
                      },
                      employmentTypes: {
                          id: candidatePreferences.employmentTypes.map((type: {[key: string]: any}) => type.id),
                      },
                      workTypes: {
                          id: candidatePreferences.workTypes.map((type: {[key: string]: any}) => type.id),
                      },
                      relocation: candidatePreferences.relocationOnly,
                  }
                : null;
            return of(changeOffersFilters(filters));
        }),
        catchError((error: any) => of(...errorActions(error)))
    );

const applyOffersSearchFilters: Epic = (action$, state$: StateObservable<RootState>) =>
    getAction(action$, state$, applyOffersFilters, doFetch);

const changeOffersPaginationEpic: Epic = (action$, state$: StateObservable<any>) =>
    getAction(action$, state$, changeOffersPagination, doFetch);

const fetchListDataEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return action$.pipe(
        ofType(fetchListData.type),
        switchMap((action: PayloadAction<IChangeOffersListType>): any => {
            const authToken = authTokenSelector(state$.value),
                paginationParams = offersPaginationSelector(state$.value),
                flattened = flattenObj(paginationParams),
                params = new RestQueryParams(flattened);
            return getOffersList(authToken, params, action.payload.listType);
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const addOfferToAppliedEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeOfferListAction(action$, state$, addOfferToApplied, addOfferToAppliedAPI, 'Offer was added to applied offers');
};

const addOfferToObservedEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeOfferListAction(action$, state$, addOfferToObserved, addOfferToObservedAPI, 'Offer was added to observed');
};

const removeOfferToAppliedEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeOfferListAction(action$, state$, removeOfferFromApplied, removeOfferFromAppliedAPI, 'Offer was removed from applied');
};

const removeOfferToObservedEpic: Epic = (action$, state$: StateObservable<RootState>) => {
    return changeOfferListAction(action$, state$, removeOfferFromObserved, removeOfferFromObservedAPI, 'Offer was removed from observed');
};

// const changeOffersSearchPagination: Epic = (action$, state$: StateObservable<any>) =>
//     getAction(action$, state$, changeStudyFieldsPagination, doFetch);

export type FetchAction = {token: string | null; flattenedParams: any; listType: OffersListType | null; searchId: string | null};
const fetchSubject = new BehaviorSubject<FetchAction>({token: null, flattenedParams: null, listType: null, searchId: null});
const resultsSubject = new BehaviorSubject<any>(null);
fetchSubject
    .asObservable()
    .pipe(
        debounceTime(250),
        switchMap((fetch) => {
            if (isNullOrUndefined(fetch.token)) {
                return of(null);
            }

            return getOffersList(fetch.token as string, new RestQueryParams(fetch.flattenedParams), fetch.listType, fetch.searchId);
        }),
        tap((action) => resultsSubject.next(action))
    )
    .subscribe(); // subscription with same lifetime as the application, no need to unsubscribe

const doFetch = (state: RootState) => {
    const authToken = authTokenSelector(state),
        paginationParams = offersPaginationSelector(state),
        filters = deepCloneObject(offerFiltersSelector(state)),
        listType = offersListTypeSelector(state),
        searchId = paginationParams?.page !== 1 ? offersSearchIdSelector(state) : null;

    if (filters && filters.best_match) {
        Object.keys(filters).forEach((key) => delete filters[key]);
        filters.best_match = true;
    } else {
        if (isNotNullOrUndefined(filters?.minimumSalary)) {
            filters['minimumSalary[gte]'] = filters.minimumSalary;
            delete filters.minimumSalary;
        }

        if (filters && (isNullOrUndefined(filters?.best_match) || filters.best_match === false)) {
            delete filters.best_match;
        }
    }

    const filterObj = {
            ...filters,
            ...paginationParams,
        },
        flattened = flattenObj(filterObj);

    fetchSubject.next({token: authToken, flattenedParams: flattened, listType: listType, searchId: searchId});

    return resultsSubject.asObservable().pipe(
        filter((action: any) => null !== action),
        concatMap((action) => of(action, changeIsOffersLoading(false)))
    );
};

const getOffersList = (authToken: string, params?: typeof RestQueryParams, listType?: OffersListType | null, searchId?: string | null) => {
    params = params || new RestQueryParams();

    if (listType && listType === OffersListType.APPLICATIONS) {
        return getList(getApplicationOffersAPI(authToken, params), successActions, errorActions);
    } else if (listType && listType === OffersListType.RECRUITMENT) {
        return getList(getRecruitmentOffersAPI(authToken, params), successActions, errorActions);
    } else if (listType && listType === OffersListType.OBSERVED) {
        return getList(getObservedOffersAPI(authToken, params), successActions, errorActions);
    } else {
        let requestHeader = undefined;
        if (searchId) {
            requestHeader = {
                'X-Search-Id': searchId,
            };
        }

        return getList(getOffersAPI(authToken, params, requestHeader), successActions, errorActions);
    }
};

export const getList = (
    api: Observable<any>,
    successActions: (list: any[], metadata: any, searchId?: string | null) => any[],
    errorActions: (error: any) => any[]
) => {
    return api.pipe(
        mergeMap((resp: any) => {
            const metadata = getMetadataDetails(resp['hydra:view']),
                offers = (resp['hydra:member'] || []).map((offer: {[key: string]: any}) => {
                    if (offer.hasOwnProperty('consultation')) {
                        return {
                            '@id': offer.application.offer['@id'],
                            '@type': offer.application.offer['@type'],
                            offer: offer.application.offer,
                            status: offer.application.status,
                            consultation: offer.consultation,
                        };
                    } else {
                        return offer;
                    }
                });

            return of(...successActions(offers, metadata, resp?.search_id));
        }),
        catchError((error: any) => of(...errorActions(error)))
    );
};

export const getAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    doFetch: (state: RootState) => ObservableInput<any>
) => {
    return action$.pipe(
        ofType(actionType.type),
        map(() => state$.value),
        switchMap(doFetch)
    );
};

export const changeOfferListAction = (
    action$: Observable<any>,
    state$: StateObservable<any>,
    actionType: any,
    api: any,
    successAlertMessage: string
) => {
    return action$.pipe(
        ofType(actionType.type),
        switchMap((action: PayloadAction<IChangeOfferAction>): any => {
            const authToken = authTokenSelector(state$.value);
            return api(authToken, action.payload.offerId).pipe(
                switchMap(() => {
                    const listType = offersListTypeSelector(state$.value),
                        actions = [addAlert({message: successAlertMessage}), changeIsOfferActionComplete(true)];

                    if (listType === OffersListType.OFFERS) {
                        actions.push(applyOffersFilters());
                    } else {
                        actions.push(fetchListData(listType));
                    }
                    return of(...actions);
                }),
                catchError((error) => of(...errorActions(error)))
            );
        }),
        catchError((error) => of(...errorActions(error)))
    );
};

const successActions = (list: any[], metadata: typeof IModelApiResponseViewObject | null, searchId?: string | null): any[] => {
    const actions = [setOffersData(list), setOffersMetadata(metadata), changeIsOffersLoading(false)];

    if (searchId !== undefined) {
        actions.push(changeOffersSearchId(searchId) as any);
    }

    return actions;
};

const errorActions = (error: any): any[] => {
    return [
        addAlert({message: getErrorMessage(error), type: AlertType.WARNING}),
        changeIsOffersLoading(false),
        changeOffersError(getErrorMessage(error)),
    ];
};

const offersEpic = combineEpics(
    applyOffersSearchFilters,
    changeOffersPaginationEpic,
    resetOffersFiltersToDefaultAccountFiltersEpic,
    addOfferToObservedEpic,
    addOfferToAppliedEpic,
    removeOfferToAppliedEpic,
    removeOfferToObservedEpic,
    fetchListDataEpic
);

export default offersEpic;
